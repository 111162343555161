import * as React from 'react';
import { Container } from './styles';
import { Duplicatas } from './main';
import Sidebar from './Sidebar';
import { useContext, useEffect, useState } from 'react';
import { DuplicatasEmAbertoDTO } from '../../DTOs/DuplicatasEmAbertoDTO';
import api from '../../service/api';
import { formatarData, formatarValores } from '../../helpers/valores';
import userContext from '../../Context/UserContext';
import Pagination from '../../componentes/Paginacao/Pagination';

const DuplicatasEmAberto: React.FC = () => {

    const [notasDuplicatas, setDuplicatas] = useState<DuplicatasEmAbertoDTO[]>([]);
    const currentlyUser = useContext(userContext);
    const { Codcliente, Codempresa } = currentlyUser.user;
    const [itensPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(-1);
    const [currentItens, setCurrentItens] = useState<DuplicatasEmAbertoDTO[]>([]);
    const pages = Math.ceil(notasDuplicatas.length / itensPerPage);
    const startIndex = currentPage * itensPerPage;
    const endIndex = startIndex + itensPerPage;

    useEffect(() => {
        setCurrentItens(notasDuplicatas.slice(startIndex, endIndex));
    }, [currentPage]);

    function ConsultaDuplicatasEmAberto(filtro: any) {
        // Busca os pedidos da API
        api.post(`api/cliente/duplicatasemaberto`, filtro).then((response) => {
            const notasDuplicatas = response.data;
            setDuplicatas(notasDuplicatas);
            setCurrentPage(0);
            setCurrentItens(notasDuplicatas.slice(startIndex, endIndex));
        });
    }

    // FILTRO DAS DATAS
    var dataInitial = new Date();
    var dia = String(dataInitial.getDate()).padStart(2, '0');
    var mes = String(dataInitial.getMonth() + 1).padStart(2, '0');
    var ano = dataInitial.getFullYear();
    var dataAnterior = ano + '-' + mes + '-' + dia;

    var dataFinal = new Date();
    var dia = String(dataFinal.getDate()).padStart(2, '0');
    var mes = String(dataFinal.getMonth() + 1).padStart(2, '0');
    var ano = dataFinal.getFullYear();
    var dataAtual = ano + '-' + mes + '-' + dia;

    function FiltroNotas(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let formData = new FormData(event.currentTarget);
        let dataInicio = formData.get("deData") as string;
        let dataFim = formData.get("ateData") as string;
        let radioButton = formData.get("radioButton") as string;

        var filtro = {
            Codcliente: Codcliente,
            Codempresa: Codempresa,
            datini: dataInicio,
            datfim: dataFim,
            Codsitconta: radioButton,
        }

        ConsultaDuplicatasEmAberto(filtro);
    }

    useEffect(() => {
        var filtro = {
            Nronota: 0,
            Datnota: "",
            Nroparcela: 0,
            Datvencto: "",
            Vlrvencto: 0,
            Datpagto: "",
            Codcliente: Codcliente,
            Codempresa: Codempresa,
            datini: "",
            datfim: "",
        }

        ConsultaDuplicatasEmAberto(filtro);

    }, [currentPage]);

    return (
        <Container>
            <Duplicatas>
                <Sidebar />

                <div className="conteudo">
                    <div className="tituloNotas">
                        Lista de Duplicatas em Aberto
                    </div>
                    <div className="periodoData">
                        <div className='divFiltros'>
                            <p className='periodoDatas'>Período de Vencimento</p>
                            <p className='periodoDatas'>Situação</p>
                        </div>
                        <div className='formsFiltro'>
                            <form onSubmit={FiltroNotas} className="datas">
                                <div className='dats'>
                                    <label htmlFor="deData">De:</label>
                                    <input type="date" id="deData" name="deData" defaultValue={dataAnterior} />
                                    <label htmlFor="ateData">Até:</label>
                                    <input type="date" id="ateData" name="ateData" defaultValue={dataAtual} />

                                    <div className='filtroSituacao'>
                                        <div className='radioDiv' id="radioDiv">
                                            <input type="radio" value="0" name="radioButton" id="radioButton" /> Todas as contas
                                            <input type="radio" value="1" name="radioButton" id="radioButton" /> Somente abertas
                                        </div>
                                    </div>
                                </div>
                                <div className='botoesFiltros'>
                                    <button type='submit' className="btn-filtrar">Filtrar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Nro da Nota</th>
                                <th>Data da Nota</th>
                                <th>Nro. da Parcela</th>
                                <th>Data de Vencimento</th>
                                <th>Valor</th>
                                <th>Data de Pagto</th>
                            </tr>
                            {
                                currentItens.length === 0 ?
                                    // Se não vier nada da API, exibe uma mensagem de aviso
                                    <tr><td colSpan={6}><p>Nenhuma duplicada encontrada.</p></td></tr>
                                    :
                                    currentItens.map((notasDuplicata: DuplicatasEmAbertoDTO, index: any) => {
                                        return (
                                            <tr key={index}>
                                                <td className='camposNota'>{notasDuplicata.Nronota == null ? 0 : notasDuplicata.Nronota}</td>
                                                <td className='camposNota'>{formatarData(notasDuplicata.Datnota)}</td>
                                                <td className='camposNota'>{notasDuplicata.Nroparcela == null ? 0 : notasDuplicata.Nroparcela}</td>
                                                <td className='camposNota'>{formatarData(notasDuplicata.Datvencto)}</td>
                                                <td className='camposNota'>{formatarValores(notasDuplicata.Vlrvencto)}</td>
                                                <td className='camposNota'>{formatarData(notasDuplicata.Datpagto == null ? "Sem Data" : notasDuplicata.Datpagto)}</td>
                                            </tr>
                                        )
                                    })
                            }
                        </thead>
                    </table>
                    {currentItens.length === 0 ? null : <Pagination pages={pages} setCurrentPaged={setCurrentPage} currentPage={currentPage} />}
                </div>
            </Duplicatas>
        </Container>
    )
};

export default DuplicatasEmAberto;
