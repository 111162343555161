import styled from 'styled-components';


export const MinhaConta = styled.div`

        /* CONTEUDO CENTRAL */

        .conteudo{
            width: 80%;
            float: right;
            text-align: center;
            height: auto;
            margin-top: 1rem;
        }

        .pedidoAtual{
            display: flex;
            font-weight: bold;
            margin-bottom: 1rem;
            border-bottom: 1px solid rgb(211, 201, 201, 1);
            width: 80%
        }

        section{
            width: 65%;
            display: flex;
            border: 1px solid rgb(211, 201, 201, 1);
            border-radius: 5px;
            flex-direction: column;
            margin-bottom: 2rem;
        }

        .dadosPedido{
            width: 60%;
            display: flex;
        }

        .tableMostrar{
            display: flex;
            flex-direction: row;
        }

        .pedido{
            padding-left: 4rem;
            display: flex;
            flex-direction: column;
        }

        .campoPrincipal{
            font-weight: bold;
            padding: 0;
            margin-top: 22px;
            margin-bottom: 0;
        }

        .campoInfo{
            margin-top: 10px;
            padding-bottom: 22px;
        }

        p{
            font-size: 17px;
        }

        .detalhes{
            width: 38%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        label { 
            display: inline-block;
            cursor: pointer;
            padding: 5px;
        }

        label {
            color: black;
            display: flex;
        }

        label:hover{
            color: #423e3e;
        }

        .inputArrow ~ .labelArrow{
            transform: rotate(0);
        }
            
        .inputArrow:checked ~ .labelArrow{
            transform: translateX(10px) rotate(180deg);
        }
        
        /* COR DOS ICONS STATUS PEDIDO */

        /* EM PREENCHIMENTO */
        .iconStatus0{
            color: rgb(6, 250, 99);
            margin-left: 10px;
        }

        /* A VALIDAR */
        .iconStatus1{
            color: rgb(219, 162, 20);
            margin-left: 10px;
        }

        /* FECHADO */
        .iconStatus2{
            color: rgb(235, 10, 10);
            margin-left: 10px;
        }

        /* EM VALIDAÇÃO */
        .iconStatus3{
            color: rgb(13, 17, 224);
            margin-left: 10px;
        }

        .iconConcluido{
            color: rgb(22, 148, 110);
            margin-left: 10px;
        }

        .periodoData{
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            font-weight: bold;
        }

        .dataeStatus{
        }
        
        .dats{
            display: flex;
        }

        .datas{
            padding-bottom: 2rem;
            display: flex;
        }

        .formsFiltro{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
        }

        .filtroStatus{
            display: flex;
        }

        .btn-filtrar{
            width: 60px;
            margin-left: 1rem;
            font-weight: bold;
            /* height: 25px; */
        }

        .btn-filtrar:hover{
            background-color: var(--filtrarHover-bg-color);
            color: #fff;
        }

        @media(max-width: 923px){
            .periodoData{
                display: flex;
                align-items: center;                
            }
        }

/* DIV DO MOSTRAR MAIS ITENS */

        .mostrar {
            display:none;
        }

        .activeProdutos {
            display:block;
        }

        table {
            border-collapse: collapse;
            width: 80%;
        }

        .principais{
            width: 100%;
            border: 0;
        }

        th{
            text-align: left;
            padding: 8px;
            color: rgb(38, 35, 35);
            font-size: 15px;
        }

        .trItens {
            text-align: left;
            padding: 8px;
            color: rgb(38, 35, 35);
            font-size: 15px;
            height: 20px;
        }

        td{
            text-align: left;
            vertical-align: top;
            padding-left: 8px;
            padding-bottom: 8px;
            color: rgb(56, 49, 49);
            font-size: 15px;
        }

        .coluna{
            color: rgb(131, 130, 130);
            padding-bottom: 2rem;
        }

        .valorItem{
            color: black;
            font-weight: bold;
        }

        
        .thClass{
            border-right: 1px solid rgb(211, 201, 201, 1);
        }

        td:first-child { width: 1px  };
        td:first-child + td {  width: 130px };
        td:first-child + td + td {  width: 90px };
        td:first-child + td + td + td + td{  width: 190px };
        th:first-child {width: 10px};

        .camposPrincipais{
            border: 0;
        }

        .livro{
            width: 130px;
            height: 110px;
        }

        a{
            color: #111;
        }

        a:hover{
            color: #423e3e;
        }

        .iconArrow{
            padding-left: 1rem;
        }

        .mostrar{
            padding-top: 1rem;
            padding-left: 1rem;
        }

        @media(max-width: 923px){
            .conteudo{
                width: 100%;
                margin-top: 3rem;
            }

            .datas{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .dats{
                width: 100%;
                flex-direction: column;
                justify-content: center;
                margin-top: 5px;
            }

            .btn-filtrar{
                width: 100px;
                margin-top: 10px;
            }

            section{
                width: 99%;
            }

            .dadosPedido{
                width: 100%;
            }

            .status{
                display: none;
            }

            .colunaGhost{
                display: none;
            }

            td:first-child + td {  width: 50px };
            td:first-child + td + td {  width: 50px };
            td:first-child + td + td + td {  width: 70px };
            td:first-child + td + td + td + td{  width: 190px };

            .mostrar{
                padding: 0;
            }
        }

`;

export const SidebarConta = styled.div`

/* MENU LATERAL */
        .menu {
            width: 15%;
            margin: 0;
            padding: 0;
            float: left;
            height: 100%;
            /* position: absolute; */
        }

        .minhaConta{
            display: flex;
            background-color: var(--menuBackground-bg-color);
            margin-bottom: 1rem;
            margin-top: 1rem;
            padding: 1rem;
            font-weight: bold;
            color: #fff;
        }

        /* .menu:hover{
            left: 0px;
            transition: 1s;
        } */

        ul{
            margin: 0;
            padding: 0;
        }

        .menu ul li {
            margin: 0; padding: 0px;
            text-align: left;
            list-style-type: none;
        }

        .menu a:link {
            font-weight: bold;
            text-decoration: none;
            padding: 8px;
            display: block;
        }

        .menu a {
            color: black;
        }

        a:hover {
            background: #423e3e;
            color: #fff;
            border-radius: 4px;
        }

        @media(max-width: 923px) {
            
            .menu{
                width: 100%;
            }
        }
`;

export const Notas = styled.div`

        #deData{
            margin-right: 1rem;
        }

        .conteudo{
            width: 80%;
            float: right;
            text-align: center;
            height: auto;
            margin-top: 1rem;
        }

        .tituloNotas{
            display: flex;
            font-weight: bold;
            margin-bottom: 1rem;
            border-bottom: 1px solid rgb(211, 201, 201, 1);
            width: 80%
        }

        
        .periodoData{
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            font-weight: bold;
        }
        
        .dats{
            display: flex;
            align-items: center;
        }

        .datas{
            padding-bottom: 2rem;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .formsFiltro{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
        }

        .filtroStatus{
            display: flex;
            width: 100%;
        }

        .btn-filtrar{
            width: 100px;
            margin-left: 1rem;
            font-weight: bold;
            height: 25px;
        }

        .btn-filtrar:hover{
            background-color: var(--filtrarHover-bg-color);
            color: #fff;
        }

        table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 80%;
        }

        td, th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
        }

        .filtroSituacao{
            padding-left: 2rem;
        }

        .codSituacao{
            display: flex;
        }

        td:first-child { width: 30px;}
        td:first-child +td { width: 30px};
        td:first-child + td + td { width: 50px};
        td:first-child + td + td + td { width: 50px};
        td:first-child + td + td + td + td { width: 250px; text-align: left;};
        td:first-child + td + td + td + td +td { width: 150px; text-align: left;};

        .camposNota{
            text-align: right;
        }

        .valornota{
            text-align: center;
        }

        th, td{
            font-size: 12px;
        }

        .camposPrincipais{
            background-color: var(--main-bg-color);
        }

        .botoesFiltros{
            width: 80%;
            display: flex;
            justify-content: center;
        }

        @media(max-width: 923px){

            .conteudo{
                width: 100%;
                display: flex;
                flex-direction: column;
                padding: 0;
                float: left;
            }
            
            .datas{
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .btn-filtrar{
                margin-top: 20px;
            }

            table{
                width: 100%;
            }

            .testi{
                display: none;
            }

            td:first-child { width: 30px;}
            td:first-child +td { width: 30px};
            td:first-child + td + td { width: 250px};
            td:first-child + td + td + td { width: 50px};
            td:first-child + td + td + td + td { width: 250px; text-align: left;};
            td:first-child + td + td + td + td +td { width: 150px; text-align: left;};

            .camposNota{
                text-align: left;
            }

            th ,td{
                padding: 0;
            }
        }

`;

export const Duplicatas = styled.div`

      #deData{
            margin-right: 1rem;
        }

        .conteudo{
            width: 80%;
            float: right;
            text-align: center;
            height: auto;
            margin-top: 1rem;
        }

        .tituloNotas{
            display: flex;
            font-weight: bold;
            margin-bottom: 1rem;
            border-bottom: 1px solid rgb(211, 201, 201, 1);
            width: 80%
        }

        
        .periodoData{
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            font-weight: bold;
        }
        
        .dats{
            display: flex;
            align-items: center;
            width: 100%;
        }

        .datas{
            padding-bottom: 2rem;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .formsFiltro{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
        }

        .filtroStatus{
            display: flex;
        }

        .btn-filtrar{
            width: 100px;
            margin-left: 1rem;
            font-weight: bold;
            height: 25px;
        }

        .btn-filtrar:hover{
            background-color: var(--filtrarHover-bg-color);
            color: #fff;
        }

        table {
            font-family: arial, sans-serif;
            border-collapse: collapse;
            width: 80%;
        }

        td, th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
        }

        .filtroSituacao{
            padding-left: 2rem;
        }

        .codSituacao{
            display: flex;
        }

        .divFiltros{
            width: 26.5%;
            display: flex;
            justify-content: space-between;
        }

        td:first-child { width: 30px  };
        td:first-child + td {  width: 70px };
        td:first-child + td + td {  width: 70px };
        td:first-child + td + td + td {  width: 80px };
        td:first-child + td + td + td + td{  width: 70px };
        td:first-child + td + td + td + td + td{  width: 80px };

        th{
            text-align: right;
        }

        .camposNota{
            text-align: right;
        }

        th, td{
            font-size: 12px;
        }

        .botoesFiltros{
            width: 80%;
            display: flex;
            justify-content: center;
            margin-top: 1rem;
        }

        .radioButton{
            margin-left: 1rem;
        }

        @media(max-width: 923px){

        .conteudo{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 0;
            float: left;
        }

        .btn-filtrar{
            margin-top: 20px;
        }

        table{
            width: 100%;
        }

        .condicao{
            display: none;
        }

        /* td:first-child { width: 30px;}
        td:first-child +td { width: 30px};
        td:first-child + td + td { width: 250px};
        td:first-child + td + td + td { width: 50px};
        td:first-child + td + td + td + td { width: 250px; text-align: left;};
        td:first-child + td + td + td + td +td { width: 150px; text-align: left;}; */

        .camposNota{
            text-align: left;
        }

        th ,td{
            padding: 0;
        }

        .divFiltros{
            width: 80%;
        }
        }

`;
