import * as React from 'react';
import { Container, PagConfiguracoes, TituloSecao } from '../styles';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand, faExpandArrowsAlt, faImage, faLink } from '@fortawesome/free-solid-svg-icons';

const Publicidade: React.FC = () => {
 
  return (
    <Container>
      <TituloSecao>
       Publicidade
      </TituloSecao>

      <PagConfiguracoes>
                <a href='/configuracao/publicidade/lstpublicidade' className="links">
                    <div className="links">
                        <FontAwesomeIcon className='icon' icon={faImage} size={"2x"} />
                        <p>Consulta</p>
                    </div>
                </a>

                <a href='/configuracao/publicidade/lstpublicidademodelo' className="links">
                    <div className="links">
                        <FontAwesomeIcon className='icon' icon={faExpandArrowsAlt} size={"2x"} />
                        <p>Modelos</p>
                    </div>
                </a>

            </PagConfiguracoes>
    </Container>
  )
};

export default Publicidade;
