import React from "react";
import { isAuthenticated } from "../paginas/Login/Auth";

import { Route, Redirect, RouteProps as ReactDOMRouteProps } from "react-router-dom";


  interface RouteProps extends ReactDOMRouteProps {
  component: React.ComponentType;
}

export const PrivateRoute:  React.FC<RouteProps> = ({ component: Component, ...rest }) => (

  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component />
      ) : (
        <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
      )
    }
  />
);
