import * as React from 'react';
import { Container, PagConfiguracoes, TituloSecao } from './styles';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faImage, faLink, faPen } from '@fortawesome/free-solid-svg-icons';

const Configuracao: React.FC = () => {
    return (
        <Container>
            <TituloSecao>
                Configurações
            </TituloSecao>

            <PagConfiguracoes>
                <Link to='/configuracao/lstlinks' className="links">
                    <div className="links">
                        <FontAwesomeIcon className='icon' icon={faLink} size={"2x"} />
                        <p>Links</p>
                    </div>
                </Link>
                <Link to='/configuracao/paginasestaticas' className="links">
                    <div className="links">
                        <FontAwesomeIcon className='icon' icon={faCode} size={"2x"} />
                        <p>Páginas Estaticas</p>
                    </div>
                </Link>
                <Link to='/configuracao/publicidade' className="links">
                    <div className="links">
                        <FontAwesomeIcon className='icon' icon={faImage} size={"2x"} />
                        <p>Publicidade</p>
                    </div>
                </Link>

                <Link to='/configuracao/layout' className="links">
                    <div className="links">
                        <FontAwesomeIcon className='icon' icon={faPen} size={"2x"} />
                        <p>Edição de Layout</p>
                    </div>
                </Link>

                {/* <Link to='/configuracao/publicidade/CdCapas' className="links">
                    <div className="links">
                        <FontAwesomeIcon className='icon' icon={faPen} size={"2x"} />
                        <p>Edição de capa</p>
                    </div>
                </Link> */}

            </PagConfiguracoes>

        </Container>
    )
};

export default Configuracao;
