import styled from 'styled-components';

export const PagLayout = styled.div`
    margin-left: 30%;

    .tituloPrincipal{
        margin-bottom: 2rem;
    }

    .campoTodo{
        margin-bottom: 2rem;
    }

    .coresCampo{
        display: flex;
        flex-direction: row;
    }
    
    .midiaCampo{
        display: flex;
        flex-direction: column;
    }

    .campo{
        width: 15%;
        margin-right: 3rem;
    }

    .cor{ 
        width: 100%;
        height: 35px;
    }

    .tituloCampo{
        font-size: 16px;
    }

    .botoesFiltros{
        margin-top: 1rem;
        /* width: 100%; */
        display: flex;
        flex-direction: row;
        /* justify-content: center; */
        margin-left: 30%;
    }

    .btn-novo{
        width: 70px;
        font-weight: bold;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-novo:hover{
        background-color: var(--filtrarHover-bg-color);
        color: #fff;
    }
`;
