import React, { createContext } from "react";
import { UsuarioLogadoDTO } from "../DTOs/UsuarioLogadoDTO";

type UserContextType = {
  user: UsuarioLogadoDTO;
  onLogin: (user: UsuarioLogadoDTO) => void;
  alteraUsuario: (user: UsuarioLogadoDTO) => void;
  onLogout: () => void;
};

export const initalValue = {
  Codacesso: 0,
  Codperfil: 0,
  Codusuario: 0,
  Codusuarioversa: 0,
  Nome: "",
  Ddd: "",
  Telefone: "",
  Empresa: "",
  Cnpj: "",
  Datinclusao: "",
  Indativo: 0,
  Userowner: 0,
  Codcliente: 0,
  Codempresa: 0,
  Codvendedor: 0,
  Nomcliente: "",
  Obsentrega: "",
  Tipofrete: 0,
  Codestado: 0,
  Codtransportadora: 0,
  Codrepresentante: 0,
  Cgccpf: "",
  Email: "",
  Codalmoxarifado: 0,
  Codempresacli: 0,
  Codoperacao: 0,
  Menu: {
    DataAlteracao: "",
    Menus: []
  },
  Indapresentaprecocusto: 0
};

const userContext = createContext<UserContextType>({ user: initalValue, onLogin: (user: UsuarioLogadoDTO) => {} , alteraUsuario: (user: UsuarioLogadoDTO) => {}, onLogout: () => {}});

export default userContext;

