import * as React from 'react';
import { CdpublicidadeBanner, Container } from './styles';
import bannerImg from '../../../assets/img/banner.png';
import { useEffect, useState } from 'react';
import api from '../../../service/api';
import { Publicidade } from '../../../DTOs/PublicidadeModeloDTO';
import Carousel from 'nuka-carousel';
import Loading from '../../Loading/Index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Banner: React.FC = () => {
  const [banner, setBanner] = useState<Publicidade[]>([]);
  const [slideIndex, setSlideIndex] = useState(1);
  const [removeLoading, setRemoveLoading] = useState(false);

  useEffect(() => {
    api.get(`/api/business/banner`).then((response) => {
      const now = new Date();
      const publicidade: Publicidade[] = response.data.filter((banner: Publicidade) => {
        const startDate = new Date(banner.Datvigencia);
        const endDate = new Date(banner.Datvalidade);
        endDate.setHours(23, 59, 59, 999);
        return startDate <= now && now <= endDate;
      });
      setBanner(publicidade);
      setRemoveLoading(true);
    });
  }, []);

  return (
    <Container>
      <CdpublicidadeBanner>
        <Carousel
          style={{ width: "100%" }}
          autoplay={true}
          wrapAround={true}
          slideIndex={slideIndex}
          renderCenterLeftControls={({ previousSlide }) => (
            <button onClick={previousSlide}>
              <FontAwesomeIcon icon={faChevronLeft} size={"1x"} className="icons" />
            </button>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <button onClick={nextSlide}>
              <FontAwesomeIcon icon={faChevronRight} size={"1x"} className="icons" />
            </button>
          )}
        >
          {
            banner.length == 0 && removeLoading == true ?
              <img src={bannerImg} className='testeimagembanner' />
              :
              banner.length > 0 && removeLoading == true ? (
                banner.map((Banner, index: number) => {
                  return (
                    <div className='imgBanner' key={index}>
                      <a href={Banner.Linkdestino}>
                        <img src={Banner.Pathimg} className='testeimagembanner' />
                      </a>
                    </div>
                  );
                })
              )
                :
                <Loading />
          }
        </Carousel>
      </CdpublicidadeBanner>
    </Container>
  );
};

export default Banner;
