import * as React from 'react';
import { Container, TituloSecao } from './styles';
import Grid from '../../componentes/Grid';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import userContext from '../../Context/UserContext';
import { SidebarConta } from './main';


const Sidebar: React.FC = () => {
    const currentlyUser = useContext(userContext);

  return (
    <Container>
         <SidebarConta>
         <div className="menu">
                <div className="minhaConta">
                    MINHA CONTA
                </div>
                    <div className="areas-perfil">
                        <ul>
                            {/* <li><Link to="#"> Meu perfil</Link></li> */}
                            <li><Link to="/minhaconta/meuspedidos">Meus Pedidos</Link></li>
                            <li><Link to="/minhaconta/notasfiscais">Notas Fiscais</Link></li>
                            <li><Link to="/minhaconta/duplicatasemaberto">Duplicatas em Aberto</Link></li>
                            <li></li>
                        </ul>
                    </div>
        </div>
         </SidebarConta>
        </Container>


  )
};

export default Sidebar;