import * as React from 'react';
import { Container, LstpublicidadeModelo, TituloSecao } from '../styles';
import { Link, } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import api from '../../../service/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useToast } from "../../../hooks/toast";
import { PublicidadeModeloDTO } from '../../../DTOs/PublicidadeModeloDTO';
import PublicidadeModeloTable from './publicidadeModeloTable';
import { SortColumnDTO } from '../../../DTOs/SortColumnDTO';
import _ from 'lodash';
import { ContainerPagination } from '../../../componentes/Grid/styles';
import Pagination from '../../../componentes/Paginacao/Pagination';

const LstPublicidadeModelo: React.FC = () => {
    const { addToast } = useToast();
    const [publicidadeModelo, setPublicidadeModelo] = useState<PublicidadeModeloDTO[]>([]);
    const [itensPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(-1);
    const [currentItens, setCurrentItens] = useState<PublicidadeModeloDTO[]>([]);
    const pages = Math.ceil(publicidadeModelo.length / itensPerPage);
    const startIndex = currentPage * itensPerPage;
    const endIndex = startIndex + itensPerPage;
    const [sortColumn, setSortColumn] = useState<SortColumnDTO>({ path: "Dscpublicidade", order: "asc" });
    const [reload, setReload] = useState(false);

    function ListaPublicidadeModelo(filtro: any) {
        // Busca os links da API
        api.post(`api/businnes/getfiltropublicidademodelo`, filtro).then((response) => {
            const publicidadeModelo = response.data;
            setPublicidadeModelo(publicidadeModelo);
            setCurrentPage(0);
            setCurrentItens(publicidadeModelo.slice(startIndex, endIndex));
            setReload(true);
        });
    }

    function FiltroPublicidadeModelo(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let formData = new FormData(event.currentTarget);
        let codigo = formData.get("txtCodigo") as string;
        let nome = formData.get("txtNome") as string;

        var filtro = {
            Codpublicidademodelo: codigo,
            Dscpublicidademodelo: nome,
        }

        ListaPublicidadeModelo(filtro);
    }

    useEffect(() => {
        setCurrentItens(publicidadeModelo.slice(startIndex, endIndex));
    }, [currentPage]);

    useEffect(() => {

        var filtro = {
            Codpublicidademodelo: 0,
            Dscpublicidademodelo: "",
        }

        ListaPublicidadeModelo(filtro);

    }, [reload]);

    const handleExcluiPublicidadeModelo = useCallback((id?: number) => {
        try {

            let paginasCopia = [...currentItens];
            let paginasAtuais = paginasCopia.filter((item) => item.Codpublicidademodelo !== id);

            setPublicidadeModelo(paginasAtuais);

            api.delete(`api/business/publicidademodelo/${id}`);

            addToast({
                type: 'success',
                title: 'Publicidade excluída com sucesso!'
            });

            setReload(!reload);
        } catch (error) {
            addToast({
                type: 'error',
                title: 'Erro ao excluir a publicidade!'
            });
        }
    }, [reload, addToast]);

    const handleSort = useCallback((sortColumn: SortColumnDTO) => {
        setSortColumn(sortColumn);
    }, [sortColumn]);

    useEffect(() => {
        if (sortColumn != null && currentItens != undefined) {
            const sorted = _.orderBy(currentItens, [sortColumn.path], [sortColumn.order]);
            setCurrentItens(sorted);
        }
    }, [sortColumn]);

    return (
        <Container>
            <TituloSecao>
                Lista de Publicidades
            </TituloSecao>

            <LstpublicidadeModelo>

                <form onSubmit={FiltroPublicidadeModelo}>
                    <div className="codCampo">
                        <p>Código</p>
                        <input type="text" className='inputText' name='txtCodigo' />
                    </div>

                    <div className="nomCampo">
                        <p>Descrição</p>
                        <input type="text" className='inputDescricao' name='txtNome' />
                    </div>

                    <div className='botoesFiltros'>
                        <Link to="/configuracao/publicidade/cdpublicidadeModelo"><button type='submit' className="btn-novo">Novo</button></Link>
                        <button type='submit' className="btn-novo">Pesquisar</button>
                    </div>
                </form>

                <PublicidadeModeloTable
                    publicidade={currentItens}
                    sortColumn={sortColumn}
                    // onLike={this.handleLike}
                    onDelete={handleExcluiPublicidadeModelo}
                    onSort={handleSort}
                />

                <ContainerPagination>
                    <Pagination pages={pages} setCurrentPaged={setCurrentPage} currentPage={currentPage} />
                </ContainerPagination>
            </LstpublicidadeModelo>
        </Container>
    )
};

export default LstPublicidadeModelo;
