import * as React from 'react';
import { Container, TituloSecao } from './styles';
import Grid from '../../componentes/Grid';
import { useParams } from 'react-router-dom';
import userContext from '../../Context/UserContext';
import { useContext } from 'react';

const Grupo: React.FC = () => {
  const { id, name, filtro }: any = useParams();
  const currentlyUser = useContext(userContext);
  const { Codcliente, Codempresa } = currentlyUser.user;

  return (
    <Container>
      <TituloSecao>
        Grupo {id} - {decodeURIComponent(name)}
      </TituloSecao>
      {filtro == "maisvendidos" ?
        <Grid MaisVendidos={1} Grupo={id} Codcliente={Codcliente} Codempresacli={Codempresa} /> :
        filtro == "lancamentos" ?
          <Grid Lancamento={1} Grupo={id} Codcliente={Codcliente} Codempresacli={Codempresa} /> :
          <Grid Grupo={id} Codcliente={Codcliente} Codempresacli={Codempresa} />
      }
    </Container>
  )
};

export default Grupo;
