import * as React from 'react';
import { ContainerFlutuante, Tabela } from './styles';
import api from '../../service/api';
import { useCallback, useContext, useEffect, useState } from 'react';
import userContext from '../../Context/UserContext';
import { ClienteDTO } from '../../DTOs/ClienteDTO';
import { Link } from 'react-router-dom';
import cartContext from '../../Context/CartContext';
import { CarrinhoDTO } from '../../DTOs/produtoDTO';
import { UsuarioLogadoDTO } from '../../DTOs/UsuarioLogadoDTO';

const ListaClientesVen: React.FC = () => {
  const [clientes, setClientes] = useState<ClienteDTO[]>([]);
  const currentlyUser = useContext(userContext);
  const currentlyCart = useContext(cartContext);

  function BuscaCarrinho(codcliente: number) {
    localStorage.removeItem('@VersaB2B:carrinho');
    currentlyCart.limpaCarrinho();

    api.get(`api/business/carrinho/${codcliente}`).then((response) => {

      // Desestrutura o objeto da API com somente os campos necessários na interface
      // e formata o valor para exibição
      const carrinho: CarrinhoDTO = response.data;

      // Seta os produtos no state para usar na página
      let vlrTotal = 0;

      for (let i = 0; i < carrinho.Produtos.length; i++) {
        // Lê o produto atual
        let produto = carrinho.Produtos[i];

        if (produto.Desconto == null) {
          produto.Desconto = 0;
        }
        // Calcula o total, multiplicando o valor do item pela quantidade dele no carrinho
        vlrTotal += produto.VlrTotal - (produto.VlrTotal * (produto.Desconto / 100));
        carrinho.Produtos[i].Src = process.env.REACT_APP_IMAGEMPATH + carrinho.Produtos[i].Src;
        if (produto.Qtd) {
          carrinho.Produtos[i].VlrTotal = (produto.Vlr * produto.Qtd - (produto.Vlr * produto.Qtd * (produto.Desconto / 100)));
        }
      }
      carrinho.VlrTotal = vlrTotal;

      currentlyCart.alteraCarrinho(carrinho);

    });

  };

  const EscolheCliente = useCallback((cliente: ClienteDTO): void => {

    let usuarioCopia: UsuarioLogadoDTO = { ...currentlyUser.user };

    usuarioCopia.Codcliente = cliente.Codcliente;
    usuarioCopia.Nomcliente = cliente.Nomcliente;
    usuarioCopia.Codempresacli = cliente.Codempresacli;

    localStorage.setItem('@VersaB2B:login', JSON.stringify(usuarioCopia));
    currentlyUser.onLogin(usuarioCopia);
  }, [currentlyUser.user]);


  useEffect(() => {
    if (currentlyUser.user.Codvendedor) {
      api.get(`api/cliente/vendedor/${currentlyUser.user.Codvendedor}`).then((response) => {

        // Desestrutura o objeto da API com somente os campos necessários na interface
        // e formata o valor para exibição
        const clientes: ClienteDTO[] = response.data;
        // Seta os produtos no state para usar na página
        setClientes(clientes);
      });
    }
  }, []);

  return (
    <ContainerFlutuante>
      <Tabela>
        <thead>
          <tr>
            <th>Código</th>
            <th>Nome</th>
            <th>Cnpj</th>
          </tr>
        </thead>
        <tbody>
          {clientes?.map((item: ClienteDTO, index: any) => {
            return (
              <tr key={index}>
                <td>{item.Codcliente}</td>
                <td><Link to="/" onClick={() => {
                  return EscolheCliente(item);
                }}>{item.Nomcliente}</Link></td>
                <td>{item.Cgccpf}</td>
              </tr>
            )
          })}
        </tbody>
      </Tabela>
    </ContainerFlutuante>
  )
};

export default ListaClientesVen;

