import styled from "styled-components";

export const Container = styled.div`
  margin: 160px auto 0;
  /* justify-content: center; */
  width: 100%;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-content: stretch;
  }

  @media (min-width: 769px) {
    margin: 40px auto 0;
  }

  @media (min-width: 1000px) {
    margin: 36px auto 0;
  }
`;


export const CdpublicidadeBanner = styled.div`
padding-top: 8px;
display: flex;
align-items: center;

button{
  background-color: transparent;
  border: none;
}

.icons{
  font-size: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: #313131;
}

.icons:hover{
  color: #423e3e;
}

.imgBanner{
  width: 100%;
}

img {
    margin: 0 auto;
    width: 100%;
}

.testeimagembanner{
  width: 100%;
  height: 100%;
}

@media (min-width: 376px) and (max-width: 436px) {
  .testeimagembanner{
    width: 100%;
    height: 10rem;
    /* height: 250px */
  }
}



/* Slideshow container */
.slideshow-container {
  max-width: 100%;
  /* position: relative; descomentar */
  margin: auto;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  background-color: red;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.actived {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {font-size: 11px}
}

/* Next & previous buttons */
.prev, .next {

  cursor: pointer;
  position: absolute;
  /* top: 10%; */
  width: auto;
  /* margin-top: -22px; */
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}
`;

export const Carousel = styled.div`
  /* position: absolute; */
  width: 100%;
  display: flex;

  .imgBanner{
    width: 90%;
    height: 350px;
  }
`;