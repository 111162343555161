import * as React from 'react';
import { Container, PagLinks, TituloSecao } from '../styles';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PaginaEstaticaDTO } from '../../../DTOs/PaginaEstaticaDTO';
import { useCallback, useEffect, useState } from 'react';
import api from '../../../service/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useToast } from "../../../hooks/toast";
import { MenuLinksDTO } from '../../../DTOs/MenuDTO';
import { SortColumnDTO } from '../../../DTOs/SortColumnDTO';
import LinksTable from './linksTable';
import _ from 'lodash';
import { ContainerPagination } from '../../../componentes/Grid/styles';
import Pagination from '../../../componentes/Paginacao/Pagination';

const MenuLinks: React.FC = () => {
    const { addToast } = useToast();
    const { id, name }: any = useParams();
    const [menuLinks, setMenuLinks] = useState<MenuLinksDTO[]>([]);
    const [sortColumn, setSortColumn] = useState<SortColumnDTO>({ path: "Dsclink", order: "asc" });
    const [itensPerPage] = useState(30);
    const [currentPage, setCurrentPage] = useState(-1);
    const [currentItens, setCurrentItens] = useState<MenuLinksDTO[]>([]);
    const pages = Math.ceil(menuLinks.length / itensPerPage);
    const startIndex = currentPage * itensPerPage;
    const endIndex = startIndex + itensPerPage;
    const [reload, setReload] = useState(false);

    useEffect(() => {
        setCurrentItens(menuLinks.slice(startIndex, endIndex));
    }, [currentPage]);

    function ListaLinksMenus(filtro: any) {
        // Busca os links da API
        api.post(`api/businnes/getfiltrolinks`, filtro).then((response) => {
            const menuLinks = response.data;
            setMenuLinks(menuLinks);
            setCurrentPage(0);
            setCurrentItens(menuLinks.slice(startIndex, endIndex));
        });
    }

    const handleSort = useCallback((sortColumn: SortColumnDTO) => {
        setSortColumn(sortColumn);
    }, [sortColumn]);

    useEffect(() => {
        if (sortColumn != null && currentItens != undefined) {
            const sorted = _.orderBy(currentItens, [sortColumn.path], [sortColumn.order]);
            setCurrentItens(sorted);
        }
    }, [sortColumn]);

    function FiltroPaginaEstaticas(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let formData = new FormData(event.currentTarget);
        let codigo = formData.get("txtCodigo") as string;
        let nome = formData.get("txtNome") as string;

        var filtro = {
            Codlink: codigo,
            DscLink: nome,
        }

        ListaLinksMenus(filtro);
    }

    useEffect(() => {

        var filtro = {
            Codlink: 0,
            DscLink: "",
        }

        ListaLinksMenus(filtro);

    }, [reload]);

    const handleExcluiLinkMenu = useCallback((id?: number) => {
        try {

            let paginasCopia = [...currentItens];
            let paginasAtuais = paginasCopia.filter((item) => item.Codlink !== id);

            setMenuLinks(paginasAtuais);

            api.delete(`api/business/menu/${id}`);

            addToast({
                type: 'success',
                title: 'Link excluída com sucesso'
            });

            setReload(!reload);

        } catch (error) {
            addToast({
                type: 'error',
                title: 'Erro ao excluir link'
            });
        }
    }, [reload, addToast]);

    return (
        <Container>
            <TituloSecao>
                Filtros
            </TituloSecao>

            <PagLinks>
                <form onSubmit={FiltroPaginaEstaticas}>
                    <div className="codCampo">
                        <p>Código</p>
                        <input type="text" className='inputText' name="txtCodigo" />
                    </div>

                    <div className="nomePosicao">
                        <div className="nomCampo">
                            <p>Descrição:</p>
                            <input type="text" className='inputTextNome' name="txtNome" />
                        </div>
                    </div>

                    <div className='botoesFiltros'>
                        <Link to="/configuracao/cdlink"><button className="btn-novo">Novo</button></Link>
                        <button type='submit' className="btn-novo">Pesquisar</button>
                    </div>
                </form>

                <LinksTable
                    links={currentItens}
                    sortColumn={sortColumn}
                    // onLike={this.handleLike}
                    onDelete={handleExcluiLinkMenu}
                    onSort={handleSort}
                />

                <ContainerPagination>
                    {/* <Pagination pages={pages} setCurrentPaged={setCurrentPage} currentPage={currentPage} /> */}
                </ContainerPagination>

            </PagLinks>
        </Container>
    )
};

export default MenuLinks;


