import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../../../componentes/Table/Table";
import { MenuLinksDTO } from "../../../DTOs/MenuDTO";
import { PaginaEstaticaDTO } from "../../../DTOs/PaginaEstaticaDTO";
import { SortColumnDTO } from "../../../DTOs/SortColumnDTO";
import linksTable from './linksTable';

type MyProps = {
  // using `interface` is also ok
  links?: Array<MenuLinksDTO>;
  sortColumn?: SortColumnDTO;
  onSort: any;
  onDelete: Function;

};

function retornaNomeCategoria(Indcategoria: number | undefined){
  switch (Indcategoria) {
    case 0:
      return "Normal";
    case 1:
      return "Grupo";
    case 2:
      return "Mais Vendidos";
    case 3:
      return "Categoria por Grupo";
    default:
      return "";
  }
}

class LinksTable extends Component<MyProps> {
  chave = "Codlink";
  columns = [
    {
      path: "Codlink",
      label: "Código",

    },
    {
      path: "Dsclink",
      label: "Descrição",
      content: (links: MenuLinksDTO) => <Link to={`/configuracao/cdlink/${links.Codlink}`}>{links.Dsclink}</Link>
    },
    {
      path: "Indcategoria",
      label: "Tipo de Link",
      content: (links: MenuLinksDTO) =>  <span>{retornaNomeCategoria(links.Indcategoria)} </span>
    },
    // { path: "dailyRentalRate", label: "Rate" },
    // {
    //   key: "like",
    //   //   content: (movie: { liked: any; }) => (
    //   //     <Like liked={movie.liked} onClick={() => this.props.onLike(movie)} />
    //   //   )
    // },
    {
      key: "delete",
      content: (links: MenuLinksDTO) => (
        <button
          onClick={() => this.props.onDelete(links.Codlink)}
          className="btn btn-danger btn-sm"
        >
          {/* <FontAwesomeIcon icon={faTrash} size={"1x"} className="pinEsquerdo" />  */}
          Excluir
        </button>
      )
    }
  ];

  handleSort = (sortColumn: SortColumnDTO) => {
    this.setState({ sortColumn });
  };



  render() {
    const { sortColumn, onSort, links } = this.props;
    // const paginasEstaticas = this.getPagedData();

    return (
      <Table
        columns={this.columns}
        data={links}
        sortColumn={sortColumn}
        onSort={onSort}
        chave={this.chave}
      />
    );
  }
}

export default LinksTable;
