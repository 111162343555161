import React from "react";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import "./Tabela.css";

interface Table {
  columns: any;
  sortColumn: any;
  onSort: any;
  data: any;
  chave: string;
}

const Table = ({ columns, sortColumn, onSort, data, chave }: Table) => {

  return (
    <table className="table">
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody columns={columns} data={data} chave={chave}/>
    </table>
  );
};

export default Table;
