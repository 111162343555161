import * as React from 'react';
import { ContainerFooter} from './styles';
import logo from '../../assets/img/logoVersab2bpreto.png';

import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import imageContext from '../../Context/ImagesContext';

const SubFooter: React.FC = () => {
    const currentlyImages = useContext(imageContext);
    const [logo, setLogo] = useState(currentlyImages.images.Logo);
    // const { images } = useContext(imageContext);
    const { images, alterarLogo } = useContext(imageContext);
    
    useEffect(() => {
        const logoPath = localStorage.getItem('logoPath'); // Retrieve the logo path from localStorage
        if (logoPath) {
          alterarLogo(logoPath); // Update the logo path in the imageContext
        }
      }, []);
      
    return (
        <ContainerFooter>

            <div className='imgSubFooter'>
                <img src={images.Logo} alt="Logo" />
            </div>

            <div className='textoFooter'>
                {/* <p>Todos os direitos reservados. CNPJ 91.973.313/0001-81 - VITROLA EDITORA E DISTRIBUIDORA LTDA | Rua Das Camelias, 321, Aparecida -Frederico Westphalen - RS</p> */}
                <p>Todos os direitos reservados. CNPJ 00.829.816/0001-22 - Partner Sistemas </p>
                <p>Avenida Cristóvão Colombo, 2948 - Sala 801 - Floresta, Porto Alegre - RS</p>
            </div>

        </ContainerFooter>
    )
};

export default SubFooter;
