import styled from 'styled-components';

export const Container = styled.div`
  color: #000000;
  top: 100px;
  margin-bottom: 4rem;
  margin-top: 3rem;

  @media (max-width: 325px) {
    max-width: 300px;
  }
`;

export const TituloSecao = styled.h3`
  padding: 20px;
  color: #313131;
  font-size: 26px;
  text-align: left;
  margin-bottom: 12px;

  @media(max-width: 923px){
    padding: 20px;
    color: #313131;
    font-size: 26px;
    text-align: left;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
  }
`;
