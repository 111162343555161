import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { ProdutoQuantidadeDTO } from '../../DTOs/produtoQuantidadeDTO';
import { Container, FormInputsQuantidade } from './styles';
import cartContext from '../../Context/CartContext';

interface IProdutoQuantidade {
  id: number;
  quantidade?: number | 0;
  aumentarQuantidade: (idProduto: number) => void;
  diminuirQuantidade: (idProduto: number) => void;
  produtosQuantidades?: ProdutoQuantidadeDTO[];
}

export const ProdutoQuantidade: React.FC<IProdutoQuantidade> = ({ id, aumentarQuantidade, diminuirQuantidade, produtosQuantidades, quantidade }) => {
  const currentlyCart = useContext(cartContext);
  const [selectedValue, setSelectedValue] = useState(false);

  const handleAumentarQuantidade = () => {
    aumentarQuantidade(id);
  }

  const handleDiminuirQuantidade = () => {
    diminuirQuantidade(id);
  }

  const handleAlteraQuantidade = (id: number, qtd: number) => {
    currentlyCart.alteraQuantidade(id, qtd);
  }

  function SelecionaValor(id: number) {
    var input = (document.getElementById('input' + id) as HTMLInputElement);
    if (selectedValue == false) {
      input.select();
    } else {
      setSelectedValue(false);
    }

  }
  return (
    <Container>
      <FormInputsQuantidade>
        <button type="button" onClick={() => handleDiminuirQuantidade()} >
          <FontAwesomeIcon icon={faMinus} />
        </button>
        <input id={"input" + id}
          onSelect={() => SelecionaValor(id)}
          onKeyDown={(e) => { e.code == "Backspace" || e.code == "Delete" ? setSelectedValue(false) : setSelectedValue(true) }}
          placeholder="1" value={currentlyCart.cart.Produtos?.find((p) => { return p.CodProduto === id; })?.Qtd}
          onChange={(e) => handleAlteraQuantidade(id, Number(e.target.value))
          } />
        <button type="button" onClick={() => handleAumentarQuantidade()}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </FormInputsQuantidade>
    </Container >
  )
};

export default ProdutoQuantidade;
