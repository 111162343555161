import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useToast } from '../../hooks/toast';
import Botao from '../../componentes/Botao';
import ModalConfirmacao from '../../componentes/ModalConfirmacao'
import {
  Container, CarrinhoTitulo, CarrinhoBody, CarrinhoBodyHeader, CarrinhoBodyItens,
  CarrinhoBodyImagemNomeProduto, CarrinhoBodyImagemNomeProdutoLinkImagem,
  CarrinhoBodyImagemNomeProdutoLinkNome, CarrinhoBodyItensQuantidades,
  CarrinhoBodyItensValores, CarrinhoFooter, CarrinhoResponsivo, BtnRemoverProduto, Subtotal, CarrinhoDesktop
} from './styles';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { ProdutoQuantidade } from '../../componentes/ProdutoQuantidade';
import { arredonda2, formatarValores } from '../../helpers/valores';
import { CarrinhoDTO } from '../../DTOs/produtoDTO';
import cartContext from '../../Context/CartContext';
import ProdutoQuantidadeCarrinho from '../../componentes/Carrinho/ProdutoQuantidade';
import api from '../../service/api';
import userContext from '../../Context/UserContext';
import ModalFinalizacao from '../../componentes/ModalFinalizacao';
import { useHistory } from 'react-router-dom';
import pinContext from '../../Context/PinContext';
import useCalculaTotais from '../../hooks/useCalculaTotais';
import { FaSpinner } from 'react-icons/fa';

const Carrinho: React.FC = () => {
  const { addToast } = useToast();
  const currentlyCart = useContext(cartContext);
  const currentlyUser = useContext(userContext);
  const currentlyPin = useContext(pinContext);
  let history = useHistory();
  const [carrinho, setCarrinho] = useState<CarrinhoDTO>(() => {
    // Busca no localStorage o carrinho
    const carrinhoLocal = localStorage.getItem('@VersaB2B:carrinho');

    // Se existir alguma coisa no localStorage
    if (carrinhoLocal) {

      // Faz um cast pra transformar a string JSON em um objeto
      const carrinhoObj = JSON.parse(carrinhoLocal);

      // Limpa o array de quantidades de produtos do localStorage do usuário
      localStorage.removeItem('@VersaB2B:ProdutosQuantidades');

      // Retorna o objeto para o state
      return carrinhoObj;
    }

    // Se não tiver nada, simplesmente retorna um array vazio
    return [];
  });

  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const [qtdDisponivel, setQtdDisponivel] = useState(0);
  const [vlrDisponivel, setVlrDisponivel] = useState("");
  const [qtdEncomenda, setQtdEncomenda] = useState(0);
  const [vlrEncomenda, setVlrEncomenda] = useState("");
  const [qtdTotal, setQtdTotal] = useState(0);
  const [vlrTotal, setVlrTotal] = useState("");
  const [descontoCarrinho, setDescontoCarrinho] = useState("");
  const [valorMercadorias, setValorMercadorias] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [vlrTotalComDescontoCarrinho, setvlrTotalComDesconto] = useState(0);

  const { totais } = useCalculaTotais();

  const handleRemoverItem = useCallback((id: number) => {
    try {
      currentlyCart.removeItem(id);
      addToast({
        type: 'success',
        title: 'Produto removido do carrinho'
      });

    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao remover item do carrinho',
        description:
          'Ocorreu um erro ao remover o produto do carrinho' + error,
      });
    }
  }, [currentlyCart.cart.Produtos, addToast]);


  // Valida a opção selecionada pelo usuário no modal de confirmação
  const handleOpcaoSelecionadaModalConfirmacao = useCallback((opcao: string): void => {
    // Se o usuário clicar em SIM no modal, vamos limpar o carrinho
    if (opcao === "S") {

      currentlyCart.esvaziaCarrinho();

      totalCarrinho();

      addToast({
        type: 'success',
        title: 'Seu carrinho foi limpo.'
      });
    }

    // Tratativas em JS para fechar o modal
    let modal = document.getElementById('react-confirm-alert');
    let body = document.getElementsByTagName('body').item(0);
    let svg = document.getElementById('react-confirm-alert-firm-svg');

    if (modal && body && svg) {
      modal.style.visibility = 'hidden';
      svg.style.visibility = 'hidden';
      modal?.classList.remove('react-confirm-alert-svg');
      body?.classList.remove('react-confirm-alert-body-element');
    }

  }, [addToast]);

  // Valida a opção selecionada pelo usuário no modal de confirmação
  const handleOpcaoSelecionadaModalFinalizacao = useCallback((opcao: string): void => {
    // Se o usuário clicar em SIM no modal, vamos limpar o carrinho
    if (opcao === "S") {

    }

    // Tratativas em JS para fechar o modal
    let modal = document.getElementById('react-confirm-alert');
    let body = document.getElementsByTagName('body').item(0);

    if (modal && body) {
      modal.style.visibility = 'hidden';
      body?.classList.remove('react-confirm-alert-body-element');
    }

  }, [currentlyCart.cart]);

  // Exibe o modal de confirmação para o usuário
  const mostrarModalConfirmacaoLimparCarrinho = useCallback(({ onClose }) => {
    return (
      <ModalConfirmacao opcaoSelecionada={handleOpcaoSelecionadaModalConfirmacao} tituloModal="Esvaziar Carrinho" textoModal="Tem certeza que deseja esvaziar seu carrinho?" />
    )
  }, [handleOpcaoSelecionadaModalConfirmacao]);

  // Exibe o modal de finalização carrinho para o usuário
  const mostrarModalConfirmacaofinalizarCarrinho = useCallback(({ onClose }) => {
    return (
      <ModalFinalizacao opcaoSelecionada={handleOpcaoSelecionadaModalFinalizacao} tituloModal="Mensagem" textoModal="Obrigado por comprar conosco!" />
    )
  }, [handleOpcaoSelecionadaModalFinalizacao]);


  // function BuscaCarrinho(codcliente: number) {
  //   localStorage.removeItem('@VersaB2B:carrinho');
  //   currentlyCart.limpaCarrinho();

  //   api.get(`api/business/carrinho/${codcliente}`).then((response) => {

  //     // Desestrutura o objeto da API com somente os campos necessários na interface
  //     // e formata o valor para exibição
  //     const carrinho: CarrinhoDTO = response.data;

  //     // Seta os produtos no state para usar na página
  //     let vlrTotal = 0;

  //     for (let i = 0; i < currentlyCart.cart.Produtos.length; i++) {
  //       // Lê o produto atual
  //       let produto = carrinho.Produtos[i];

  //       if (produto.Desconto == null) {
  //         produto.Desconto = 0;
  //       }
  //       // Calcula o total, multiplicando o valor do item pela quantidade dele no carrinho
  //       vlrTotal += produto.VlrTotal - (produto.VlrTotal * (produto.Desconto / 100));
  //       carrinho.Produtos[i].Src = process.env.REACT_APP_IMAGEMPATH + carrinho.Produtos[i].Src;
  //       carrinho.Produtos[i].VlrTotal = (produto.Vlr * produto.Qtd - (produto.Vlr * produto.Qtd * (produto.Desconto / 100)));
  //     }
  //     carrinho.VlrTotal = vlrTotal;

  //     currentlyCart.alteraCarrinho(carrinho);
  //   });

  // };

  


  // Captura a ação de clicar no botão de esvaziar carrinho
  function FinalizarPedido() {
    // let produtoscarrinhoCopia = [...currentlyCart.cart.Produtos];
    // produtoscarrinhoCopia = produtoscarrinhoCopia.filter((item) => item.CodProduto !== -1);
    setDisabled(true);
    let vlrTotal = vlrTotalComDescontoCarrinho;

    let carrinhoCopia: CarrinhoDTO = {
      ...currentlyCart.cart,
      CodCliente: currentlyUser.user.Codcliente,
      CodEmpresa: currentlyUser.user.Codempresa,
      CodempresaCli: currentlyUser.user.Codempresacli,
      CodUsuario: currentlyUser.user.Codusuarioversa,
      CodAcesso: currentlyUser.user.Codacesso,
      CodAlmoxarifado: currentlyUser.user.Codalmoxarifado,
      CodSituacao: 0,
      VlrTotal: vlrTotal
    };

    localStorage.setItem('@VersaB2B:pedido', JSON.stringify(carrinhoCopia));

    // Busca os produtos da API
    api.post(`api/business/finalizacarrinho`, carrinhoCopia).then(() => {
     
      // addToast({
      //   type: 'success',
      //   title: 'Pedido finalizado com sucesso'
      // });

      // Atualiza o state limpando ele
      setCarrinho({ Produtos: [] });
      currentlyCart.limpaCarrinho();
      currentlyCart.buscaCarrinho(currentlyCart.cart.CodCliente ? currentlyCart.cart.CodCliente : 0);
      
      history.push("/");
      handleFinalizarCarrinho();
    });


  }

  // Captura a ação de clicar no botão de esvaziar carrinho
  const handleLimparCarrinho = useCallback(() => {

    // Tratativas em JS para exibir o modal, após ele ter sido fechado ou dispensado
    let modal = document.getElementById('react-confirm-alert');
    let body = document.getElementsByTagName('body').item(0);

    if (modal && body) {
      modal.style.visibility = 'visible';
      body?.classList.add('react-confirm-alert-body-element');
    }

    // Chama o modal
    confirmAlert({ customUI: mostrarModalConfirmacaoLimparCarrinho })
  }, [mostrarModalConfirmacaoLimparCarrinho]);

  // CAPTURA AÇÃO AO CLICAR EM FINALIZAR CARRINHO
  const handleFinalizarCarrinho = useCallback(() => {
    
  
    // Tratativas em JS para exibir o modal, após ele ter sido fechado ou dispensado
    let modal = document.getElementById('react-confirm-alert');
    let body = document.getElementsByTagName('body').item(0);

    if (modal && body) {
      modal.style.visibility = 'visible';
      body?.classList.add('react-confirm-alert-body-element');
    }

    // Chama o modal
    confirmAlert({ customUI: mostrarModalConfirmacaofinalizarCarrinho })
  }, [mostrarModalConfirmacaofinalizarCarrinho]);



  // Calcula o total do carrinho
  const totalCarrinho = useCallback(() => {
    let totalVal = 0;
    let descontoValor = 0;
    let vlrMercadoria = 0;
    let qtdDisponivel = 0;
    let qtdEncomenda = 0;
    let qtdTotal = 0;
    let vlrDisponivel = 0;
    let vlrEncomenda = 0;
    let vlrTotal = 0;


    for (let i = 0; i < currentlyCart.cart.Produtos.length; i++) {

      // Lê o produto atual
      let produto = currentlyCart.cart.Produtos[i];



      // Calcula o total
      if (produto.isDeleted == false) {
        if (produto.Qtd) {
          vlrMercadoria += produto.Vlr * produto.Qtd;

          if (produto.Qtdestoque > 0) {
            qtdDisponivel += produto.Qtd;
            vlrDisponivel += produto.Vlr * produto.Qtd;


          } else {
            qtdEncomenda += produto.Qtd;
            vlrEncomenda += produto.Vlr * produto.Qtd;
          }

          qtdTotal = qtdDisponivel + qtdEncomenda;
          vlrTotal = vlrDisponivel + vlrEncomenda;
        }


        // totalVal += produto.VlrTotal;
      }
    }

    descontoValor = vlrMercadoria - totalVal;

    //   // Seta no state o valor total
    // setValorTotalCarrinho(formatarValores(totalVal));
    // setDescontoCarrinho(formatarValores(descontoValor));
    // totalVal = arredonda2(totalVal);
    // setvlrTotalComDesconto(totalVal);
    // setValorMercadorias(formatarValores(vlrMercadoria));


    setVlrTotal(formatarValores(vlrTotal));
    setQtdTotal(qtdTotal);
    setVlrEncomenda(formatarValores(vlrEncomenda));
    setQtdEncomenda(qtdEncomenda);
    setVlrDisponivel(formatarValores(vlrDisponivel));
    setQtdDisponivel(qtdDisponivel);

  }, [currentlyCart.cart.Produtos]);




  useEffect(() => {
    currentlyPin.desativaCarrinho();
  }, []);


  useEffect(() => {
    // Grava no localStorage do usuário as quantidades
    localStorage.setItem('@VersaB2B:carrinho', JSON.stringify(currentlyCart.cart));

    totalCarrinho();

  }, [totalCarrinho]);

  useEffect(() => {
    if (valorTotalCarrinho != "") {
      localStorage.setItem('@VersaB2B:valorTotalCarrinho', JSON.stringify(valorTotalCarrinho));
    }

  }, [valorTotalCarrinho]);

  return (

    <Container isEmpty={currentlyCart.cart.Produtos.length === 0}>
      {currentlyCart.cart.Produtos.length === 0 && (
        <p>Seu carrinho está vazio!</p>
      )}

      {currentlyCart.cart.Produtos.length > 0 && (
        <>
          <CarrinhoDesktop>
            <CarrinhoTitulo>
              Meu Carrinho
            </CarrinhoTitulo>

            <CarrinhoBody>
              <CarrinhoBodyHeader>
                <h3>Produto</h3>
                <h3>Valor Unitário</h3>
                <h3>Desconto</h3>
                <h3>Quantidade</h3>
                <h3>Estoque</h3>
                <h3>Encomenda</h3>
                <h3>Total</h3>
                <h3></h3>
              </CarrinhoBodyHeader>

              {currentlyCart.cart.Produtos.map((produto) => (
                produto.CodProduto >= 0 && produto.isDeleted == false ?
                  <CarrinhoBodyItens key={produto.CodProduto} color={produto.Qtd != null && produto.Qtd > produto.Qtdestoque ? 'red' : 'black'}>
                    <CarrinhoBodyImagemNomeProduto>
                      <CarrinhoBodyImagemNomeProdutoLinkImagem>
                        {/* <img src={produto.Src} alt={produto.Titulo} /> */}
                        <div>
                          <a href={`/produtos/${produto.CodProduto}/detalhes`}>{<img src={produto.Src} />}</a>
                        </div>
                      </CarrinhoBodyImagemNomeProdutoLinkImagem>

                      <CarrinhoBodyImagemNomeProdutoLinkNome>
                        <a href={`/produtos/${produto.CodProduto}/detalhes`} className="produto-titulo">{produto.Titulo}</a>
                      </CarrinhoBodyImagemNomeProdutoLinkNome>
                    </CarrinhoBodyImagemNomeProduto>

                    <CarrinhoBodyItensValores>
                      {formatarValores(produto.Vlr)}
                    </CarrinhoBodyItensValores>
                    <CarrinhoBodyItensValores>
                      {`${produto.Desconto}%`}
                    </CarrinhoBodyItensValores>

                    <CarrinhoBodyItensQuantidades>
                      <ProdutoQuantidade
                        aumentarQuantidade={() => currentlyCart.adicionaQuantidade(produto.CodProduto)}
                        diminuirQuantidade={() => produto.Qtd != null && produto.Qtd > 1 ? currentlyCart.removeQuantidade(produto.CodProduto) : null}
                        quantidade={produto.Qtd}
                        id={produto.CodProduto}>
                      </ProdutoQuantidade>
                    </CarrinhoBodyItensQuantidades>
                    <CarrinhoBodyItensValores>
                      {produto.Qtdestoque > 0 ? produto.Qtdestoque : 0}

                    </CarrinhoBodyItensValores>
                    <CarrinhoBodyItensValores>
                      {produto.Qtd != null && produto.Qtd > produto.Qtdestoque ? produto.Qtd - produto.Qtdestoque : 0}
                    </CarrinhoBodyItensValores>
                    <CarrinhoBodyItensValores>
                      {formatarValores(produto.VlrTotal)}
                    </CarrinhoBodyItensValores>
                    <BtnRemoverProduto>
                      <FontAwesomeIcon onClick={() => handleRemoverItem(produto.CodProduto)} icon={faTrash} />
                    </BtnRemoverProduto>
                  </CarrinhoBodyItens>
                  : null))}

              <Subtotal>
                <table>
                  <thead>
                    <th></th>
                    <th>Qtd</th>
                    <th>Valor</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Disponível: </td>
                      <th>{totais.qtdDisponivel}</th>
                      <td><b>{formatarValores(totais.vlrDisponivel)}</b></td>
                    </tr>
                    <tr>
                      <td>Encomenda: </td>
                      <th>{totais.qtdEncomenda}</th>
                      <td><b>{formatarValores(totais.vlrEncomenda)}</b></td>
                    </tr>
                    <tr>
                      <td>Total: </td>
                      <th>{totais.qtdTotal}</th>
                      <td><b>{formatarValores(totais.vlrTotal)}</b></td>
                    </tr>
                  </tbody>
                </table>
              </Subtotal>

            </CarrinhoBody>
            {/* <Frete/> */}

            <CarrinhoFooter>
              <Botao onClick={() => handleLimparCarrinho()} containerStyle={{
                backgroundColor: "#fff", borderColor: "var(--botoesFiltrarFinalizar-bg-color)",
                color: "var(--botoesFiltrarFinalizar-bg-color)", borderWidth: 1, borderStyle: "solid",
                width: "20%"
              }}>
                Esvaziar Carrinho
              </Botao>
              <Botao onClick={() => currentlyCart.buscaCarrinho(currentlyCart.cart.CodCliente ? currentlyCart.cart.CodCliente : 0)} containerStyle={{
                backgroundColor: "#fff", borderColor: "var(--botoesFiltrarFinalizar-bg-color)",
                color: "var(--botoesFiltrarFinalizar-bg-color)", borderWidth: 1, borderStyle: "solid",
                width: "20%"
              }}><span className="limparCarrinho">Recarregar Carrinho</span></Botao>

              <Botao onClick={FinalizarPedido} disabled={disabled} id='btnFinalizarCarrinho' containerStyle={{
                backgroundColor: disabled? "#9b9999":"var(--botoesFiltrarFinalizar-bg-color)" , borderColor: "var(--botoesFiltrarFinalizar-bg-color)",
                color: disabled? "#3d3b3b":"#fff", borderWidth: 1, borderStyle: "solid",
                width: "20%"
              }}>
                {disabled? <FaSpinner/> : "Finalizar Pedido"}
              </Botao>

            </CarrinhoFooter>

          </CarrinhoDesktop>
          <CarrinhoResponsivo>

            <div className="titulo-carrinho">
              <p>MEU CARRINHO</p>
            </div>

            {currentlyCart.cart.Produtos.map((produto) => (
              produto.CodProduto >= 0 && produto.isDeleted == false ?
                <div className="card-produto" key={produto.CodProduto}>
                  <div className="imagem-produto">
                    <div className='container-imagem'>
                      {/* <img className="img-carrinho" src={produto.Src} alt={produto.Titulo} /> */}
                      <a href={`/produtos/${produto.CodProduto}/detalhes`}>{<img src={produto.Src} />}</a>
                    </div>
                  </div>
                  <div className="informacoes-carrinho">
                    <div className="produto-carrinho">
                      {/* <p className="produto-titulo">{produto.Titulo}</p> */}
                      <a href={`/produtos/${produto.CodProduto}/detalhes`} className="produto-titulo">{produto.Titulo}</a>
                    </div>
                    <div className="valores-carrinho">
                      <div className="vlrUnitario">
                        <ProdutoQuantidadeCarrinho
                          aumentarQuantidade={() => currentlyCart.adicionaQuantidade(produto.CodProduto)}
                          diminuirQuantidade={() => produto.Qtd != null && produto.Qtd > 1 ? currentlyCart.removeQuantidade(produto.CodProduto) : null}
                          quantidade={produto.Qtd}
                          id={produto.CodProduto}>
                        </ProdutoQuantidadeCarrinho>
                      </div>

                      <div className="vlrUnitario">
                        <p className="valoresPedido"><span>{formatarValores(produto.Vlr)}</span></p>
                      </div>
                      <div className="desconto">
                        <p className="valoresPedido"><span> {`${produto.Desconto}%`}</span></p>
                      </div>
                      <div className="vlrUnitario">
                        <p className="valoresPedido"><span> {produto.Qtdestoque > 0 ? produto.Qtdestoque : 0}</span></p>
                      </div>
                      <div className="vlrUnitario">
                        <p className="valoresPedido"><span> {produto.Qtd != null && produto.Qtd > produto.Qtdestoque ? produto.Qtd - produto.Qtdestoque : 0}</span></p>
                      </div>
                      <div className="vlrTotal">
                        <p className="valoresPedido"><span>{formatarValores(produto.VlrTotal)}</span></p>
                      </div>
                      <div className="vlrTotal">
                        <FontAwesomeIcon onClick={() => handleRemoverItem(produto.CodProduto)} icon={faTrash} />

                      </div>


                    </div>

                  </div>
                </div>
                :
                null
            ))}

            <div className="footer-carrinho">
              <div className="valorTotal">
                <div className="titulo-carrinho">
                  Resumo do Pedido
                </div>
                <div className="descontoeTotalFinal">
                  <div className="Unidade">
                    <p className="desconto-vlrTotal">Descontos: </p>
                    <p className="desconto-vlrTotal">Valor Total: </p>
                  </div>

                  <div className="totais">
                    <p className="valoresPedidoFinal"><span>{descontoCarrinho}</span></p>
                    <p className="valoresPedidoFinal"><span>{valorTotalCarrinho}</span></p>
                  </div>
                </div>
              </div>
            </div>
            <div className="finalizarResponsivo">
              <Botao onClick={FinalizarPedido}
               disabled={disabled} 
                containerStyle={{
                backgroundColor: "var(--botoesFiltrarFinalizar-bg-color)", borderColor: "var(--botoesFiltrarFinalizar-bg-color)",
                color: "#fff", borderWidth: 1, borderStyle: "solid",
                width: "30%"
              }}>
{disabled? <FaSpinner/> : "Finalizar Pedido"}

              </Botao>
              <p className="salvar-carrinho" onClick={() => currentlyCart.buscaCarrinho(currentlyCart.cart.CodCliente ? currentlyCart.cart.CodCliente : 0)}><span className="limparCarrinho">Recarregar Carrinho</span></p>
              <p className="limpar-carrinho" onClick={() => handleLimparCarrinho()}><span className="limparCarrinho">Limpar Carrinho</span></p>
            </div>

          </CarrinhoResponsivo>
        </>
      )
      }
    </Container >);
}

export default Carrinho;
