import React, { Component } from "react";
import _ from "lodash";
import "./Tabela.css";

interface Columns {
  path: string;
  key: number;
  content: any;
}
type MyProps = {
  // using `interface` is also ok
  data: [];
  columns: Columns[];
  chave: string;
};

class TableBody extends Component<MyProps> {
  renderCell = (item: any, column: Columns) => {
    if (column.content) return column.content(item);

    return _.get(item, column.path);
    // return item[column.path];
  };

  createKey = (item: any, column: Columns, chave: string) => {
    return item[chave] + (column.path || column.key);
  };

  render() {
    const { data, columns, chave } = this.props;

    return (
      <tbody className="bodyTable">
        {data.map(item => (
          <tr key={item[chave]} >
            {columns.map(column => (
              <td key={this.createKey(item, column, chave)}>
                {this.renderCell(item, column)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
