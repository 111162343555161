import React from 'react';

import {
  Container, BodyModal, TituloModal, TextoModal,
  BotoesModalContainer, BotoesModal
} from './styles';

interface ModalFinalizacaoProps {
  tituloModal: string
  textoModal: string
  opcaoSelecionada: (opcao: string) => void
}

const ModalFinalizacao: React.FC<ModalFinalizacaoProps> = ({ tituloModal, textoModal, opcaoSelecionada }) => {

  const handleClickSim = () => {
    opcaoSelecionada("S");
  }

  return (
    <Container>
      {/* <TituloModal>
        {tituloModal}
      </TituloModal> */}

      <BodyModal>

        <TextoModal>
          {textoModal}
        </TextoModal>

        <BotoesModalContainer>
          <BotoesModal onClick={() => handleClickSim()}>Ok</BotoesModal>
        </BotoesModalContainer>
      </BodyModal>
    </Container>
  )
};

export default ModalFinalizacao;
