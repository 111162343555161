import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 60px;
  min-height: 400px;
.semProdutos{
    align-items: center;
}
  .cards {
    display: flex;

    flex-wrap: wrap;
    /* RESPONSIVE AJUSTE */
    justify-content: flex-start;
    align-items: center;
  }

.semItens {
  display: block;
}

.comItens{
  display: none;
}
  @media (max-width: 643px) {
      margin: 0;

    .cards {
      display: flex;

      flex-wrap: wrap;
      /* RESPONSIVE AJUSTE */
      justify-content: center;
      align-items: center;
    }
  }

  @media ((min-width: 644px) and (max-width: 922px)) {
    margin: 0;

    .cards {
      display: flex;

      flex-wrap: wrap;
      /* RESPONSIVE AJUSTE */
      justify-content: center;
      align-items: center;
    }
  }
  /* 
  @media (max-width: 1366px) {
    padding: 2rem;
  } */
`;

export const ContainerPagination = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  /* max-width: 2560px; */
`;

// DIV INCLUINDO IMG TITULO ....
export const GridItem = styled.div`
  display: flex;
  width: 300px;
  max-width: 300px;
  margin: 0 0 0 5px;
  padding: 10px;
  flex-direction: column;
  justify-content: flex-start;
  height: -moz-fit-content;
  padding: -1px 20px 2rem 32px;
  margin-bottom: 2rem;
  border: solid 1px rgba(133, 130, 130, 0.5);
  border-radius: 5px;



  @media (min-width: 376px) and (max-width: 436px) {
    width: 300px;
    padding: 5px;
  }

  @media (min-width: 1521px) {
    width: 13%;
    margin: 0 30px;
    padding: 10px;
    margin-bottom: 4rem;
  }
`;

export const GridItemAtivo = styled.div`
  background-color: #009b9b78; /*acrescentar o 78 para dar transparência apenas no fundo sem opacidade*/
  border: 1px solid #009b9b;
`;

export const GridItemImagem = styled.div`
  display: inherit;
  justify-content: center;
  height: 250px;

  .infosCards {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
  }

  .cardDesconto {
    background: var(--cardDesconto-bg-color);
    margin-left: 14rem;
    padding: 0;
    z-index: 1;
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
  }

  .inativo {
    background: none !important;
  }

  .cardMaisVendidos {
    position: absolute;
    background: var(--cardMaisVendidos-bg-color);
    width: 80px;
    height: 30px;
    top: 0px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
    bottom: 1rem;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
  }

  .cardLancamentos {
    position: absolute;
    background: var(--cardLancamentos-bg-color);
    width: 80px;
    height: 30px;
    top: 40px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
    bottom: 2rem;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
  }

  .cardPreVenda {
    position: absolute;
    background: var(--cardPreVenda-bg-color);
    width: 80px;
    height: 30px;
    top: 80px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
    bottom: 2rem;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
  }

  @media (min-width: 376px) and (max-width: 436px) {
    max-height: 190px;
  }

  .sidebar-row {
    width: 100%;
    margin-bottom: 25px;
    height: 100%;
  }

  .sidebar-row a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    color: #111;

    /* @media (max-width: 375px) {
      width: 300px;
    }

    @media (min-width: 376px) and (max-width: 436px) {
      width: 290px;
      max-width: 250px;
    }

    @media (min-width: 1000px) {
      top: 0;
      max-height: 275px;
      width: 100%;
    } */
  }
`;

export const GridItemTags = styled.div`
  display: flex;
  float: right;
  justify-content: flex-end;
  max-width: 200px;
  flex-wrap: wrap;
`;

// export const GridItemTagDesconto = styled.span`
//   background-color: #004aad;
//   color: #ffffff;
// `;

// export const GridItemTagPreVenda = styled.span`
//   background-color: #03989e;
//   color: #ffffff;
// `;

// export const GridItemTagPromocao = styled.span`
//   background-color: #008037;
//   color: #ffffff;
// `;

// export const GridItemTagLancamento = styled.span`
//   background-color: #ff9b00;
//   color: #ffffff;
// `;

// export const GridItemTagMaisVendidos = styled.span`
//   background-color: #ff66c4;
//   color: #ffffff;
// `;

export const GridItemDados = styled.div`
  @media (max-width: 768px) {
    margin: 8rem 0 0;
  }

  @media (min-width: 769px) {
    margin: 0.8rem 0 0;
  }
`;

export const GridItemDadosTituloProduto = styled.p`
  /* margin-bottom: 1rem; */
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  min-height: 50px;

  a {
    color: #111;
  }

  @media (min-width: 1000px) {
    font-size: 14px;
    /* min-height: 2rem; */
    /* max-height: 1rem; */
  }

  @media (max-width: 923px) {
    margin-bottom: 0;
  }
`;

export const GridItemDadosValorProduto = styled.p`
  font-size: 18px;
  padding: 0px 0;
  font-weight: 800;

  @media (min-width: 1000px) {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 1800px) {
    font-size: 1.2rem;
  }

  @media (max-width: 923px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const GridItemDadosAutorProduto = styled.p`
  font-size: 14px;
  padding: 0 0;

  a {
    color: #111;
  }
`;

export const CarrosselItemDadosEstoqueProduto = styled.p`
  font-size: 12px;
  padding: 3px 0;

  .botoesTotais {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .inputAumentar {
    height: 70%;
    width: 25%;
  }

  .botaoAdc {
    width: 80%;
  }

  .contadorLivros {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1000px) {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 1800px) {
    font-size: 1.2rem;
  }
`;

export const GridItemDadosDisponibilidadeProduto = styled.p`
  font-size: 12px;
  /* padding: 10px 0; */
  color: #787474;

  @media (min-width: 1800px) {
    font-size: 1.2rem;
  }
`;

export const BotoesPaginacao = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  @media (max-width: 375px) {
    display: block;
    justify-content: center;
    padding: 10px;
  }

  @media (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
    flex: 1;
    flex-wrap: wrap;
    padding: 20px 20px;
  }
`;

export const BotoesPaginacaoEsquerda = styled.div`
  button {
    @media (max-width: 375px) {
      width: 100%;
    }

    @media (min-width: 1000px) {
      /* display: grid; */
      justify-items: start;
    }
  }
`;

export const BotoesPaginacaoDireita = styled.div`
  button {
    @media (max-width: 375px) {
      width: 100%;
    }

    @media (min-width: 1000px) {
      /* display: grid; */
      justify-items: end;
    }
  }
`;

export const FormPesquisa = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;

  input {
    padding: 10px;
  }
`;

export const Vitrine = styled.div`
  display: flex;

  p {
    margin: 0;
    font-size: 14px;
  }

  .folderTotal {
    min-width: 320px;
    max-width: 320px;
    min-height: 350px;
  }

  .capaLivroETitulo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
  }

  .imgCapa {
    margin-bottom: 1rem;
    height: 250px;
    width: 100%;
    min-height: 250px;
    min-width: 100%;
  }

  .imagemCapa {
    width: 100%;
    min-height: 350px;
  }

  .tituloLivro {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 14px;
    color: #111;
  }

  .infosLivros {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .valorEDesconto {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .valorProduto {
    font-size: 18px;
    font-weight: 800;
  }

  .adcCarrinho {
    background-color: var(--botaoAdicionarCart-bg-color);
    color: rgb(255, 255, 255);
    cursor: pointer;
    border: none;
    height: 50px;
    padding: 1px;
  }

  .adcCarrinhoDisable {
    background-color: darkgray;
    color: rgb(255, 255, 255);
    cursor: not-allowed;
    border: none;
    height: 50px;
    padding: 1px;
  }

  .aumentarEcomprar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
    height: 50px;
    /* box-shadow: 0 12px 15px rgb(0 0 0 / 15%); */
  }

  .inputNumeros {
    width: 20%;
  }

  .contadorLivros {
    width: 100%;
    /* height: 100%; */
    font-size: 14px;
    border: none;
    padding: 1px;
    text-align: center;
    background: #eee;
    line-height: 50px;
  }

  .buttonAdc {
    width: 75%;
  }

  .adcCarrinho {
    width: 100%;
    height: 100%;
  }

  .adcCarrinhoDisable {
    width: 100%;
    height: 100%;
  }
`;
