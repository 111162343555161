import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Footer from './componentes/Footer';
import Header from './componentes/Header/';
import { CarrinhoDTO, CarrinhoProdutoDTO, ItemCarrinhoFiltroDTO } from './DTOs/produtoDTO';
import { UsuarioLogadoDTO } from './DTOs/UsuarioLogadoDTO';
import AppProvider from './hooks';
import cartContext, { cartInital } from './Context/CartContext';
import userContext, { initalValue } from './Context/UserContext';
import Rotas from './routes';
import GlobalStyle from './styles/global';
import './style.css'
import pinContext, { pinInitial } from './Context/PinContext';
import { Carrinho } from './componentes/Carrinho';
import SubFooter from './componentes/Footer/subFooter';
import { arredonda2, formatarValores } from './helpers/valores';
import { useToast } from './hooks/toast';
import api from './service/api';
import { LayoutDTO } from './DTOs/LayoutDTO';
import imageContext, { imageInitial } from './Context/ImagesContext';
import { ImagesDTO } from './DTOs/ImagesDTO';

const App: React.FC = () => {
  const { addToast } = useToast();

  const [usuarioLogado] = useState<UsuarioLogadoDTO>(() => {
    // Busca no localStorage o carrinho
    const usuarioLocal = localStorage.getItem('@VersaB2B:login');

    // Se existir alguma coisa no localStorage
    if (usuarioLocal) {

      // Faz um cast pra transformar a string JSON em um objeto
      const usuarioObj = JSON.parse(usuarioLocal);
      // Retorna o objeto para o state
      return usuarioObj;
    }

    // Se não tiver nada, simplesmente retorna um array vazio
    return initalValue;
  });


  const [carrinho] = useState<CarrinhoDTO>(() => {
    // Busca no localStorage o carrinho
    const carrinhoLocal = localStorage.getItem('@VersaB2B:carrinho');

    // Se existir alguma coisa no localStorage
    if (carrinhoLocal) {

      // Faz um cast pra transformar a string JSON em um objeto
      const carrinhoObj = JSON.parse(carrinhoLocal);
      // Limpa o array de quantidades de produtos do localStorage do usuário
      localStorage.removeItem('@VersaB2B:ProdutosQuantidades');

      // Retorna o objeto para o state
      return carrinhoObj;
    }

    // Se não tiver nada, simplesmente retorna um array vazio
    return cartInital;
  });


  const [images] = useState<ImagesDTO>(() => {
    // Busca no localStorage o carrinho
    const imagesLocal = localStorage.getItem('@VersaB2B:images');

    // Se existir alguma coisa no localStorage
    if (imagesLocal) {

      // Faz um cast pra transformar a string JSON em um objeto
      const imagesObj = JSON.parse(imagesLocal);

      // Retorna o objeto para o state
      return imagesObj;
    }

    // Se não tiver nada, simplesmente retorna um array vazio
    return imageInitial;
  });

  useEffect(() => {
    api.get(`api/configuracao/getconfiguracoes`).then((response) => {
      const layout: LayoutDTO = response.data;

      if (layout.Cores != null) {
        layout.Cores.HeaderBackground ? cssVar("--headerBackground-bg-color", layout.Cores?.HeaderBackground) : cssVar("--headerBackground-bg-color", "#fff");
        layout.Cores.HeaderTextos ? cssVar("--headerTextos-bg-color", layout.Cores?.HeaderTextos) : cssVar("--headerTextos-bg-color", "#fff");
        layout.Cores.HeaderIcons ? cssVar("--headerIcons-bg-color", layout.Cores?.HeaderIcons) : cssVar("--headerIcons-bg-color", "#fff");
        layout.Cores.HeaderButton ? cssVar("--headerButton-bg-color", layout.Cores?.HeaderButton) : cssVar("--headerButton-bg-color", "#fff");
        layout.Cores.MenuBackground ? cssVar("--menuBackground-bg-color", layout.Cores?.MenuBackground) : cssVar("--menuBackground-bg-color", "#fff");
        layout.Cores.SecondaryMenuBackground ? cssVar("--secondaryMenuBackground-bg-color", layout.Cores?.SecondaryMenuBackground) : cssVar("--secondaryMenuBackground-bg-color", "#fff");
        layout.Cores.MenuTextos ? cssVar("--menuTextos-bg-color", layout.Cores?.MenuTextos) : cssVar("--menuTextos-bg-color", "#fff");
        layout.Cores.MenuHover ? cssVar("--menuHover-bg-color", layout.Cores?.MenuHover) : cssVar("--menuHover-bg-color", "#fff");
        layout.Cores.FooterBackground ? cssVar("--footerBackground-bg-color", layout.Cores?.FooterBackground) : cssVar("--footerBackground-bg-color", "#fff");
        layout.Cores.FooterTextos ? cssVar("--footerTextos-bg-color", layout.Cores?.FooterTextos) : cssVar("--footerTextos-bg-color", "#fff");
        layout.Cores.FooterLinks ? cssVar("--footerLinks-bg-color", layout.Cores?.FooterLinks) : cssVar("--footerLinks-bg-color", "#fff");
        layout.Cores.FooterIcons ? cssVar("--footerIcons-bg-color", layout.Cores?.FooterIcons) : cssVar("--footerIcons-bg-color", "#fff");
        layout.Cores.FooterButton ? cssVar("--footerButton-bg-color", layout.Cores?.FooterButton) : cssVar("--footerButton-bg-color", "#fff");
        layout.Cores.CardButton ? cssVar("--cardButton-bg-color", layout.Cores?.CardButton) : cssVar("--cardButton-bg-color", "#fff");
        layout.Cores.CardDesconto ? cssVar("--cardDesconto-bg-color", layout.Cores?.CardDesconto) : cssVar("--cardDesconto-bg-color", "#fff");
        layout.Cores.CardLancamentos ? cssVar("--cardLancamentos-bg-color", layout.Cores?.CardLancamentos) : cssVar("--cardLancamentos-bg-color", "#fff");
        layout.Cores.CardMaisVendidos ? cssVar("--cardMaisVendidos-bg-color", layout.Cores?.CardMaisVendidos) : cssVar("--cardMaisVendidos-bg-color", "#fff");
        layout.Cores.CardPreVenda ? cssVar("--cardPreVenda-bg-color", layout.Cores?.CardPreVenda) : cssVar("--cardPreVenda-bg-color", "#fff");
      };

    });
  }, []);

  const [contextUser, setContextUser] = useState(usuarioLogado);
  const [contextCart, setContextCarrinho] = useState(carrinho);
  const [contextPin, setContextPin] = useState(pinInitial);
  const [contextImage, setContextImage] = useState(images);

  const handleAlternaCarrinho = () => {
    setContextPin({ pinToggle: contextPin.carrinhoToggle === true ? !contextPin.carrinhoToggle : contextPin.pinToggle, carrinhoToggle: !contextPin.carrinhoToggle });
  };

  const handleAlternaPin = () => {
    setContextPin({ pinToggle: !contextPin.pinToggle, carrinhoToggle: contextPin.carrinhoToggle });
  };

  const handleDesativaPin = () => {
    setContextPin({ pinToggle: false, carrinhoToggle: contextPin.carrinhoToggle })
  };

  const handleDesativaCarrinho = () => {
    setContextPin({ pinToggle: false, carrinhoToggle: false })
  };

  const handleAlteraLogo = (path: string) => {
    setContextImage({ Logo: path });
  };

  const handleAlteraUsuario = (user: UsuarioLogadoDTO) => {
    setContextUser(user);
  };

  const handleLogin = (user: UsuarioLogadoDTO) => {
    handleAlteraUsuario(user);

    if (user.Codcliente > 0) {
      handleLimpaCarrinho();
      handleBuscaCarrinho(user.Codcliente);
    }
  };

  const handleLimpaCarrinho = () => {
    handleAlteraCarrinho(cartInital);
  };

  const handleLogout = () => {
    setContextUser(initalValue);
    handleLimpaCarrinho();
  };

  const handleEsvaziaCarrinho = () => {

    var filtro: ItemCarrinhoFiltroDTO = {
      Codacerto: contextCart.Codcarrinho,
      Codproduto: 0,
      Desconto: 0,
      Quantidade: 0,
      Vlrunitario: 0,
    }

    api.post(`api/business/excluircarrinho`, filtro).then(() => {
      handleLimpaCarrinho();
    });

  }

  const handleAlteraCarrinho = (cart: CarrinhoDTO) => {

    setContextCarrinho(cart);
    localStorage.setItem('@VersaB2B:carrinho', JSON.stringify(cart));

  };

  function handleBuscaCarrinho(codcliente: number) {
    // localStorage.removeItem('@VersaB2B:carrinho');
    // handleLimpaCarrinho();

    if (codcliente > 0) {
      api.get(`api/business/carrinho/${codcliente}`).then((response) => {

        // Desestrutura o objeto da API com somente os campos necessários na interface
        // e formata o valor para exibição
        const carrinho: CarrinhoDTO = response.data;

        // Seta os produtos no state para usar na página
        let vlrTotal = 0;

        for (let i = 0; i < carrinho.Produtos.length; i++) {
          // Lê o produto atual
          let produto = carrinho.Produtos[i];

          if (produto.Desconto == null) {
            produto.Desconto = 0;
          }
          // Calcula o total, multiplicando o valor do item pela quantidade dele no carrinho
          vlrTotal += produto.VlrTotal - (produto.VlrTotal * (produto.Desconto / 100));
          carrinho.Produtos[i].Src = process.env.REACT_APP_IMAGEMPATH + carrinho.Produtos[i].Src;
          if (produto.Qtd) {
            carrinho.Produtos[i].VlrTotal = (produto.Vlr * produto.Qtd - (produto.Vlr * produto.Qtd * (produto.Desconto / 100)));

          }
        }

        carrinho.VlrTotal = vlrTotal;
        setContextCarrinho(carrinho);
        localStorage.setItem('@VersaB2B:carrinho', JSON.stringify(contextCart));

      });
    }

  };


  const SalvaItemCarrinho = (cart: ItemCarrinhoFiltroDTO) => {
    api.post(`api/business/gravaitemcarrinho`, cart).then(() => {
      handleBuscaCarrinho(contextUser.Codcliente);
    });
  };


  const AtualizaItemCarrinho = (cart: ItemCarrinhoFiltroDTO) => {

    api.post(`api/business/atualizaitemcarrinho`, cart).then((response) => {
      if (contextUser.Codcliente != null) {
        handleBuscaCarrinho(contextUser.Codcliente);
      }
    });
  };


  function cssVar(name: string, value: string) {
    if (name[0] !== '-') name = '--' + name //allow passing with or without --
    if (value) document.documentElement.style.setProperty(name, value)
    return getComputedStyle(document.documentElement).getPropertyValue(name);
  }

  const handleAdicionaQuantidade = (codproduto: number) => {

    var filtro = {
      Codproduto: codproduto,
      Codacerto: contextCart.Codcarrinho,
    }

    let quantidadeApi = 0;

    api.post(`api/business/carrinho/somarquantidade`, filtro).then((response) => {
      const data = response.data;
      if (data != null) {
        quantidadeApi = data;

        // Procura o indice do produto no array que está no state
        const produtoIndex = contextCart.Produtos.findIndex(produto => produto.CodProduto === codproduto);

        // Se achou produto
        if (produtoIndex > -1) {
          // Armazena o objeto na variável
          const produto = contextCart.Produtos[produtoIndex];

          produto.Qtd = quantidadeApi;
          produto.VlrTotal = arredonda2((produto.Vlr * produto.Qtd) * ((100 - produto.Desconto) / 100));
          produto.VlrTotalFormatado = formatarValores(produto.VlrTotal);


          if (quantidadeApi > 0) {
            handleAlteraCarrinho({
              ...contextCart,
              Produtos: [
                ...contextCart.Produtos.slice(0, produtoIndex),
                produto,
                ...contextCart.Produtos.slice(produtoIndex + 1)
              ]
            });
          }

          if(produto.Qtd === 0){
            handleBuscaCarrinho(contextUser.Codcliente);
          }
        }
      

      }
    });
  };


  const handleRemoveQuantidade = (codproduto: number) => {

    var filtro = {
      Codproduto: codproduto,
      Codacerto: contextCart.Codcarrinho,
    }

    let quantidadeApi = 0;

    api.post(`api/business/carrinho/diminuirquantidade`, filtro).then((response) => {
      const data = response.data;

      if (data != null) {

        quantidadeApi = data;

        // Procura o indice do produto no array que está no state
        const produtoIndex = contextCart.Produtos.findIndex(produto => produto.CodProduto === codproduto);

        // Se achou produto
        if (produtoIndex > -1) {
          // Armazena o objeto na variável
          const produto = contextCart.Produtos[produtoIndex];

          produto.Qtd = quantidadeApi;
          produto.VlrTotal = arredonda2((produto.Vlr * produto.Qtd) * ((100 - produto.Desconto) / 100));
          produto.VlrTotalFormatado = formatarValores(produto.VlrTotal);

          handleAlteraCarrinho({
            ...contextCart,
            Produtos: [
              ...contextCart.Produtos.slice(0, produtoIndex),
              produto,
              ...contextCart.Produtos.slice(produtoIndex + 1)
            ]
          });

          if(produto.Qtd === 0){
            handleBuscaCarrinho(contextUser.Codcliente);
          }
        }
      }
    });
  };

  const handleAlteraQuantidade = (id: number, qtd: number) => {

    // Procura o indice do produto no array que está no state
    const produtoIndex = contextCart.Produtos.findIndex(produto => produto.CodProduto === id);

    // Se achou produto
    if (produtoIndex > -1) {

      // Armazena o objeto na variável
      const produto = contextCart.Produtos[produtoIndex];

      // Aumenta a quantidade
      if (qtd > 1) {

        produto.Qtd = qtd;
      }
      else {
        produto.Qtd = 1;
      }

      // produto.isEdited = true;

      // Recalcula o valor do item baseado na quantidade
      produto.VlrTotal = arredonda2((produto.Vlr * produto.Qtd) * ((100 - produto.Desconto) / 100));
      produto.VlrTotalFormatado = formatarValores(produto.VlrTotal);

      var filtro: ItemCarrinhoFiltroDTO = {
        Codacerto: contextCart.Codcarrinho,
        Codproduto: produto.CodProduto,
        Desconto: produto.Desconto,
        Quantidade: produto.Qtd,
        Vlrunitario: produto.Vlr,
      }

      AtualizaItemCarrinho(filtro);

      // Seta pra atualizar o state, somente o item que foi alterado

    }
  }

  const handleAdicionaProdutoCarrinho = (produto: CarrinhoProdutoDTO) => {
    try {

      if (contextUser.Codcliente != null) {
        handleBuscaCarrinho(contextUser.Codcliente);
      }

      var carrinho: CarrinhoDTO = contextCart;

      // Procura o indice do produto no state, baseado no ID
      const produtoIndex = contextCart.Produtos.findIndex(produtoCarrinho => produtoCarrinho.CodProduto === produto.CodProduto);

      // Se achou produto
      if (produtoIndex > -1) {

        // // Armazena o objeto na variável
        // const produtoCarrinho = contextCart.Produtos[produtoIndex];

        // produto.Qtd = produtoCarrinho.Qtd;
        // // produto.isEdited = true;

        // if (produto.Qtd) {
        //   produto.VlrTotal = arredonda2((produto.Vlr * produto.Qtd) * ((100 - produto.Desconto) / 100));
        // }
        // produto.VlrTotalFormatado = formatarValores(produto.VlrTotal);

        // // Seta pra atualizar o state, somente o item que foi alterado
        // carrinho = {
        //   ...contextCart,
        //   Produtos: [...contextCart.Produtos.slice(0, produtoIndex),
        //     produto,
        //   ...contextCart.Produtos.slice(produtoIndex + 1)]
        // }

        // var filtro: ItemCarrinhoFiltroDTO = {
        //   Codacerto: contextCart.Codcarrinho,
        //   Codproduto: produto.CodProduto,
        //   Desconto: produto.Desconto,
        //   Quantidade: produto.Qtd,
        //   Vlrunitario: produto.Vlr,
        // }

        // AtualizaItemCarrinho(filtro);
      }
      else {


        if (produto.Qtd === undefined || produto.Qtd === 0 || produto.Qtd === null) {

          // Se tiver vazio, coloca 1
          produto.Qtd = 1;

        }
        console.log(produto.Qtd )

        produto.isAdded = true;
        produto.VlrTotal = arredonda2((produto.Vlr * produto.Qtd) * ((100 - produto.Desconto) / 100));
        produto.VlrTotalFormatado = formatarValores(produto.VlrTotal);

        carrinho = {
          ...contextCart,
          Produtos: [...contextCart.Produtos, produto]
        }

        var filtro: ItemCarrinhoFiltroDTO = {
          Codacerto: contextCart.Codcarrinho,
          Codproduto: produto.CodProduto,
          Desconto: produto.Desconto,
          Quantidade: produto.Qtd,
          Vlrunitario: produto.Vlr,
        }

        if(filtro.Codacerto != null &&  filtro.Codacerto > 0){
          SalvaItemCarrinho(filtro);
        }
      }


      if (contextCart.Codcarrinho === 0) {

        let filtro: CarrinhoDTO = {
          ...carrinho,
          CodAlmoxarifado: contextUser.Codalmoxarifado,
          CodCliente: contextUser.Codcliente,
          CodEmpresa: contextUser.Codempresa,
          CodempresaCli: contextUser.Codempresacli,
          CodUsuario: contextUser.Codusuario

        };

        api.post(`api/business/criacarrinho`, filtro).then((response) => {

          const data = response.data;
          if (data != null) {
            carrinho = ({
              ...filtro,
              Codcarrinho: data,
            });

            handleAlteraCarrinho(carrinho);
          }
        });


      }
      //else {



      //   const produtoIndex = contextCart.Produtos.findIndex(produtoCarrinho => produtoCarrinho.CodProduto === produto.CodProduto);

      //   if (produtoIndex) {
      //     AtualizaItemCarrinho(filtro);
      //   } else {
      //     SalvaItemCarrinho(filtro);
      //   }

      // }


    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao adicionar ao carrinho',
        description: "" + error
      });
    }
  };

  const handleRemoveItem = (codproduto: number) => {

    // let produtoscarrinhoCopia = [...contextCart.Produtos];

    // for (let i = 0; i < produtoscarrinhoCopia.length; i++) {
    //   if (produtoscarrinhoCopia[i].CodProduto === codproduto) {
    //     produtoscarrinhoCopia[i].isDeleted = true;
    //   }
    // }    

    const produtoIndex = contextCart.Produtos.findIndex(produtoCarrinho => produtoCarrinho.CodProduto === codproduto);

    // Se achou produto
    if (produtoIndex > -1) {

      const produto = contextCart.Produtos[produtoIndex];

      produto.Qtd = 0;
      produto.VlrTotal = arredonda2((produto.Vlr * produto.Qtd) * ((100 - produto.Desconto) / 100));
      produto.VlrTotalFormatado = formatarValores(produto.VlrTotal);

      handleAlteraCarrinho({
        ...contextCart,
        Produtos: [
          ...contextCart.Produtos.slice(0, produtoIndex),
          produto,
          ...contextCart.Produtos.slice(produtoIndex + 1)
        ]
      });

      var filtro: ItemCarrinhoFiltroDTO = {
        Codacerto: contextCart.Codcarrinho,
        Codproduto: codproduto,
        Desconto: 0,
        Quantidade: 0,
        Vlrunitario: 0,
      }


      api.post(`api/business/removeitemcarrinho`, filtro).then(() => {

        if (contextUser.Codcliente != null) {
          handleBuscaCarrinho(contextUser.Codcliente);
        }
      });

      // handleLimpaCarrinho();
    }

  }

  useEffect(() => {
    api.get(`api/configuracao/getconfiguracoes`).then((response) => {
      const layout: LayoutDTO = response.data;

      if (layout.Cores != null) {
        layout.Cores.HeaderBackground ? cssVar("--headerBackground-bg-color", layout.Cores?.HeaderBackground) : cssVar("--headerBackground-bg-color", "#fff");
        layout.Cores.HeaderTextos ? cssVar("--headerTextos-bg-color", layout.Cores?.HeaderTextos) : cssVar("--headerTextos-bg-color", "#fff");
        layout.Cores.HeaderIcons ? cssVar("--headerIcons-bg-color", layout.Cores?.HeaderIcons) : cssVar("--headerIcons-bg-color", "#fff");
        layout.Cores.HeaderButton ? cssVar("--headerButton-bg-color", layout.Cores?.HeaderButton) : cssVar("--headerButton-bg-color", "#fff");
        layout.Cores.MenuBackground ? cssVar("--menuBackground-bg-color", layout.Cores?.MenuBackground) : cssVar("--menuBackground-bg-color", "#fff");
        layout.Cores.SecondaryMenuBackground ? cssVar("--secondaryMenuBackground-bg-color", layout.Cores?.SecondaryMenuBackground) : cssVar("--secondaryMenuBackground-bg-color", "#fff");
        layout.Cores.MenuTextos ? cssVar("--menuTextos-bg-color", layout.Cores?.MenuTextos) : cssVar("--menuTextos-bg-color", "#fff");
        layout.Cores.MenuHover ? cssVar("--menuHover-bg-color", layout.Cores?.MenuHover) : cssVar("--menuHover-bg-color", "#fff");
        layout.Cores.FooterBackground ? cssVar("--footerBackground-bg-color", layout.Cores?.FooterBackground) : cssVar("--footerBackground-bg-color", "#fff");
        layout.Cores.FooterTextos ? cssVar("--footerTextos-bg-color", layout.Cores?.FooterTextos) : cssVar("--footerTextos-bg-color", "#fff");
        layout.Cores.FooterLinks ? cssVar("--footerLinks-bg-color", layout.Cores?.FooterLinks) : cssVar("--footerLinks-bg-color", "#fff");
        layout.Cores.FooterIcons ? cssVar("--footerIcons-bg-color", layout.Cores?.FooterIcons) : cssVar("--footerIcons-bg-color", "#fff");
        layout.Cores.FooterButton ? cssVar("--footerButton-bg-color", layout.Cores?.FooterButton) : cssVar("--footerButton-bg-color", "#fff");
        layout.Cores.CardButton ? cssVar("--cardButton-bg-color", layout.Cores?.CardButton) : cssVar("--cardButton-bg-color", "#fff");
        layout.Cores.CardDesconto ? cssVar("--cardDesconto-bg-color", layout.Cores?.CardDesconto) : cssVar("--cardDesconto-bg-color", "#fff");
        layout.Cores.CardLancamentos ? cssVar("--cardLancamentos-bg-color", layout.Cores?.CardLancamentos) : cssVar("--cardLancamentos-bg-color", "#fff");
        layout.Cores.CardMaisVendidos ? cssVar("--cardMaisVendidos-bg-color", layout.Cores?.CardMaisVendidos) : cssVar("--cardMaisVendidos-bg-color", "#fff");
        layout.Cores.CardPreVenda ? cssVar("--cardPreVenda-bg-color", layout.Cores?.CardPreVenda) : cssVar("--cardPreVenda-bg-color", "#fff");
      };

    });
  }, []);

  return (
    <BrowserRouter>
      <AppProvider>
        <userContext.Provider value={{ user: contextUser, onLogin: handleLogin, onLogout: handleLogout, alteraUsuario: handleAlteraUsuario }}>
          <cartContext.Provider value={{ cart: contextCart, alteraCarrinho: handleAlteraCarrinho, limpaCarrinho: handleLimpaCarrinho, adicionaQuantidade: handleAdicionaQuantidade, removeQuantidade: handleRemoveQuantidade, alteraQuantidade: handleAlteraQuantidade, adicionaCarrinho: handleAdicionaProdutoCarrinho, esvaziaCarrinho: handleEsvaziaCarrinho, buscaCarrinho: handleBuscaCarrinho, removeItem: handleRemoveItem, }}>
            <pinContext.Provider value={{ pin: contextPin, ativarCarrinhoPin: handleAlternaPin, desativarCarrinhoPin: handleDesativaPin, mostrarCarrinho: handleAlternaCarrinho, desativaCarrinho: handleDesativaCarrinho }}>
              <imageContext.Provider value={{ images: contextImage, alterarLogo: handleAlteraLogo }}>
                <Header />
                <div className='RotasEcarrinho'>
                  <div className='rotas'>
                    <Rotas />
                  </div>
                  <div className={contextPin.pinToggle ? 'pin' : 'pinOff'} />
                  <Carrinho />
                </div>
              </imageContext.Provider>
            </pinContext.Provider>
          </cartContext.Provider>
        </userContext.Provider>
      </AppProvider>
      <GlobalStyle />
      <Footer />
      <SubFooter />
    </BrowserRouter>
  );
}


export default App;


