export function formatarValores(valor: number) {

  // let valorLimpo = 0.0;
  // if(valor == 0){
  //   valorLimpo = parseFloat(valor.toString().replace(" ", "0.0"));
  // }
  if(valor != null){
    const valorLimpo = parseFloat(valor.toString().replace(",", "."));
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valorLimpo);
  }

  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);
}

export function arredonda2(valor: number) {
  const valorLimpo = valor.toString().replace(",", ".");

  const valorArredondado = parseFloat(valorLimpo).toFixed(2);

  return parseFloat(valorArredondado);
}

export function formatarData(dateTimeString: string) {
    const [date, time] = dateTimeString.split(' ');  
    const [DD, MM, YYYY] = date.split('/');  
    const [HH, mm] = time.split(':');  
    return `${MM}/${DD}/${YYYY}`;
  }

  export function formatarDataComHorario(dateTimeString: string) {
    const [date, time] = dateTimeString.split(' ');  
    const [DD, MM, YYYY] = date.split('/');  
    const [HH, mm] = time.split(':');  
    return `${MM}/${DD}/${YYYY} ${HH}:${mm}`;
  }
  
