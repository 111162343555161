import React, { useContext, useMemo, useState } from 'react';
import Botao from '../Botao';
import { FormPesquisa, ContainerPagination, Container } from './styles';
import { CarrinhoProdutoDTO } from '../../DTOs/produtoDTO';
import { ProdutoFiltro } from '../../DTOs/ProdutoFiltro';
import Pagination from '../Paginacao/Pagination';
import userContext from '../../Context/UserContext';
import CardProduto from '../CardProduto';
import Loading from '../Loading/Index';
import useProdutos from '../../hooks/useProdutos';
import { useLocation } from "react-router-dom";

const Grid: React.FC<ProdutoFiltro> = (filtro: ProdutoFiltro) => {
  const search = useLocation().search;
  const page = new URLSearchParams(search).get('page') || "1";
  const [itensPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState<number>(+page - 1);
  const [filtroPesquisa, setFiltroPesquisa] = useState("");
  const currentlyUser = useContext(userContext);

  filtro = useMemo(() => {
    const filtroMemo = {
      ...filtro,
      Codalmoxarifado: currentlyUser.user.Codalmoxarifado,
      Codempresa: currentlyUser.user.Codempresa,
      Codoperacao: currentlyUser.user.Codoperacao,
    }

    return filtroMemo;

  }, [filtro.Codcliente, filtro.Codempresacli]);

  const { produtos, setProdutos, removeLoading } = useProdutos(filtro);
  const pages = Math.ceil(produtos.length / itensPerPage);
  const startIndex = currentPage * itensPerPage;
  const endIndex = startIndex + itensPerPage;

  var currentItens = produtos.slice(startIndex, endIndex);
  if (filtroPesquisa !== "") {
    currentItens = func_str(produtos, filtroPesquisa);
  }

  function replaceSpecialChars(str: string) {
    str = str.replace(/[ÀÁÂÃÄÅ]/, "A");
    str = str.replace(/[àáâãäå]/, "a");
    str = str.replace(/[ÈÉÊẼË]/, "E");
    str = str.replace(/[èéêẽë]/, "e");
    str = str.replace(/[ÌÍÎĨÏ]/, "I");
    str = str.replace(/[ìíîĩï]/, "i");
    str = str.replace(/[ÒÓÔÕÖ]/, "O");
    str = str.replace(/[òóôõö]/, "o");
    str = str.replace(/[ÙÚÛŨÜ]/, "U");
    str = str.replace(/[ùúûũü]/, "u");

    str = str.replace(/[ýỹÿ]/, "y");
    str = str.replace(/[ÝỸŸ]/, "Y");

    str = str.replace(/[ñ]/, "n");
    str = str.replace(/[Ñ]/, "N");

    str = str.replace(/[Ç]/, "C");
    str = str.replace(/[ç]/, "c");

    return str.replace(/[^a-z0-9]/gi, '');
  }

  function func_str(arr1: CarrinhoProdutoDTO[], b: string) {
    const filtro = replaceSpecialChars(b);
    return arr1.filter(function (ele_val) {
      const filtrarTitulo = replaceSpecialChars(ele_val.Titulo);
      const filtraEditora = replaceSpecialChars(ele_val.NomEditora);
      const filtrarAutor = replaceSpecialChars(ele_val.NomAutor);

      if (filtrarTitulo.toLowerCase().indexOf(filtro.toLowerCase()) !== -1) {
        return filtrarTitulo.toLowerCase().indexOf(filtro.toLowerCase()) !== -1;
      } else if (filtrarAutor.toLowerCase().indexOf(filtro.toLowerCase()) !== -1) {
        return filtrarAutor.toLowerCase().indexOf(filtro.toLowerCase()) !== -1;
      } else if (filtraEditora.toLowerCase().indexOf(filtro.toLowerCase()) !== -1) {
        return filtraEditora.toLowerCase().indexOf(filtro.toLowerCase()) !== -1;
      } else {
        return filtrarAutor.toLowerCase().indexOf(filtro.toLowerCase()) !== -1;
      }
    })
  }

  function Pesquisa(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    let txtfiltro = formData.get("txtfiltro") as string;
    if (txtfiltro !== "") {
      setFiltroPesquisa(txtfiltro);
    }
  }

  return (
    <>
    
      <FormPesquisa onSubmit={Pesquisa}>
        <input type="text" id="iptBusca" placeholder="Pesquisar produtos" title="Campo de busca." name="txtfiltro" value={filtroPesquisa} onChange={event => setFiltroPesquisa(event.target.value)} />
        <Botao containerStyle={{
          backgroundColor: "#423e3e", borderColor: "#fff",
          color: "#fff", borderWidth: 1, borderStyle: "solid",
          padding: 10, margin: 0, height: "inherit", fontWeight: "normal",
          fontSize: 16
        }}>Filtrar</Botao>
      </FormPesquisa>

      <Container>

        <div className="cards">
          {removeLoading === false ?
            <Loading /> : produtos.length > 0 ?
              <CardProduto Produtos={currentItens} SetProdutos={setProdutos} /> :
              <p className='semProdutos'>Nenhum produto cadastrado.</p>}
        </div>
      </Container>

      {pages <= 1 ? null :
        <ContainerPagination>
          <Pagination pages={pages} setCurrentPaged={setCurrentPage} currentPage={currentPage} />
        </ContainerPagination>}
    </>
  );
}

export default Grid;
