import * as React from 'react';
import { useForm } from "react-hook-form";
import { Container, PagLinksNovo, TituloSecao } from '../styles';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../service/api';
import { useEffect, useState } from 'react';
import { useToast } from '../../../hooks/toast';
import { MenuLinksDTO } from '../../../DTOs/MenuDTO';
import { GrupoDTO } from '../../../DTOs/GrupoDTO';

const Cdlink: React.FC = () => {
    const { addToast } = useToast();
    const { id }: any = useParams();
    const [menuLinks, setMenuLinks] = useState<MenuLinksDTO>();
    const [grupos, setGrupos] = useState<GrupoDTO[]>();
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    let history = useHistory();
    const isAddMode = !id;
    const Indcategoria = watch('Indcategoria', '0');
    let disabledLinkPai = (Indcategoria === '2') || (Indcategoria ==='3');
    let disabledGrupo = menuLinks?.Codcolecaopai != null? !(menuLinks.Indcategoria === 1 || menuLinks.Indcategoria === 3) : !(Indcategoria === '1' || Indcategoria === '3') ;
    let disabledUrl = ( menuLinks?.Indcategoria !=null ? menuLinks.Indcategoria !== 0 : Indcategoria !== '0');
    let disabledEditora = !(Indcategoria === '0');

    function CarregaGrupos() {
        api.get(`api/produto/grupo`).then((response) => {
            const dados = response.data.Dados;

            if (dados.length > 0) {
                setGrupos(dados);
            }
        });
    }

    useEffect(() => {
        if (disabledLinkPai) {
            setValue("Codlinkpai", "");
        }
        if (disabledGrupo) {
            setValue("Codcolecaopai", "");
        }

        if (disabledUrl) {
            setValue("Url", "");
        }
        if (disabledEditora) {
            const Codpagina = watch('Codpagina', '0');

            if (Codpagina == 5) {
                setValue("Codpagina", "0");
            }
        }
    }, [Indcategoria])

    function SalvarMenuLink(menuLinks: MenuLinksDTO) {

        if (id != null) {
            api.put(`api/business/menu/${id}`, menuLinks).then((response) => {
                addToast({
                    type: 'success',
                    title: 'Link menu atualizado com sucesso'
                });
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao atualizar link menu'
                });
            });

        } else {
            api.post(`api/business/links`, menuLinks).then((response) => {
                addToast({
                    type: 'success',
                    title: 'Link menu criada com sucesso'
                });
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao criar link menu'
                });
            });
        }
         history.push("/configuracao/lstlinks");
    }

    const OnSubmit = (data: any) => {
        const menuLinks: MenuLinksDTO = data;
        SalvarMenuLink(menuLinks);
    };

    useEffect(() => {
        CarregaGrupos();
    }, []);

    useEffect(() => {
        if (!isAddMode) {
            api.get(`api/business/menu/${id}`).then((response) => {
                setValue("Codlink", response.data["Codlink"])
                setValue("Nomlink", response.data["Nomlink"])
                setValue("Dsclink", response.data["Dsclink"])
                setValue("Url", response.data["Url"])
                setValue("Codpagina", response.data["Codpagina"])
                setValue("Codposicao", response.data["Codposicao"])
                setValue("Indcategoria", response.data["Indcategoria"])
                setValue("Codlinkpai", response.data["Codlinkpai"])
                setValue("Codcolecaopai", response.data["Codcolecaopai"])
                setMenuLinks(response.data);
            });
        }
    }, [grupos]);

    function onChanged(ev: any) {
        const { name, value } = ev.target;
        setMenuLinks({ ...menuLinks, [name]: value })
    }

    return (
        <Container>
            <TituloSecao>
                Cadastro de Menus
            </TituloSecao>

            <PagLinksNovo>
                <form onSubmit={handleSubmit(OnSubmit)}>
                    <div className="codCampo" hidden={menuLinks?.Codlink ? false : true}>
                        <p >Código</p>
                        <input type="text" className='inputTextNome' placeholder="Codigo" {...register("Codlink", { required: false, maxLength: 80, value: menuLinks?.Codlink, disabled: true })} />
                    </div>

                    <div className="nomePagina">
                        <div className="nomCampo">
                            <p>Nome:</p>
                            <input type="text" className='inputTextNome' placeholder="Nome" {...register("Nomlink", { required: false, maxLength: 100, value: menuLinks?.Nomlink, onChange: onChanged })} />
                        </div>
                        <div className="posicaoPagina">
                            <div className="nomCampo">
                                <p>Descrição:</p>
                                <input type="text" className='inputTextNome' placeholder="Descrição" {...register("Dsclink", { required: true, maxLength: 250, value: menuLinks?.Dsclink, onChange: onChanged })} />
                            </div>
                        </div>
                        <div className="posicaoPagina">
                            <p>Tipo de Link:</p>
                            <select className='inputColecao' {...register("Indcategoria")}>
                                <option value="0" selected>Normal</option>
                                <option value="1">Grupo</option>
                                <option value="2">Vitrine</option>
                                <option value="3">Categoria por grupo</option>
                            </select>
                        </div>
                    </div>
                    <div className="nomePagina">
                        <div className="nomCampo">
                            <p>Página:</p>
                            <select className='inputTextNome' {...register("Codpagina")}>
                                <option value="0" selected>Url</option>
                                <option value="1">Promoções</option>
                                <option value="2">Mais Vendidos</option>
                                <option value="3">Lançamentos</option>
                                <option value="4">Pré-Venda</option>
                                <option value="5" disabled={disabledEditora}>Editora</option>
                            </select>
                        </div>
                        <div className="posicaoPagina">
                            <p>Grupo</p>
                            <select className='inputColecao' {...register("Codcolecaopai", { required: !disabledGrupo, disabled: disabledGrupo, value: menuLinks?.Codcolecaopai,})}>
                                <option value="">Selecione</option>
                                {grupos?.map((grupo) => (
                                    <option key={grupo.Codgrupo} value={grupo.Codgrupo}>{grupo.Dscgrupo}</option>
                                ))}
                            </select>
                        </div>
                        <div className="colecao">
                            <div className="posicaoPagina">
                                <p>Cod. Link Pai</p>
                                <input type="text" className='inputTextNome' placeholder="Cod. Link Pai" {...register("Codlinkpai", { required: false, maxLength: 100, value: menuLinks?.Codcolecaopai, onChange: onChanged, disabled: disabledLinkPai })} />
                            </div>
                        </div>
                    </div>

                    <div className="codCampo">
                        <p>Url</p>
                        <input type="text" placeholder="/exemplo" className='inputUrl' {...register("Url", { required: false, value: menuLinks?.Url, onChange: onChanged, disabled: disabledUrl })} />
                    </div>
                    <div className='botoesFiltros'>
                        <button type="submit" className="btn-novo"> Salvar</button>
                    </div>
                </form>

            </PagLinksNovo>
        </Container>
    )
};

export default Cdlink;
