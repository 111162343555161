import * as React from "react";
import { Container, ProdutoDetails, TituloSecao, Vitrine } from "./styles";
import { useContext, useEffect, useState } from "react";
import api from "../../service/api";
import userContext from "../../Context/UserContext";
import { ProdutoDetalhesDTO } from "../../DTOs/ProdutoDetalheDTO";
import { Link, useParams } from "react-router-dom";
import { formatarData, formatarValores } from "../../helpers/valores";
import { CarrinhoProdutoDTO } from "../../DTOs/produtoDTO";
import cartContext from "../../Context/CartContext";
import { useToast } from "../../hooks/toast";
import Carrossel from "../../componentes/Carrossel";

const ProdutoDetalhes: React.FC = () => {
  const [produtosDetalhes, setProdutoDetalhes] = useState<ProdutoDetalhesDTO>();
  const { id }: any = useParams();
  const currentlyCart = useContext(cartContext);
  const { addToast } = useToast();
  const currentlyUser = useContext(userContext);
  const { Codcliente, Codempresa, Indapresentaprecocusto } = currentlyUser.user;

  useEffect(() => {
    if (Codcliente > 0) {
      var filtro = {
        CodProduto: id,
        Codcliente: Codcliente,
        Codalmoxarifado: currentlyUser.user.Codalmoxarifado,
        Codempresa: currentlyUser.user.Codempresa,
        Codoperacao: currentlyUser.user.Codoperacao,
        Codempresacli: currentlyUser.user.Codempresacli
      };

      api.post(`api/produto/detalhes`, filtro).then((response) => {
        const produto: ProdutoDetalhesDTO = response.data;
        setProdutoDetalhes(produto);
      });
    }
  }, [id, Codcliente]);

  useEffect(() => {
  }, [produtosDetalhes]);

  function relacionaCarrinho(produto: ProdutoDetalhesDTO) {
    produto.VlrFormatado = formatarValores(produto.Vlrvenda);
    produto.VlrTotal =
      produto.Vlrvenda *
      Number(produto.Quantidade) *
      ((100 - produto.Desconto) / 100);
    produto.VlrTotalFormatado = formatarValores(produto.VlrTotal);

    var produtoCarrinho: CarrinhoProdutoDTO = {
      CodAutor: produto.Codautor,
      Codcarrinho: 0,
      CodProduto: produto.Codproduto,
      CodEditora: produto.Codeditora,
      Qtd: produto.Qtd,
      NomAutor: produto.Autor,
      NomEditora: produto.Editora,
      Titulo: produto.Nomproduto,
      VlrTotalFormatado: formatarValores(produto.VlrTotal),
      VlrTotal: produto.VlrTotal,
      Desconto: produto.Desconto,
      Qtdestoque: produto.Quantidade,
      VlrFormatado: produto.VlrFormatado,
      Vlr: produto.Vlrvenda,
      Disponibilidade: produto.Situacaoestoque,
      Src: process.env.REACT_APP_IMAGEMPATH + produto.Pathimagem,
      VlrDesconto: produto.VlrDesconto,
      Dscsituacaoestoque: produto.Dscsituacaoestoque,
      Inddestaque: produto.Inddestaque,
      Indlancamento: produto.Indlancamento,
      Indprevenda: produto.Indprevenda,
      Pathimagem: process.env.REACT_APP_IMAGEMPATH + produto.Pathimagem,
      QuantidadeEmEstoque: produto.QuantidadeEmEstoque,
      Datprevendafinal: produto.Datprevendafinal,
      isEdited: false,
      isAdded: false,
      isDeleted: false,
      NroItem: 0,
    };

    handleAdicionarCarrinho(produtoCarrinho);
  }

  function handleAlterarQuantidade(id: number, qtd: number) {
    // const index = produtosDetalhes.findIndex(
    //   (produtoCarrinho) => produtoCarrinho.Codproduto === id
    // );

    // Se achou produto
    // if (index > -1) {
    //   const produto = produtosDetalhes[index];
    //   // Armazena o objeto na variável
    //   produto.Quantidade = qtd;

    //   // Seta pra atualizar o state, somente o item que foi alterado
    //   setProdutoDetalhes([
    //     ...produtosDetalhes.slice(0, index),
    //     produto,
    //     ...produtosDetalhes.slice(index + 1),
    //   ]);
    // }

    produtosDetalhes!.Quantidade = qtd;

    setProdutoDetalhes(produtosDetalhes);
    currentlyCart.alteraQuantidade(id, qtd);
  }

  function handleAdicionarCarrinho(produto: CarrinhoProdutoDTO) {
    // const index = produtosDetalhes.findIndex((produtoCarrinho) => produtoCarrinho.Codproduto === produto.CodProduto);

    // // Se achou produto
    // if (index > -1) {
    //   // Armazena o objeto na variável
    //   produto.Qtd = produtosDetalhes[index].Quantidade;
    //   produto.isDeleted = false;
    // } else {
    //   produto.NroItem = 0;
    // }

    if (produtosDetalhes!.Codproduto === produto.CodProduto) {
      produto.Qtd = produtosDetalhes!.Quantidade;
      produto.isDeleted = false;
    } else {
      produto.NroItem = 0;
    }

    currentlyCart.adicionaCarrinho(produto);

    addToast({
      type: "success",
      title: "Produto adicionado ao carrinho",
    });
  }

  useEffect(() => {
    // Grava no localStorage do usuário o carrinho atualizado, se tiver algo
    if (currentlyCart.cart.Produtos.length > 0)
      localStorage.setItem(
        "@VersaB2B:carrinho",
        JSON.stringify(currentlyCart.cart)
      );
  }, [currentlyCart.cart]);

  return (
    <>
      {produtosDetalhes
        ?
        <Container key={produtosDetalhes.Codproduto}>
          <ProdutoDetails>
            <div className="tudoProduto">
              <div className="tituloProduto">
                <p className="detalhesProd">Detalhes do Produto</p>
              </div>

              <div className="imagemInformacoes">
                <div className="imgProduto">
                  <img
                    src={
                      process.env.REACT_APP_IMAGEMPATH +
                      produtosDetalhes.Pathimagem
                    }
                    alt=""
                    className="imagem-produto"
                  />
                </div>

                <div className="infosProduto">
                  <div className="titulo-produto">
                    <p className="prodTitulo">{produtosDetalhes.Nomproduto}</p>

                    <div className="infosProd">
                      <ul>
                        <span className="campo">Autor(a)(es): </span>
                        <Link
                          to={`/autor/${produtosDetalhes.Codautor
                            }/${encodeURIComponent(produtosDetalhes.Autor)}`}
                        >
                          {produtosDetalhes.Autor}
                        </Link>
                      </ul>
                      <ul>
                        {/* <li className="campo">Estoque: </li> */}

                        {/* {produto.Qtdestoque > 0 ? <p>{produto.Dscsituacaoestoque}: <b> {produto.Qtdestoque}</b></p>
                                : produto.Qtdestoque == -1 && produto.QuantidadeEmEstoque != null && produto.QuantidadeEmEstoque <= 0 ? <p>{produto.Dscsituacaoestoque}</p>
                                    : produto.Qtdestoque == -1 && produto.QuantidadeEmEstoque > 0 ? <p>{produto.Dscsituacaoestoque}: <b> {produto.QuantidadeEmEstoque}</b></p>
                                        : produto.Dscsituacaoestoque} */}

                        {/* {produtosDetalhes.Qtdestoque > 0 ? <p> {produtosDetalhes.Dscsituacaoestoque}: <b> {produtosDetalhes.Qtdestoque}</b></p>
                          : produtosDetalhes.Qtdestoque == -1 && produtosDetalhes.QuantidadeEmEstoque != null && produtosDetalhes.QuantidadeEmEstoque <= 0 ? <p>{produtosDetalhes.Dscsituacaoestoque}: {produtosDetalhes.Qtdestoque}</p>
                            : produtosDetalhes.Qtdestoque == -1 && produtosDetalhes.QuantidadeEmEstoque > 0 ? <p>{produtosDetalhes.Dscsituacaoestoque}: <b> {produtosDetalhes.QuantidadeEmEstoque}</b></p>
                              : produtosDetalhes.Qtdestoque == 0 && produtosDetalhes.Dscsituacaoestoque == null && produtosDetalhes.QuantidadeEmEstoque == 0 ? <p> ESTOQUE: <b>0 </b> </p> :
                              produtosDetalhes.Qtdestoque == 0 && produtosDetalhes.Dscsituacaoestoque == null && produtosDetalhes.QuantidadeEmEstoque == null ? <p> ESTOQUE: <b>0 </b> </p> :
                                produtosDetalhes.Dscsituacaoestoque != null ? produtosDetalhes.Dscsituacaoestoque : <b> {produtosDetalhes.Qtdestoque}</b> 
                        }  */}
                        {produtosDetalhes.Qtdestoque > 0 ? (
                          <p>{produtosDetalhes.Dscsituacaoestoque}: <b>{produtosDetalhes.Qtdestoque}</b></p>
                        ) : produtosDetalhes.Qtdestoque == -1 && produtosDetalhes.QuantidadeEmEstoque != null ? (
                          produtosDetalhes.QuantidadeEmEstoque <= 0 ? (
                            <p>{produtosDetalhes.Dscsituacaoestoque}: {produtosDetalhes.Qtdestoque > -1 ? produtosDetalhes.Qtdestoque : ""}</p>
                          ) : (
                            <p>{produtosDetalhes.Dscsituacaoestoque}: <b>{produtosDetalhes.QuantidadeEmEstoque > -1 ? produtosDetalhes.QuantidadeEmEstoque : ""}</b></p>
                          )
                        ) : produtosDetalhes.Qtdestoque == 0 && produtosDetalhes.Dscsituacaoestoque == null ? (
                          <p>ESTOQUE: <b>0</b></p>
                        ) : produtosDetalhes.Dscsituacaoestoque != null ? (
                          <p>{produtosDetalhes.Dscsituacaoestoque}: <b>{produtosDetalhes.Qtdestoque != null && produtosDetalhes.Qtdestoque > -1 ? produtosDetalhes.Qtdestoque : 0}</b></p>
                        ) : null}

                      </ul>
                    </div>
                  </div>

                  {Indapresentaprecocusto && produtosDetalhes.Desconto != null && produtosDetalhes.Desconto > 0 ? (
                    <div className="dePorValores">
                      <div className="dePor">
                        <p className="valor">
                          De {formatarValores(produtosDetalhes.Vlrvenda)}{" "}
                        </p>
                        <p className="por">Por</p>
                      </div>
                      <p className="valorFinal">
                        {
                          (produtosDetalhes.VlrTotalFormatado = formatarValores(
                            produtosDetalhes.Vlrvenda -
                            produtosDetalhes.Vlrvenda * (produtosDetalhes.Desconto / 100)
                          ))
                        }
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="valorFinal">
                        {
                          (produtosDetalhes.VlrTotalFormatado = formatarValores(
                            produtosDetalhes.Vlrvenda -
                            produtosDetalhes.Vlrvenda * (produtosDetalhes.Desconto / 100)
                          ))
                        }
                      </p>
                    </div>
                  )}

                  <Vitrine>
                    <div className="aumentarEcomprar">
                      <div className="inputNumeros">
                        <input
                          type="number"
                          className="contadorLivros"
                          defaultValue="1"
                          min="1"
                          max="100"
                          value={
                            currentlyCart.cart.Produtos?.find((p) => {
                              return p.CodProduto === produtosDetalhes.Codproduto;
                            })?.Qtd
                          }
                          onChange={(e) =>
                            handleAlterarQuantidade(
                              produtosDetalhes.Codproduto,
                              Number(e.target.value)
                            )
                          }
                          name="qtd"
                        />
                      </div>
                      <div className="buttonAdc">
                        {produtosDetalhes.Qtdestoque > 0 ? <button onClick={() => relacionaCarrinho(produtosDetalhes)} className="adcCarrinho">Adicionar ao Carrinho</button>
                          : produtosDetalhes.Qtdestoque == -1 && produtosDetalhes.QuantidadeEmEstoque <= 0 ? <button className="adcCarrinhoDisable">Adicionar ao Carrinho</button>
                            : produtosDetalhes.Qtdestoque == -1 && produtosDetalhes.QuantidadeEmEstoque > 0 ? <button onClick={() => relacionaCarrinho(produtosDetalhes)} className="adcCarrinho">Adicionar ao Carrinho</button>
                              : <button onClick={() => relacionaCarrinho(produtosDetalhes)} className="adcCarrinho">Adicionar ao Carrinho</button>
                        }
                      </div>
                    </div>
                  </Vitrine>
                </div>
              </div>

              <div className="sinopseVitrine">
                <div className="sinopseFicha">
                  <div className="sinopse">
                    <p className="Psinopsee">Sinopse</p>
                    <p className="txtSinopse">{produtosDetalhes.Sinopse}</p>
                  </div>

                  <div className="ficha">
                    <div className="camposFicha">
                      <div className="infosFicha">
                        <p className="Psinopse">Ficha Técnica</p>
                        <ul>
                          <li className="campo">Referência: </li>
                          <li className="campoDetalhe"></li>
                        </ul>
                        <ul>
                          <li className="campo">Editora: </li>
                          <li className="campoDetalhe"><Link
                            to={`/editora/${produtosDetalhes.Codeditora
                              }/${encodeURIComponent(produtosDetalhes.Editora)}`}
                          >
                            {produtosDetalhes.Editora}
                          </Link>
                          </li>
                        </ul>
                        {produtosDetalhes.Nomcolecao != null ?
                          <ul>
                            <li className="campo">Coleção: </li>
                            <li className="campoDetalhe"><Link
                              to={`/colecao/${produtosDetalhes.Codcolecao
                                }/${encodeURIComponent(produtosDetalhes.Nomcolecao)}`}
                            >
                              {produtosDetalhes.Nomcolecao}
                            </Link></li>
                          </ul>
                          : null}
                        <ul>
                          <li className="campo">Edição: </li>
                          <li className="campoDetalhe">
                            {produtosDetalhes.Nroedicao}
                          </li>
                        </ul>
                        <ul>
                          <li className="campo">Ano de edição: </li>
                          <li className="campoDetalhe">
                            {produtosDetalhes.Anoedicao}
                          </li>
                        </ul>
                        <ul>
                          <li className="campo">Data Lançamento: </li>
                          {produtosDetalhes.Datedicao == null ? (
                            ""
                          ) : (
                            <li className="campoDetalhe">
                              {formatarData(produtosDetalhes.Datedicao)}
                            </li>
                          )}

                        </ul>
                        <ul>
                          <li className="campo">Autor(a)(es): </li>
                          <li className="campoDetalhe">
                            {produtosDetalhes.Autor}
                          </li>
                        </ul>
                        <ul>
                          <li className="campo">EAN13: </li>
                          <li className="campoDetalhe">
                            {produtosDetalhes.Isbn13}
                          </li>
                        </ul>
                        <ul>
                          <li className="campo">Formato: </li>
                          <li className="campoDetalhe">
                            {produtosDetalhes.Formato}
                          </li>
                        </ul>
                        <ul>
                          <li className="campo">Origem: </li>
                          <li className="campoDetalhe">
                            {produtosDetalhes.Dscimportado}
                          </li>
                        </ul>
                        <ul>
                          <li className="campo">Idioma: </li>
                          <li className="campoDetalhe">
                            {produtosDetalhes.Nomidioma}
                          </li>
                        </ul>
                        <ul>
                          <li className="campo">Isbn: </li>
                          <li className="campoDetalhe">{produtosDetalhes.Isbn}</li>
                        </ul>
                        <ul>
                          <li className="campo">Largura: </li>
                          <li className="campoDetalhe">
                            {produtosDetalhes.Largura}
                          </li>
                        </ul>
                        <ul>
                          <li className="campo">Altura: </li>
                          <li className="campoDetalhe">
                            {produtosDetalhes.Altura}
                          </li>
                        </ul>
                        <ul>
                          <li className="campo">Paginas: </li>
                          <li className="campoDetalhe">
                            {produtosDetalhes.Qtdpaginas}
                          </li>
                        </ul>
                        <ul>
                          <li className="campo">Peso: </li>
                          <li className="campoDetalhe">{produtosDetalhes.Peso}</li>
                        </ul>
                        <ul>
                          <li className="campo">Encadernação: </li>
                          <li className="campoDetalhe">{produtosDetalhes.Encadernacao}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ProdutoDetails>
          <TituloSecao>Produtos Relacionados</TituloSecao>
          <Carrossel
            Editora={produtosDetalhes.Codeditora}
            Codcliente={Codcliente}
            Codempresacli={Codempresa}
          />
        </Container>
        : "Nenhuma produto encontrado!"}
    </>
  );
};

export default ProdutoDetalhes;
