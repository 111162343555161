import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { LoginDTO } from '../../DTOs/LogintDTO';
import { useToast } from '../../hooks/toast';
import api from '../../service/api';
import { Botao, Cabecalho, Campos, ContainerForm, Formulario, InputLogin, LinksLogin, ListaRodapeLogin, RodapeLogin, SubtituloLogin } from "./styles";

interface EsqueciASenhaDTO {
  email: string
};

const RedefinirSenha: React.FC = (props: any) => {
  const { register, handleSubmit, formState: { errors } } = useForm<EsqueciASenhaDTO>();
  const [verificacao, setVerificacao] = useState([]);
  const [values, setValues]: any = React.useState({});
  const { addToast } = useToast();
  const history = useHistory();



  const EsqueciASenha = (email: EsqueciASenhaDTO) => {

    api.post(`api/business/usuario/esqueciasenha`, email).then(() => {
      addToast({
        type: 'info',
        title: 'Uma nova senha foi enviada ao seu email!',
       });
    }).catch(() => {
      addToast({
        type: 'error',
        title: 'E-mail informado inválido ou não registrado!'
      });

    });
    history.push("/login");
  }
const onSubmit = (data: EsqueciASenhaDTO) => {
  EsqueciASenha(data);
};


return (
  <ContainerForm>
    <Formulario onSubmit={handleSubmit(onSubmit)}>
      <Cabecalho>
        <SubtituloLogin>Não consegue entrar?</SubtituloLogin>
      </Cabecalho>
      <Campos>
        <InputLogin>
          <input placeholder="Digite seu email" defaultValue="" type="text" {...register("email", { required: true })} />
          {errors.email && <span>This field is required</span>}
        </InputLogin>
      </Campos>
      <Campos>
        <Botao type="submit">Enviar link de recuperação</Botao>
      </Campos>
      <RodapeLogin>
        <ListaRodapeLogin>
          <LinksLogin to="/login"><span>Voltar à página de entrada</span></LinksLogin>
        </ListaRodapeLogin>
      </RodapeLogin>
    </Formulario>
  </ContainerForm>
);
}

export default RedefinirSenha;