import { createContext } from "react";
import { CarrinhoDTO, CarrinhoProdutoDTO, ItemCarrinhoFiltroDTO } from "../DTOs/produtoDTO";

type CartContextType = {
  cart: CarrinhoDTO;
  alteraCarrinho: (cart: CarrinhoDTO) => void;
  limpaCarrinho: () => void;
  adicionaQuantidade: (id: number) => void;
  removeQuantidade: (id: number) => void;
  alteraQuantidade: (id: number, qtd: number) => void;
  adicionaCarrinho: (produto: CarrinhoProdutoDTO) => void;
  esvaziaCarrinho: () => void;
  buscaCarrinho: (codcliente: number) => void;
  removeItem: (id: number) => void;
};

export const cartInital: CarrinhoDTO = {
  Codcarrinho: 0,
  CodCliente: 0,
  CodEmpresa: 0,
  CodUsuario: 0,
  DatCarrinho: "",
  Produtos: [],
  VlrMercadoria: 0,
  VlrDesconto: 0,
  VlrTotal: 0,
  CodSituacao: 0,
};

const cartContext = createContext<CartContextType>({
  cart: cartInital,
  alteraCarrinho: (cart: CarrinhoDTO) => {},
  limpaCarrinho: () => {},
  adicionaQuantidade: () => {},
  removeQuantidade: () => {},
  alteraQuantidade: () => {},
  adicionaCarrinho: () => {},
  esvaziaCarrinho: () => {},
  buscaCarrinho: (codcliente: number) => {},
  removeItem: (id: number) => {},
});

export default cartContext;
