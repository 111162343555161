import styled from "styled-components";

export const Container = styled.button`
  @media (min-width: 1800px) {
    font-size: 20px;
  }

  padding: 5px 0;
  font-weight: bold;
  margin: 5px 0;
  box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 20%);
`;
