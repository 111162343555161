import * as React from 'react';
import { Container, PagLinks, TituloSecao } from '../styles';
import { Link } from 'react-router-dom';
import { PaginaEstaticaDTO } from '../../../DTOs/PaginaEstaticaDTO';
import { useCallback, useEffect, useState } from 'react';
import api from '../../../service/api';

import { useToast } from "../../../hooks/toast";
import PaginaEstaticaTable from './PaginaEstaticaTable';
import { SortColumnDTO } from '../../../DTOs/SortColumnDTO';
import _ from 'lodash';
import Pagination from '../../../componentes/Paginacao/Pagination';
import { ContainerPagination } from '../../../componentes/Grid/styles';

const PaginasEstaticas: React.FC = () => {
    const { addToast } = useToast();
    const [paginasEstaticas, setpaginasEstaticas] = useState<PaginaEstaticaDTO[]>([]);
    const [sortColumn, setSortColumn] = useState<SortColumnDTO>({ path: "Nomeditora", order: "asc" });
    const [itensPerPage] = useState(3);
    const [currentPage, setCurrentPage] = useState(-1);
    const [currentItens, setCurrentItens] = useState<PaginaEstaticaDTO[]>([]);
    const pages = Math.ceil(paginasEstaticas.length / itensPerPage);
    const startIndex = currentPage * itensPerPage;
    const endIndex = startIndex + itensPerPage;
    const [reload, setReload] = useState(false);
    
    function ListaPaginasEstaticas(filtro: any) {
        // Busca os pedidos da API
        api.post(`api/configuracao/paginasestaticas`, filtro).then((response) => {
            const paginasEstaticas = response.data;
            setpaginasEstaticas(paginasEstaticas);
            setCurrentPage(0);
            setCurrentItens(paginasEstaticas.slice(startIndex, endIndex));
            setReload(true);
        });
    }

    useEffect(() => {
        setCurrentItens(paginasEstaticas.slice(startIndex, endIndex));
      }, [currentPage]);

    useEffect(() => {

        var filtro = {
            Codpagina: 0,
            Nompagina: "",
        }

        ListaPaginasEstaticas(filtro);
    }, [reload]);

    const handleSort = useCallback((sortColumn: SortColumnDTO) => {
        setSortColumn(sortColumn);
    }, [sortColumn]);

    useEffect(() => {
        setCurrentItens(paginasEstaticas.slice(startIndex, endIndex));
    }, [currentPage]);

    useEffect(() => {
        if (sortColumn != null && currentItens != undefined) {
            const sorted = _.orderBy(currentItens, [sortColumn.path], [sortColumn.order]);
            setCurrentItens(sorted);
        }
    }, [sortColumn]);

    function FiltroPaginaEstaticas(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let formData = new FormData(event.currentTarget);
        let codigo = formData.get("txtCodigo") as string;
        let nome = formData.get("txtNome") as string;

        var filtro = {
            Codpagina: codigo,
            Nompagina: nome,
        }

        ListaPaginasEstaticas(filtro);
    }


    const handleExcluiPaginaEstatica = useCallback((id?: number) => {
        try {

            let paginasCopia = [...currentItens];
            let paginasAtuais = paginasCopia.filter((item) => item.Codpagina !== id);

            api.delete(`api/configuracao/paginaestatica/${id}`);
            setpaginasEstaticas(paginasAtuais);
            setReload(!reload);

            addToast({
                type: 'success',
                title: 'Página estática excluída com sucesso'
            });


        } catch (error) {
            addToast({
                type: 'error',
                title: 'Erro ao excluir página estática'
            });
        }
    }, [reload, addToast]);

    return (
        <Container>
            <TituloSecao>
                Filtros
            </TituloSecao>

            <PagLinks>
                <form onSubmit={FiltroPaginaEstaticas}>
                    <div className="codCampo">
                        <p>Código</p>
                        <input type="text" className='inputText' name="txtCodigo" />
                    </div>

                    <div className="nomePosicao">
                        <div className="nomCampo">
                            <p>Nome</p>
                            <input type="text" className='inputTextNome' name="txtNome" />
                        </div>
                    </div>

                    <div className='botoesFiltros'>
                        <Link to="/configuracao/cdpaginaestatica"><button className="btn-novo">Novo</button></Link>
                        <button type='submit' className="btn-novo">Pesquisar</button>
                    </div>
                </form>
                <PaginaEstaticaTable
                    paginasEstaticas={currentItens}
                    sortColumn={sortColumn}
                    // onLike={this.handleLike}
                    onDelete={handleExcluiPaginaEstatica}
                    onSort={handleSort}
                />

                <ContainerPagination>
                    <Pagination pages={pages} setCurrentPaged={setCurrentPage} currentPage={currentPage} />
                </ContainerPagination>
            </PagLinks>
        </Container>
    )
};

export default PaginasEstaticas;


