import * as React from 'react';
import { Container, TituloSecao } from './styles';

import Botao from '../../componentes/Botao';
import Grid from '../../componentes/Grid';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import userContext from '../../Context/UserContext';

const Editora: React.FC = () => {
  const {id, name}: any = useParams();
  const currentlyUser = useContext(userContext);
  const {Codcliente, Codempresa} = currentlyUser.user;
 
  return (
    <Container>
      <TituloSecao>
        Editora {decodeURIComponent(name)} 
      </TituloSecao>
      <Grid Editora={id} Codcliente={Codcliente} Codempresacli={Codempresa}/>
    </Container>
  )
};

export default Editora;
