import styled from 'styled-components';

export const Container = styled.div`
  color: #000000;
  top: 100px;
  margin-bottom: 4rem;
  margin-top: 3rem;

  @media (max-width: 325px) {
    max-width: 300px;
  }
`;

export const TituloSecao = styled.h3`
  padding: 20px;
  color: #313131;
  font-size: 26px;
  text-align: left;
  margin-bottom: 12px;
`;

export const PagConfiguracoes = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

    .links{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    p{
        margin-bottom: 0;
    }

    .icon{
        margin-bottom: 10px;
    }

    a{
        margin-right: 2rem;
        border: solid 1px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width: 10%;
        height: 100px;
        color: #111;
    }

    a:hover{
        color: var(--filtrarHover-bg-color);
    }
`;


export const PagLinks = styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 10rem;

        a{
            color: #000;
        }

        a:hover {
            color: #423e3e;
        }

        .codCampo{
            margin-bottom: 1rem;
        }

        .nomePosicao{
            width: 50%;
            display: flex;
            flex-direction: row;
        }

        .inputTextNome{
            width: 30rem;
            height: 2rem;
            border: 1px #d9d9d9 solid;
            padding-right: 5px;
            padding-left: 5px;
        }

        .posicaoCampo{
            margin-left: 1rem;
        }

        p{
            font-size: 14px;
        }

        .botoesFiltros{
            margin-top: 1rem;
            width: 9%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .btn-novo{
            width: 70px;
            font-weight: bold;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .btn-novo:hover{
            background-color: var(--filtrarHover-bg-color);
            color: #fff;
        }

        .inputText{
            border: 1px #d9d9d9 solid;
            height: 2rem;
            width: 20rem;
        }

        .selectPosicao{
            border: 1px #d9d9d9 solid;
            height: 2rem;
            width: 20rem;
        }

        .campoCodLink{
            outline: none;
        }

        table{
            margin-top: 2rem;
            width: 80%;
        }

        td, tr {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
        }

        td:first-child { width: 100px  };
        td:first-child + td {  width: 300px };
        td:first-child + td + td {  width: 200px };
        td:first-child + td + td + td {  width: 200px };
        td:first-child + td + td + td + td {  width: 200px };
        td:first-child + td + td + td + td + td {  width: 0px };

        th:first-child {width: 10px};

`;

export const PagLinksNovo = styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 10rem;

        p{
            font-size: 14px;
            margin-top: 1rem;
        }

        label{
            font-size: 14px;
        }

        .nomePagina{
            display: flex;
            flex-direction: row;
            width: 50%;
        }

        .inputText{
            border: 1px #d9d9d9 solid;
            height: 2rem;
        }

        .inputUrl{
            width: 31rem;
            border: 1px #d9d9d9 solid;
            height: 2rem;
        }

        .inputColecao{
            border: 1px #d9d9d9 solid;
            height: 2rem;
            width: 20rem;
        }

        .inputTextNome{
            width: 20rem;
            height: 2rem;
            border: 1px #d9d9d9 solid;
            padding-right: 5px;
            padding-left: 5px;
        }

        .posicaoColecao{
            width: 20rem;
        }

        .posicaoPagina{
            margin-left: 1rem;
        }

        .campoCodPai{
            margin-left: 1rem;
        }

        .selectPagina{
            width: 10rem;
            height: 2rem;
            border: 1px #d9d9d9 solid;
        }

        .colecao{
            width: 50%;
            display: flex;
            flex-direction: row;
        }

        input[type="checkbox"]{
            display: block;
            margin-right: 5px;
            width: 20px;
            height: 20px;
            background-color: #fff;
            border: 2px #d9d9d9 solid;
        }

        .camposCheck{
            margin-right: 2rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            display: flex;
            flex-direction: row;
        }

        .urlExterna{
            margin-left: 1rem;
            cursor: pointer;
        }

        .botoesFiltros{
            margin-top: 1rem;
            width: 9%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .btn-novo{
            width: 70px;
            font-weight: bold;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .btn-novo:hover{
            background-color: var(--filtrarHover-bg-color);
            color: #fff;
        }
`;


export const Lstpublicidade = styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 10rem;

        a{
            color: #000;
        }

        a:hover {
            color: #423e3e;
        }

        .botoesFiltros{
            margin-top: 1rem;
            width: 9%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .btn-novo{
            width: 70px;
            font-weight: bold;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .btn-novo:hover{
            background-color: var(--filtrarHover-bg-color);
            color: #fff;
        }

        .inputText{
            border: 1px #d9d9d9 solid;
            height: 2rem;
        }

        .inputNome{
            border: 1px #d9d9d9 solid;
            height: 2rem;
            width: 30rem;
        }

        table{
            margin-top: 2rem;
            width: 80%;
        }

        td, tr {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
        }


        td:first-child { width: 200px  };
        td:first-child + td {  width: 70rem };
        td:first-child + td + td {  width: 0 };

        th:first-child {width: 10px};

`;

export const LstpublicidadeModelo = styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 10rem;

        a{
            color: #000;
        }

        a:hover {
            color: #423e3e;
        }

        .botoesFiltros{
            margin-top: 1rem;
            width: 9%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .btn-novo{
            width: 70px;
            font-weight: bold;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .btn-novo:hover{
            background-color: var(--filtrarHover-bg-color);
            color: #fff;
        }

        table{
            margin-top: 2rem;
            width: 80%;
        }

        td, tr {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
        }

        .inputText{
            border: 1px #d9d9d9 solid;
            height: 2rem;
        }

        .inputDescricao{
            border: 1px #d9d9d9 solid;
            height: 2rem;
            width: 30rem;
        }

        td:first-child { width: 200px  };
        td:first-child + td {  width: 70rem };

        th:first-child {width: 10px};
`;

export const CadastroPublicidade = styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 10rem;

        .inputTextCodigo{
            border: 1px #d9d9d9 solid;
            height: 2rem;
            margin-bottom: 1rem;
        }

        .datas{
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;
        }

        .inputDate{
            border: 1px #d9d9d9 solid;
            height: 2rem;
            width: 12rem;
            margin-right: 1rem;
        }

        .inputDescricao{
            border: 1px #d9d9d9 solid;
            height: 2rem;
            width: 30rem;
            margin-bottom: 1rem;
        }

        .camposSelects{
            display: flex;
            flex-direction: row;
            margin-bottom: 1rem;
        }

        .inputSelect{
            border: 1px #d9d9d9 solid;
            height: 2rem;
            width: 18rem;
            margin-right: 1rem;
        }

        .botoesFiltros{
            margin-top: 1rem;
            width: 9%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .btn-novo{
            width: 70px;
            font-weight: bold;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .btn-novo:hover{
            background-color: var(--filtrarHover-bg-color);
            color: #fff;
        }
        
        .campoImg{
                display: flex;
                flex-direction: column;
                
        }
        .inputImagem{
            margin-top:5px;
        }

        .containerImg{
            border: 1px solid #d9d9d9;
            max-width: 690px;
            max-height: 350px;
            margin-top:5px;
         
        }

        .img{
            width: 100%;
            height: 100%;
        }

`;

export const CadastroPublicidadeModelo = styled.div`
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 10rem;

        .inputTextCodigo{
            border: 1px #d9d9d9 solid;
            height: 2rem;
            margin-bottom: 1rem;
        }

        .inputDescricao{
            border: 1px #d9d9d9 solid;
            height: 2rem;
            width: 30rem;
            margin-bottom: 1rem;
        }

        .larguraAltura{
            display: flex;
            flex-direction: row;
        }

        .inputLarguraAltura{
            border: 1px #d9d9d9 solid;
            height: 2rem;
            width: 14.3rem;
            margin-right: 1rem;
        }


        .botoesFiltros{
            margin-top: 1rem;
            width: 9%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        .btn-novo{
            width: 70px;
            font-weight: bold;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .btn-novo:hover{
            background-color: var(--filtrarHover-bg-color);
            color: #fff;
        }

`;

export const PagCores = styled.div`
        width: 100%;

        .coresHeader {
            width: 80%;
            display: flex;
            flex-direction: column;
            margin-left: 10%
        }

        .titulo {
            width: 100%;
            padding: 0 0 30px 0;
            color: #313131;
            font-size: 26px;
            text-align: left;
            margin-bottom: 12px;
        }

        .titulo2 {
            width: 100%;
            padding: 0 0 30px 0;
            color: #313131;
            font-size: 26px;
            text-align: left;
            margin-bottom: 12px;
            margin-top: 4rem;
        }

        .caixaCores {
            display: flex;
            flex-direction: row;
        }

        .caixaInfos{
            display: flex;
            flex-direction: column;
        }

        .cor {
            width: 80%;
            display: flex;
            flex-direction: column;
        }

        .infosFooter {
            display: flex;
            justify-content: space-around;
            margin-bottom: 1rem;
        }

        .infosSocialFooter{
            display: flex;
            justify-content: space-around;
            margin-top: 2rem;
        }

        .infosSocial{
            display: flex;
            flex-direction: column;
        }

        .caixaCor {
            width: 200px;
            border-color: #fff;
            cursor: pointer;
            margin-bottom: 3rem;            
        }

        .label {
            padding: 20px;
            color: #313131;
            font-size: 26px;
            text-align: left;
            margin-bottom: 12px;
        }

        label{
            font-weight: bold;
        }

        .botoesFiltros{
            margin-top: 1rem;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        .btn-novo{
            width: 70px;
            font-weight: bold;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .btn-novo:hover{
            background-color: var(--filtrarHover-bg-color);
            color: #fff;
        }

`;