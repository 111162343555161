import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import api from '../../service/api';
import { Botao, Cabecalho, Campos, ContainerForm, Formulario, InputLogin, LinksLogin, ListaRodapeLogin, RodapeLogin, SubtituloLogin } from './styles';
import userContext from '../../Context/UserContext';
import { LoginDTO } from '../../DTOs/LogintDTO';
import { UsuarioLogadoDTO } from '../../DTOs/UsuarioLogadoDTO';
import cartContext from '../../Context/CartContext';
import { CarrinhoDTO } from '../../DTOs/produtoDTO';
import { MenuRaizDTO } from '../../DTOs/MenuDTO';

type Inputs = {
  username: string;
  password: string;
};

const Login: React.FC = () => {
  const { register, formState: { errors }, handleSubmit } = useForm<Inputs>();
  const history = useHistory();
  const currentlyCart = useContext(cartContext);
  const currentlyUser = useContext(userContext);

  const onSubmit = (formData: Inputs) => {
    const { username, password } = formData;

    const usuario: LoginDTO = {
      email: username,
      senha: password,
    };

    api.post(`api/business/usuario/login`, usuario)
      .then((response) => {
        const usuarioLogado: UsuarioLogadoDTO = response.data;

        if (usuarioLogado != null) {

          api.get(`api/business/menu`).then((response) => {

            // Desestrutura o objeto da API com somente os campos necessários na interface
            // e formata o valor para exibição
            const menus: MenuRaizDTO = response.data;

            // Seta os produtos no state para usar na página

            if (menus != null) {
              var userLogado: UsuarioLogadoDTO = {
                ...usuarioLogado,
                Menu: menus
              }

              localStorage.setItem('@VersaB2B:login', JSON.stringify(userLogado));
              currentlyUser.alteraUsuario(userLogado);

            } else {
              localStorage.setItem('@VersaB2B:login', JSON.stringify(usuarioLogado));
              currentlyUser.alteraUsuario(usuarioLogado);
            }

            localStorage.setItem('@VersaB2B:Autorizacao', 'true');

            if (usuarioLogado.Codperfil !== 1) {
              history.push('/listaclientes');

            } else {
              localStorage.removeItem('@VersaB2B:carrinho');
              currentlyCart.limpaCarrinho();
              currentlyCart.buscaCarrinho(usuarioLogado.Codcliente);
              history.push('/');
            }

          });

        } else {
          alert('Nome de usuário ou senha incorretos!');
        }
      })
      .catch((error) => {
        alert('Nome de usuário ou senha incorretos!');
      });
  };

  return (
    <ContainerForm>
      <Formulario onSubmit={handleSubmit(onSubmit)}>
        <Cabecalho>
          <SubtituloLogin>Entre para continuar:</SubtituloLogin>
        </Cabecalho>
        <Campos>
          <InputLogin>
            <input placeholder="Usuário" defaultValue="" type="text" required {...register('username', { required: false })} />
            {errors.username && <span>This field is required</span>}
          </InputLogin>
        </Campos>
        <Campos>
          <InputLogin>
            <input placeholder="Senha" defaultValue="" type="password" required {...register('password', { required: false })} />
          </InputLogin>
        </Campos>
        <Campos>
          <Botao type="submit">Login</Botao>
        </Campos>
        <RodapeLogin>
          <ListaRodapeLogin>
            <LinksLogin to="/redefinirsenha">
              <span>Não consegue entrar?</span>
            </LinksLogin>
            <span>•</span>
            <LinksLogin to="/criarconta">
              <span>Crie uma conta</span>
            </LinksLogin>
          </ListaRodapeLogin>
        </RodapeLogin>
      </Formulario>
    </ContainerForm>
  );
};

export default Login;
