import styled from "styled-components";

export const TituloSecao = styled.h3`
  padding-left: 20px;
  color: #313131;
  font-size: 26px;
  text-align: left;
  margin-bottom: 12px;

  @media(max-width: 923px) {
    padding: 20px;
    color: #313131;
    font-size: 26px;
    text-align: left;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
  }
`;

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  width: 100%;
  /* max-width: 2560px; */
`;

export const Vitrine = styled.div`
  display: flex;

  p {
    margin: 0;
    font-size: 14px;
  }

  .folderTotal {
    min-width: 320px;
    max-width: 320px;
    min-height: 350px;
  }

  .capaLivroETitulo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
  }

  .imgCapa {
    margin-bottom: 1rem;
    height: 250px;
    width: 100%;
    min-height: 250px;
    min-width: 100%;
  }

  .imagemCapa {
    width: 100%;
    min-height: 350px;
  }

  .tituloLivro {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 14px;
    color: #111;
  }

  .infosLivros {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .valorEDesconto {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .valorProduto {
    font-size: 18px;
    font-weight: 800;
  }

    .adcCarrinho{
        background-color: var(--cardButton-bg-color);
        color: rgb(255, 255, 255);
        cursor: pointer;
        border: none;
        height: 50px;
        padding: 1px;
    }

    .adcCarrinhoDisable{
      background-color: darkgray;
        color: rgb(255, 255, 255);
        cursor: not-allowed;
        border: none;
        height: 50px;
        padding: 1px;      
    }

  .aumentarEcomprar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
    height: 50px;
    /* box-shadow: 0 12px 15px rgb(0 0 0 / 15%); */
  }

  .inputNumeros {
    width: 20%;
  }

  .contadorLivros {
    width: 100%;
    /* height: 100%; */
    font-size: 14px;
    border: none;
    padding: 1px;
    text-align: center;
    background: #eee;
    line-height: 50px;
  }

  .buttonAdc {
    width: 75%;
  }

  .adcCarrinho {
    width: 100%;
    height: 100%;
  }

  .adcCarrinhoDisable{
    width: 100%;
    height: 100%;
    }
`;

export const CarrosselItem = styled.div`
  display: flex;
  width: 300px;
  max-width: 300px;
  margin: 0 0 0 5px;
  padding: 10px;
  flex-direction: column;
  justify-content: flex-start;
  height: -moz-fit-content;
  padding: 20px 20px 2rem 32px;
  margin-bottom: 2rem;
  border: solid 1px rgba(133, 130, 130, 0.5);
  border-radius: 5px;

  @media (max-width: 375px) {
    width: 100%;
    flex-wrap: wrap;
    flex: 1;
    /* max-width: min-content; */
    padding: 10px;
  }

  @media (min-width: 376px) and (max-width: 436px) {
    width: 100%;
    padding: 10px;
    justify-content:center;
  }

  @media (min-width: 1521px) {
    width: 13%;
    margin: 0 30px;
    padding: 10px;
    margin-bottom: 4rem;
  }

`;

export const CarrosselItemImagem = styled.div`
  display: inherit;
  justify-content: center;
  height: 250px;

  .infosCards{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
  }

  .cardDesconto {
    background: var(--cardDesconto-bg-color);
    margin-left: 14rem;
    padding: 0;
    z-index: 1;
    width: 80px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
  }

  .inativo{
    background: none !important;
  }

  .cardMaisVendidos {
    position: absolute;
    background: var(--cardMaisVendidos-bg-color);
    width: 80px;
    height: 30px;
    top: 0px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
    bottom: 1rem;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
  }

  .cardLancamentos {
    position: absolute;
    background: var(--cardLancamentos-bg-color);
    width: 80px;
    max-width: 80px;
    height: 30px;
    top: 40px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
    bottom: 2rem;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;    
  }

  .cardPreVenda{
    position: absolute;
    background: var(--cardPreVenda-bg-color);
    width: 80px;
    height: 30px;
    top: 80px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
    bottom: 2rem;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    z-index: 0;
  }

  @media (min-width: 376px) and (max-width: 436px) {
    max-height: 190px;
  }

  
  .sidebar-row{
    width: 100%;
    margin-bottom: 25px;
    height: 100%;
  }

  .sidebar-row a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    color: #111;

    /* @media (max-width: 375px) {
      width: 300px;
    }

    @media (min-width: 376px) and (max-width: 436px) {
      width: 290px;
      max-width: 250px;
    }

    @media (min-width: 1000px) {
      top: 0;
      max-height: 275px;
      width: 100%;
    } */
  }
`;

export const CarrosselItemTags = styled.div`
  display: flex;
  float: right;
  justify-content: flex-end;
  max-width: 200px;
  flex-wrap: wrap;
`;

// export const CarrosselItemTagDesconto = styled.span`
//   background-color: #004aad;
//   color: #ffffff;
// `;

// export const CarrosselItemTagPreVenda = styled.span`
//   background-color: #03989e;
//   color: #ffffff;
// `;

// export const CarrosselItemTagPromocao = styled.span`
//   background-color: #008037;
//   color: #ffffff;
// `;

// export const CarrosselItemTagLancamento = styled.span`
//   background-color: #ff9b00;
//   color: #ffffff;
// `;

// export const CarrosselItemTagMaisVendidos = styled.span`
//   background-color: #ff66c4;
//   color: #ffffff;
// `;

export const CarrosselItemDados = styled.div`
  @media (max-width: 768px) {
    margin: 4rem 0 0;
  }

  @media (min-width: 376px) and (max-width: 436px) {
      /* margin-top: 8rem; */
    }

  @media (min-width: 769px) {
    margin: 0.8rem 0 0;
  }
`;

export const CarrosselItemDadosTituloProduto = styled.p`
  /* margin-bottom: 1rem; */
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  min-height: 50px;

  a {
    color: #111;
  }
`;

export const CarrosselItemDadosValorProduto = styled.div`
    font-size: 14px;
    padding: 0 0;
    display: flex;
    justify-content: space-between;

  @media (min-width: 1000px) {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 1800px) {
    font-size: 1rem;
  }

  .foraCatalogo{
    font-size: 14px;
  }
`;

export const CarrosselItemDadosEstoqueProduto = styled.div`
  font-size: 14px;
  padding: 0 0;

  .botoesTotais {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .inputAumentar {
    height: 70%;
    width: 25%;
  }

  .botaoAdc {
    width: 80%;
  }

  .contadorLivros {
    width: 100%;
    height: 100%;
  }

  .foraCatalogo{
    font-size: 10px;
  }

  @media (min-width: 1000px) {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 1800px) {
    font-size: 1rem;
  }
`;

export const CarrosselItemDadosDescontoProduto = styled.p`
  padding: 3px 0;
  text-align: center;

  @media (min-width: 1000px) {
    display: flex;
    justify-content: center;
  }
`;

export const CarrosselItemDadosAutorProduto = styled.p`
  font-size: 14px;
  padding: 0 0;

  a {
    color: #111;
  }
`;

export const CarrosselDesktop = styled.div`

@media(max-width: 430px){
  display: none;
}
`;

export const CarroselResponsivo = styled.div`

.botoesPaginacao{
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  color: black;
  align-items: baseline;
}

.botaoPagEsquerda{
  margin-right: 10px;
}

@media(min-width: 431px){
  display: none;
}
`;

export const CarrosselItemDadosDisponibilidadeProduto = styled.p`
  font-size: 12px;
  padding: 10px 0;
  color: #787474;

  @media (min-width: 1800px) {
    font-size: 1.2rem;
  }
`;

export const BotoesPaginacao = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  flex: 1;
  flex-wrap: wrap;
  padding: 20px 20px;

  @media (min-width: 376px) and (max-width: 436px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }


  @media (max-width: 923px) {
    top: 10rem;

  }
`;

export const BotoesPaginacaoEsquerda = styled.div`
  button {
    @media (max-width: 375px) {
      width: 100%;
    }

    @media (min-width: 1000px) {
      /* display: grid; */
      justify-items: start;
    }
  }
`;

export const BotoesPaginacaoDireita = styled.div`
  button {
    @media (max-width: 375px) {
      width: 100%;
    }

    @media (min-width: 1000px) {
      /* display: grid; */
      justify-items: end;
      
    }
  }
`;
