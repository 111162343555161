import * as React from 'react';
import { useForm } from "react-hook-form";
import { CadastroPublicidade, Container, TituloSecao } from '../styles';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../service/api';
import { useEffect, useState } from 'react';
import { useToast } from '../../../hooks/toast';
import { Publicidade, PublicidadeModeloDTO } from '../../../DTOs/PublicidadeModeloDTO';
import { formatarData } from '../../../helpers/valores';


const CdPublicidade: React.FC = () => {
    const { addToast } = useToast();
    const [publicidade, setPublicidade] = useState<Publicidade>();
    const [selectedFile, setSelectedFile] = useState<any>();
    const [pathResponse, setPathResponse] = useState<string>();
    const [publicidadeModelo, setPublicidadeModelo] = useState<PublicidadeModeloDTO[]>([]);
    const { register, handleSubmit, setValue } = useForm();
    const { id }: any = useParams();
    const isAddMode = !id;
    let history = useHistory();

    function onChangeFile(event: any) {
        const target = event.target;
        if (target.files != null) {
            const file = target.files[0];
            setSelectedFile(file);
        }
    };

    function uploadImagem() {
        if (selectedFile == null) {
            addToast({
                type: 'error',
                title: 'Erro ao subir imagem da publicidade!'
            });
        }
        else {
            const formData = new FormData();
            formData.append('dataFile', selectedFile, selectedFile.name);
            api.post(`api/DocumentUpload/MediaUpload`, formData).then((response) => {
                const data = response.data;
                setPathResponse(data);
            }).catch(err => {
                alert(err.message);
            });

        }
    }

    useEffect(() => {
        if (!isAddMode) {
            api.get(`api/business/publicidade/${id}`).then((response) => {
                setValue("Codpublicidade", response.data["Codpublicidade"]);

                var datVigenciaInitial = response.data["Datvigencia"];
                var dia = datVigenciaInitial.slice(0, 2);
                var mes = datVigenciaInitial.slice(3, 5);
                var ano = datVigenciaInitial.slice(6, 10);
                var datVigencia= ano + '-' + dia + '-' + mes;
                setValue("Datvigencia", datVigencia);

                var DatValidadeInitial = response.data["Datvalidade"];
                var dia = DatValidadeInitial.slice(0, 2);
                var mes = DatValidadeInitial.slice(3, 5);
                var ano = DatValidadeInitial.slice(6, 10);
                var Datvalidade = ano + '-' + dia + '-' + mes;

                setValue("Datvalidade", Datvalidade);
                setValue("Dscpublicidade", response.data["Dscpublicidade"]);
                setValue("Linkdestino", response.data["Linkdestino"]);
                setValue("Pathimg", response.data["Pathimg"]);
                setPublicidade(response.data);
            });
        }
    }, []);

    useEffect(() => {
        if (selectedFile != null) {
            uploadImagem();
        }
    }, [selectedFile]);

    function SalvarPublicidade(publicidade: Publicidade) {

        if (pathResponse != null) {
            publicidade.Pathimg = pathResponse;
        }

        if (id != null) {
            api.put(`api/business/publicidade/${id}`, publicidade).then((response) => {
                addToast({
                    type: 'success',
                    title: 'Publicidade atualizada com sucesso'
                });
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao atualizar publicidade'
                });
            });
        } else {
            api.post(`api/business/publicidade`, publicidade).then((response) => {
                addToast({
                    type: 'success',
                    title: 'Publicidade criada com sucesso'
                });
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao criar publicidade'
                });
            });
        }
        history.push("/configuracao/publicidade/lstpublicidade");

    }

    const OnSubmit = (data: any) => {
        const publicidade: Publicidade = data;
        SalvarPublicidade(publicidade);
    };

    function listPublicidadeModelos() {
        api.get(`api/business/publicidademodelo`).then((response) => {
            const publicidadeModelo = response.data;
            setPublicidadeModelo(publicidadeModelo);
        });
    }

    return (
        <Container>
            <TituloSecao>
                Cadastro de Publicidade
            </TituloSecao>

            <CadastroPublicidade>
                <form onSubmit={handleSubmit(OnSubmit)}>
                    <div className="codCampo">
                        <p hidden={publicidade ? false : true}>Código</p>
                        <input type="text" className='inputTextCodigo' placeholder="Codigo" hidden={publicidade ? false : true}  {...register("Codpublicidade", { required: false, maxLength: 80, value: publicidade?.Codpublicidade, disabled: true })} />
                    </div>

                    <div className="datas">
                        <div className="camposDate">
                            <p>Vigência</p>
                            <input type="date" className='inputDate' {...register("Datvigencia", { required: false, maxLength: 100, value: publicidade?.Datvigencia })} />
                        </div>
                        <div className="camposDate">
                            <p>Validade</p>
                            <input type="date" className='inputDate' {...register("Datvalidade", { required: false, maxLength: 100, value: publicidade?.Datvalidade })} />
                        </div>
                    </div>

                    <div className="campoDescricao">
                        <p>Descrição</p>
                        <input type="text" className='inputDescricao' placeholder="Descrição" {...register("Dscpublicidade", { required: true, maxLength: 100, value: publicidade?.Dscpublicidade })} />
                    </div>

                    <div className="campoDescricao">
                        <p>Link</p>
                        <input type="text" placeholder="Url" className='inputDescricao' {...register("Linkdestino", { required: false, value: publicidade?.Linkdestino })} />
                    </div>

                    {/* <div className='camposSelects'>
                        <div className="posicaoCampo">
                            <p>Tipo</p>
                            <select name="select" className='inputSelect'>
                                <option value="1" selected>Selecione</option>
                                <option value="valor2"></option>
                                <option value="valor3"></option>
                                <option value="valor3"></option>
                                <option value="valor3"></option>
                            </select>
                        </div>

                        <div className="posicaoColecao">
                            <p>Modelo 1900px X 450px</p>
                            <select className='inputSelect' onClick={listPublicidadeModelos} {...register("CodPublicidadeModelo", { required: false, value: publicidade?.Codpublicidademodelo })}>
                                <option value="1" selected>Selecione</option>
                                {publicidadeModelo?.map((publicidadeModelo, index: any) => (
                                    <option key={index} value={publicidadeModelo.Codpublicidademodelo}>{publicidadeModelo.Dscpublicidademodelo}</option>
                                ))}
                            </select>
                        </div>
                    </div> */}

                    <div className="campoImg">
                        <p>Imagem(1900px X 450px)</p>
                        <input type="file" className='inputImagem' name='file' onChange={onChangeFile}/>
                        <div className="containerImg">
                            <img className="img" src={'http://186.209.139.67:8080/' + publicidade?.Pathimg} />
                        </div>
                        
                    </div>

                    <div className='botoesFiltros'>
                        <button type="submit" className="btn-novo"> Salvar</button>
                    </div>
                </form>

            </CadastroPublicidade>
        </Container >
    )
};

export default CdPublicidade;
