import * as React from 'react';
import { Botao, Cabecalho, Campos, ContainerForm, Formulario, InputLogin, SubtituloLogin } from "./styles";
import userContext from '../../Context/UserContext';
import { useContext, useEffect } from 'react';
import api from '../../service/api';
import { useToast } from '../../hooks/toast';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { LoginDadosDTO } from '../../DTOs/LogintDTO';

const Lancamento: React.FC = () => {
  const [logins, setLogins] = React.useState<LoginDadosDTO>();
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
  const currentlyUser = useContext(userContext);
  const { Codcliente, Codempresa } = currentlyUser.user;
  const { addToast } = useToast();
  const history = useHistory();
  const { id }: any = useParams();
  const isAddMode = !id;

  function AlterarASenha(senha: LoginDadosDTO) {
    api.put(`api/business/conta/alterasenha`, senha).then((response) => {
      addToast({
        type: 'success',
        title: 'Senha atualizada com sucesso!'
      });
    }).catch(error => {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar a senha!'
      });
    });
    history.push("/");
  }

  useEffect(() => {
    if (!isAddMode) {
      api.get(`api/configuracao/conta/${id}`).then((response) => {
        setValue("Senha", response.data["Senha"])
        setLogins(response.data);
      });
    }
  }, []);

  const onSubmit = (data: LoginDadosDTO) => {
    AlterarASenha(data);
  };

  function onChanged(ev: any) {
    const { name, value } = ev.target;
    setLogins({ ...logins, [name]: value })
  }

  return (
    <ContainerForm>
      <Formulario onSubmit={handleSubmit(onSubmit)}>
        <Cabecalho>
          <SubtituloLogin>Alterar Senha</SubtituloLogin>
        </Cabecalho>
        <Campos>
          <InputLogin>
            <input placeholder="Código Usuário" defaultValue={currentlyUser.user.Codacesso} type="hidden" {...register("codacesso", { onChange: onChanged })} />
          </InputLogin>
          <InputLogin>
            <input type="text" placeholder="Usuário" defaultValue={currentlyUser.user.Email} readOnly />
          </InputLogin>
          <InputLogin>
            <input type="password" placeholder="Nova senha" {...register("senha", { onChange: onChanged })} />
          </InputLogin>
          <InputLogin>
            {/* <input placeholder="Nova senha" defaultValue="" type="password" /> */}
          </InputLogin>
        </Campos>
        <Campos>
          <Botao type="submit">Alterar Senha</Botao>
          {currentlyUser.user.Codperfil != 1 ?
            <p>A senha alterado será do usuário logado, não do cliente selecionado!</p>
            : null}
        </Campos>
      </Formulario>
    </ContainerForm>
  )
};

export default Lancamento;
