import * as React from 'react';
import { ContainerFlutuante, Tabela, FormPesquisa } from './styles';
import api from '../../service/api';
import { useCallback, useContext, useEffect, useState } from 'react';
import userContext from '../../Context/UserContext';
import { ClienteDTO } from '../../DTOs/ClienteDTO';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botao';
import cartContext from '../../Context/CartContext';
import { UsuarioLogadoDTO } from '../../DTOs/UsuarioLogadoDTO';

const ListaClientesAdm: React.FC = () => {

  const [clientes, setClientes] = useState<ClienteDTO[]>([]);
  const currentlyCart = useContext(cartContext);
  const currentlyUser = useContext(userContext);
  const [itensPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(-1);
  const [currentItens, setCurrentItens] = useState<ClienteDTO[]>([]);
  const pages = Math.ceil(clientes.length / itensPerPage);
  const startIndex = currentPage * itensPerPage;
  const endIndex = startIndex + itensPerPage;

  function ConsultaClientes(filtro: any) {

    // Busca os pedidos da API
    api.post(`api/clientes`, filtro).then((response) => {
      const clientes = response.data;
      setClientes(clientes);
      setCurrentPage(0);
      setCurrentItens(clientes.slice(startIndex, endIndex));
    });
  }

  useEffect(() => {
    setCurrentItens(clientes.slice(startIndex, endIndex));
  }, [currentPage]);

  function Pesquisa(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    let txtfiltro = formData.get("txtfiltro") as string;

    if (txtfiltro != "") {
      var filtrocliente = {
        Codcliente: 0,
        Codvendedor: 0,
        Cgccpf: "",
        Nomcliente: txtfiltro
      }

      ConsultaClientes(filtrocliente);
    }

  }

  // function BuscaCarrinho(codcliente: number) {
  //   localStorage.removeItem('@VersaB2B:carrinho');
  //   currentlyCart.limpaCarrinho();

  //   api.get(`api/business/carrinho/${codcliente}`).then((response) => {

  //     // Desestrutura o objeto da API com somente os campos necessários na interface
  //     // e formata o valor para exibição
  //     const carrinho: CarrinhoDTO = response.data;


  //     // Seta os produtos no state para usar na página
  //     let vlrTotal = 0;

  //     for (let i = 0; i < carrinho.Produtos.length; i++) {
  //       // Lê o produto atual
  //       let produto = carrinho.Produtos[i];

  //       if (produto.Desconto == null) {
  //         produto.Desconto = 0;
  //       }
  //       // Calcula o total, multiplicando o valor do item pela quantidade dele no carrinho
  //       vlrTotal += produto.VlrTotal - (produto.VlrTotal * (produto.Desconto / 100));
  //       carrinho.Produtos[i].Src = process.env.REACT_APP_IMAGEMPATH + carrinho.Produtos[i].Src;
  //       carrinho.Produtos[i].VlrTotal = (produto.Vlr * produto.Qtd - (produto.Vlr * produto.Qtd * (produto.Desconto / 100)));
  //     }
  //     carrinho.VlrTotal = vlrTotal;

  //     currentlyCart.alteraCarrinho(carrinho);
  //   });

  // };

  const EscolheCliente = useCallback((cliente: ClienteDTO): void => {

    let usuarioCopia: UsuarioLogadoDTO = { ...currentlyUser.user };

    usuarioCopia.Codcliente = cliente.Codcliente;
    usuarioCopia.Nomcliente = cliente.Nomcliente;
    usuarioCopia.Codempresacli = cliente.Codempresacli;

    localStorage.setItem('@VersaB2B:login', JSON.stringify(usuarioCopia));
    currentlyUser.onLogin(usuarioCopia);
  }, [currentlyUser.user]);


  return (
    <ContainerFlutuante>
      <FormPesquisa onSubmit={Pesquisa}>
        <input type="text" id="iptBusca" placeholder="Pesquisar Clientes" title="Campo de busca." name="txtfiltro" />
        <Botao type="submit" containerStyle={{
          backgroundColor: "#ED3237", borderColor: "#fff",
          color: "#fff", borderWidth: 1, borderStyle: "solid",
          padding: 10, margin: 0, height: "inherit", fontWeight: "normal",
          fontSize: 16
        }}>Pesquisar</Botao>
      </FormPesquisa>
      {currentItens.length != 0 ?
        <Tabela>
          <thead>
            <tr>
              <th>Código</th>
              <th>Nome</th>
              <th>Cnpj</th>
            </tr>
          </thead>
          <tbody>
            {
              currentItens?.map((item: ClienteDTO, index: any) => {
                return (
                  <tr key={index}>
                    <td>{item.Codcliente}</td>
                    <td><Link to="/" onClick={() => EscolheCliente(item)} >{item.Nomcliente}</Link></td>
                    <td>{item.Cgccpf}</td>
                  </tr>
                )
              })}
          </tbody>
        </Tabela>
        : null}
      {/* {currentItens.length === 0 ? null : <Pagination pages={pages} setCurrentPaged={setCurrentPage} currentPage={currentPage} />} */}
    </ContainerFlutuante>
  )
};

export default ListaClientesAdm;

