import styled from 'styled-components';

export const Container = styled.div`
  justify-content: center;
`;

export const FormInputsQuantidade = styled.form`
  display: flex;
  margin: 0 auto;
  

  @media (min-width: 376px) and (max-width: 436px) {
    /* margin: 0 0 5px -1px; */
  }

  button {
    width: 15px;
    height: 15px;
    color: #787474;
    border: 1px solid #787474;
    font-size: 10px;
    font-weight: bold;

    /* @media (max-width: 375px) {
      width: 25px;
    } */

    /* @media (min-width: 376px) and (max-width: 436px) {
      width: 25px;
    } */

    @media (min-width: 1521px) {
      width: 25px;
      height: 25px;
    }

    @media (min-width: 1800px) {
      width: 25px;
    }
  }

  input {
    height: 15px;
    color: #787474;
    border: 1px solid #787474;
    text-align: center;
    font-size: 14px;
    width: 30px;

    /* @media (max-width: 375px) {
      width: 50px;
    }

    @media (min-width: 376px) and (max-width: 436px) {
      width: 65px;
    } */

    @media (min-width: 1521px) {
      height: 25px;
    }

    @media (min-width: 1800px) {
      width: 30px;
    }
  }

`;
