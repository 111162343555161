import { useEffect, useState } from "react";
import { BusinessProdutoDTO, CarrinhoProdutoDTO } from "../DTOs/produtoDTO";
import api from "../service/api";
import { ProdutoFiltro } from "../DTOs/ProdutoFiltro";
import produtoSemCapa from '../assets/img/no_image_large.jpg'
import { formatarValores } from "../helpers/valores";

const useProdutos = (filtro: ProdutoFiltro) => {
    const [produtos, setProdutos] = useState<CarrinhoProdutoDTO[]>([]);
    const [removeLoading, setRemoveLoading] = useState(false);
    // const [qtdTotalProdutos, setQtdTotalProdutos] = useState(0);

    useEffect(() => {
        api.post(`api/business/produto`, filtro).then((response) => {
            // Desestrutura o objeto da API com somente os campos necessários na interface
            // e formata o valor para exibição
            const produtosFormatados: CarrinhoProdutoDTO[] = response.data.map(
                ({
                    Codproduto, Nomproduto, Vlrvenda, Codautor, Nomautor, Codeditora, Nomeditora, Desconto, Qtdestoque,
                    QuantidadeEmEstoque, Pathimagem, Inddestaque, Indlancamento, Indprevenda, Dscsituacaoestoque }: BusinessProdutoDTO) => {
                    return {
                        CodProduto: Codproduto, Src: Pathimagem != null ? process.env.REACT_APP_IMAGEMPATH + Pathimagem : produtoSemCapa,
                        Titulo: Nomproduto, Vlr: Vlrvenda, Disponibilidade: "", CodEditora: Codeditora, NomEditora: Nomeditora, CodAutor: Codautor,
                        NomAutor: Nomautor, Desconto: Desconto, Qtdestoque: Qtdestoque, QuantidadeEmEstoque: QuantidadeEmEstoque, VlrFormatado: formatarValores(Vlrvenda),
                        Inddestaque: Inddestaque, Indlancamento: Indlancamento, Indprevenda: Indprevenda, Dscsituacaoestoque: Dscsituacaoestoque
                    };
                }
            );
            setProdutos(produtosFormatados);
            setRemoveLoading(true);
            // setQtdTotalProdutos(produtosFormatados.length);

        });

    }, []);
    return {produtos, setProdutos, removeLoading};
}

export default useProdutos;