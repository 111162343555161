import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useToast } from '../../../hooks/toast';
import { AdministradorDTO, ClienteDTO, ListaComboboxDTO, VendedorDTO } from "../../../DTOs/ListaComboboxDTO";
import { PerfilDTO } from "../../../DTOs/PefilDTO";
import { SolicitacaoAcessoDTO } from "../../../DTOs/SolicitacaoAcessoDTO";
import api from "../../../service/api";
import { Botao, Campos, InputLogin, LinksLogin, ListaRodapeLogin, RodapeLogin } from "../../Login/styles";
import userContext from "../../../Context/UserContext";
import { Container, Formulario, TituloSecao } from "../styles";

const AceitarAcesso: React.FC = () => {
  const { id }: any = useParams();
  const currentlyUser = useContext(userContext);
  const [usuarioAcesso, setusuarioAcesso] = useState<SolicitacaoAcessoDTO[]>();
  const [perfils, setPerfils] = useState<PerfilDTO[]>();
  const [codselecionado, setCodselecionado] = useState("");
  const [listacombobox, setListacombobox] = useState<ListaComboboxDTO>();
  let history = useHistory();
  const { addToast } = useToast();


  useEffect(() => {
    api.get(`/api/business/perfil`).then((response) => {

      // Desestrutura o objeto da API com somente os campos necessários na interface
      // e formata o valor para exibição
      const perfils: PerfilDTO[] = response.data;
      // Seta os produtos no state para usar na página
      setPerfils(perfils);

    });
  }, []);

  useEffect(() => {
    api.get(`/api/business/usuario/${id}`).then((response) => {

      // Desestrutura o objeto da API com somente os campos necessários na interface
      // e formata o valor para exibição
      const usuarioAcesso: SolicitacaoAcessoDTO[] = response.data;
      // Seta os produtos no state para usar na página
      setusuarioAcesso(usuarioAcesso);

      // // Seleciona o elemento input pelo id
      // let input = document.getElementById("txtcnpj") as HTMLInputElement;
      // // Adiciona um evento de input para aplicar a máscara
      // input.addEventListener("input", function () {
      //   // Atualiza o valor do input com a máscara
      //   input.value = mascaraCnpjCpf(input.value);
      // });
    });
  }, []);


  useEffect(() => {
    let cnpj = "";

    usuarioAcesso?.map((item: SolicitacaoAcessoDTO) => {
      cnpj = item.Cnpj;
    });

    setListacombobox({});

    switch (codselecionado) {
      case "1":
      case "4":

        var filtro = {
          Codcliente: 0,
          Codvendedor: 0,
          Cgccpf: cnpj,
          Nomcliente: ""
        }
        api.post(`api/clientes`, filtro).then((response) => {

          // Desestrutura o objeto da API com somente os campos necessários na interface
          // e formata o valor para exibição
          const clientes: ClienteDTO[] = response.data;
          // Seta os produtos no state para usar na página
          setListacombobox({
            Clientes: clientes
          });

        });
        break;
      case "2":
        api.get(`api/vendedor`).then((response) => {

          // Desestrutura o objeto da API com somente os campos necessários na interface
          // e formata o valor para exibição
          const vendedores: VendedorDTO[] = response.data;
          // Seta os produtos no state para usar na página
          setListacombobox({
            Vendedores: vendedores
          });

        });
        break;
      case "3":
        api.get(`api/usuario`).then((response) => {

          // Desestrutura o objeto da API com somente os campos necessários na interface
          // e formata o valor para exibição
          const administradores: AdministradorDTO[] = response.data;
          // Seta os produtos no state para usar na página
          setListacombobox({
            Administradores: administradores
          });
        });
        break;
    }
  }, [codselecionado]);

  function mascaraCnpjCpf(valor: string) {
    // Remove qualquer caractere que não seja um dígito
    valor = valor.replace(/\D/g, "");
    // Verifica o tamanho da string e aplica a formatação adequada
    if (valor.length <= 11) {
      // Formato de CPF: 999.999.999-99
      valor = valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    } else {
      // Formato de CNPJ: 99.999.999/9999-99
      valor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }
    return valor;
  }

  function AprovarAcesso(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);

    let codusuario = formData.get("txtcodusuario");
    let codusuarioversa = currentlyUser.user.Codusuarioversa;
    let codperfil = formData.get("cbxperfil") as string;
    let codselecionado = formData.get("cbxusuario") as string;

    var acesso = {
      Codusuario: codusuario,
      Codusuarioversa: codusuarioversa,
      Codperfil: codperfil,
      Codselecionado: codselecionado
    }

    api.post(`api/business/usuario/aprovaracesso`, acesso);

    addToast({
      type: 'success',
      title: 'Solicitação aceita com sucesso'
    });

    history.push("/solicitacaoacesso");

  }

  return (
    <Container>
      <TituloSecao>
        Aceitar Acesso
      </TituloSecao>
      {usuarioAcesso?.map((item: SolicitacaoAcessoDTO, index: any) => {
        return (
          <Formulario key={index} onSubmit={AprovarAcesso}>
            <Campos>
              <label htmlFor="txtnome">Nome:</label>
              <InputLogin>
                <input type="hidden" id="txtcodusuario" name="txtcodusuario" value={item.Codusuario} readOnly></input>
                <input type='text' id="txtnome" name="txtnome" value={item.Nome} readOnly />
              </InputLogin>
            </Campos>
            <Campos>
              <label htmlFor="txtddd">Ddd:</label>
              <InputLogin>

                <input type='text' id="txtddd" value={item.Ddd} readOnly />
              </InputLogin>
            </Campos>
            <Campos>
              <label htmlFor="txttelefone">Telefone:</label>
              <InputLogin>

                <input type='text' id="txttelefone" value={item.Telefone} readOnly />
              </InputLogin>
            </Campos>
            <Campos>
              <label htmlFor="txtempresa">Empresa:</label>
              <InputLogin>

                <input type='text' id="txtempresa" value={item.Empresa} readOnly />
              </InputLogin>
            </Campos>
            <Campos>
              <label htmlFor="txtcnpj">{item.Indcpf == 0 || item.Indcpf == null ? "CNPJ:" : "CPF:"}</label>
              <InputLogin>

                <input type='text' id="txtcnpj" value={mascaraCnpjCpf(item.Cnpj)} readOnly/>
              </InputLogin>
            </Campos>
            <Campos>
              <label htmlFor="txtemail">Email:</label>
              <InputLogin>

                <input type='text' id="txtemail" value={item.Email} readOnly />
              </InputLogin>
            </Campos>
            <Campos>
              <label htmlFor="cbxperfil">Perfil:</label>
              <InputLogin>

                <select name="cbxperfil" id="cbxperfil" onChange={event => setCodselecionado(event.target.value)} >
                  <option id="0">Selecione uma opção</option>
                  {
                    perfils?.map((perfil: PerfilDTO) => {
                      return (
                        <option id={`${perfil.Codperfil}`} key={perfil.Codperfil} value={perfil.Codperfil}>{perfil.Dscperfil}</option>
                      )
                    })}
                </select>
              </InputLogin>
            </Campos>
            <Campos>
              <label htmlFor="cbxusuario">Usuario:</label>
              <InputLogin>

                {listacombobox ?

                  <select id="cbxusuario" name="cbxusuario">
                    {listacombobox.Administradores?.map((dadoscombo: AdministradorDTO) => {
                      return (
                        <option id={`${dadoscombo.Codusuario}`} key={dadoscombo.Codusuario} value={dadoscombo.Codusuario}>{dadoscombo.Nomusuario}</option>
                      )
                    })}
                    {listacombobox.Clientes?.map((dadoscombo: ClienteDTO) => {
                      return (
                        <option id={`${dadoscombo.Codcliente}`} key={dadoscombo.Codcliente} value={dadoscombo.Codcliente}>{dadoscombo.Nomcliente}</option>
                      )
                    })}
                    {listacombobox.Vendedores?.map((dadoscombo: VendedorDTO) => {
                      return (
                        <option id={`${dadoscombo.Codvendedor}`} key={dadoscombo.Codvendedor} value={dadoscombo.Codvendedor}>{dadoscombo.Nomvendedor}</option>
                      )
                    })}
                  </select> : <span>erro</span>}
              </InputLogin>
            </Campos>
            <Campos>
              <Botao type="submit">Gravar</Botao>
            </Campos>
            <RodapeLogin>
              <ListaRodapeLogin>
                <LinksLogin to="/solicitacaoacesso"><span>Voltar</span></LinksLogin>
              </ListaRodapeLogin>
            </RodapeLogin>

          </Formulario>
        )
      })}
    </Container>
  );
}

export default AceitarAcesso;

