import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  color: #000000;
  top: 100px;
  margin-bottom: 4rem;
  margin-top: 3rem;

  @media (max-width: 325px) {
    max-width: 300px;
  }
`;

export const ListaEditora = styled.div`
  display: flex;
  margin: 0 auto;
  justify-content:  space-between;
  width: 100%;
  flex-wrap: wrap;
  /* max-width: 2560px; */
  align-items:center;
`;

export const TituloSecao = styled.h3`
  padding: 20px;
  color: #313131;
  font-size: 26px;
  text-align: left;
  margin-bottom: 12px;
`;

export const LinkEditora = styled(Link)`
    color: black;
    text-decoration: none;
    margin: 0px 5px; 
    font-weight: bold;

    &:hover{
      text-decoration: underline;
    }
`;

export const ContainerCabecalho = styled.div`
  display: flex;
`;

export const FormPesquisa = styled.form`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`;

