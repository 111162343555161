import * as React from 'react';
import { Container, TituloSecao } from './styles';
import userContext from '../../Context/UserContext';
import { useContext } from 'react';
import Grid from '../../componentes/Grid';
import { useParams } from 'react-router-dom';

const Lancamento = ( 

  ) => {
  const currentlyUser = useContext(userContext);
  const {Codcliente, Codempresa} = currentlyUser.user;

  return (
    <Container>
      <TituloSecao>
        Lançamentos
      </TituloSecao>
        <Grid Lancamento={1} Codcliente={Codcliente} Codempresacli={Codempresa} />
    </Container>
  )
};

export default Lancamento;
