import styled, { css } from "styled-components";

interface CarrinhoProps {
  isEmpty: boolean;
}

export const Container = styled.div<CarrinhoProps>`
  width: 100%;
  margin: 25px auto 0 auto;
  padding: 1.5rem;
  display: grid;
  column-gap: 2rem;
  grid-template-rows: repeat(4, min-content);
  grid-auto-flow: dense;

  // Altura padrão do carrinho é 50% do viewport do dispositivo
  height: 50vh;

  @media (max-width: 923px) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  // Verificamos as props passadas pra ele
  ${(props) => {
    return (
      // Se não estiver vazio o carrinho, essa prop é falsa, então o height
      // da tela ocupará 100% do seu conteúdo
      !props.isEmpty &&
      css`
        height: 100%;
      `
    );
  }}/* @media(max-width: 923px){
    padding: 0;
    margin: 0;
  } */
`;

export const CarrinhoTitulo = styled.h2`
  margin-bottom: 0.5rem;
  color: black;
  font-size: 26px;
  padding: 20px;

  @media (max-width: 923px) {
    display: flex;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 923px) {
    display: none;
  }
`;

export const Subtotal = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 18px;

  .subtotal {
    padding: 0 20px;
    margin-right: 20px;
    border-top: 2px solid rgb(233, 233, 233);
  }


  th {
    margin-left: 10px;
    text-align: left;
    padding: 10px 20px;
  }

  td{
    margin-left: 10px;
    text-align: left;

    padding: 10px 20px;

  }

  .th-qtd{
    text-align: right;
  }
  p {
    font-size: 20px;
    margin-top: 10px;
  }

  @media (max-width: 923px) {
    display: none;
  }
`;

export const CarrinhoBody = styled.div`
  display: grid;
  padding: 0 20px;
  width: 100%;
`;

export const CarrinhoBodyHeader = styled.div`
  grid-template-columns: minmax(400px, 1fr) 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 0.5rem;
  -webkit-box-align: center;
  align-items: center;
  column-gap: 1.5rem;
  display: grid;
  grid-column: 1 / auto;
  border-bottom: 2px solid rgb(233, 233, 233);

  h3 {
    color: rgb(74, 74, 74);
    margin: 0;
    align-items: center;
  }

  h3:nth-child(n + 2) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 923px) {
    display: none;
  }
`;

interface ICarrinhoBodyItens {
color: string;
}
export const CarrinhoBodyItens = styled.div<ICarrinhoBodyItens>`
  grid-template-columns: minmax(400px, 1fr) 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 0.5rem;
  -webkit-box-align: left;
  align-items: left;
  column-gap: 1.5rem;
  display: grid;
  grid-column: 1 / auto;

  border-bottom: 1px solid rgb(233, 233);
  color: ${props => props.color};

  a {
    color: inherit;
  }

  @media (max-width: 923px) {
    display: none;
  }
`;

export const CarrinhoBodyImagemNomeProduto = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  h3 {
    font-size: 16px;
  }

  @media (max-width: 923px) {
    display: none;
  }
`;

export const CarrinhoBodyImagemNomeProdutoLinkImagem = styled.div`
  display: flex;
  justify-content: center;
  width: 115px;
  height: 110px;

  div {
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  @media (max-width: 923px) {
    display: none;
  }
`;

export const CarrinhoBodyImagemNomeProdutoLinkNome = styled.div`
  width: 100%;
  padding: 10px;

  @media (max-width: 923px) {
    display: none;
  }
`;

export const CarrinhoBodyItensValores = styled.h3`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 923px) {
    display: none;
  }
`;

export const BtnRemoverProduto = styled.h3`
  display: flex;
  align-items: center;

  svg {
    color: black;
    width: 20px !important;
    height: 20px;
    margin-right: -10%;
    margin-left: 5%;
    cursor: pointer;
  }
`;

export const CarrinhoBodyItensQuantidades = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 50px;
    height: 50px;
    color: #787474;
    border: 2px solid #787474;
    font-size: 20px;
    font-weight: bold;

    @media (max-width: 375px) {
      width: 32px;
    }

    @media (min-width: 376px) and (max-width: 436px) {
      width: 37px;
    }
  }

  @media (max-width: 923px) {
    display: none;
  }
`;

export const CarrinhoFooter = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: space-evenly;
  height: -moz-fit-content;

  @media (max-width: 923px) {
    display: none;
  }
`;

export const CarrinhoDesktop = styled.div``;

export const CarrinhoResponsivo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 13px;

  .card-produto {
    display: flex;
    margin-bottom: 10px;
    max-height: 130px;
    font-size: 12px;
  }
  .produto-titulo {
    font-weight: bold;
    padding: 10px;
  }

  .titulo-carrinho {
    height: 50px;
    font-size: 25px;
    font-weight: bold;
    display: flex;
    justify-content: space-around;
  }

  .btn-finalizar {
    padding: 10px;
    margin-bottom: 10px;
  }

  .valores-carrinho {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-around;
    align-items: center;
  }
  @media (min-width: 923px) {
    display: none;
  }

  @media (max-width: 923px) {
    .imagem-produto {
      display: flex;
      flex-direction: column;
      margin: 0;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }

    .imagem-carrinho {
      display: flex;
    }

    .container-imagem {
      height: 130px;
      width: 100px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    .informacoes-carrinho {
      display: flex;
      flex-direction: column;
    }

    .vlrUnitario {
    }

    .desconto {
    }

    .titulo-livro {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .contador {
      display: flex;
    }

    .produto-carrinho {
      display: flex;
      flex-direction: row;
      height: auto;
      margin-bottom: 10px;
      width: 100%;
    }

    /* .valores-carrinho {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      margin-top: 10px;
    } */

    .desconto {
      margin: 0;
    }

    .contador {
      margin-bottom: 10px;
    }

    .remover-produto {
      margin: 0;
    }

    .finalizarResponsivo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
`;
