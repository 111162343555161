import React, { createContext } from "react";

 interface PinDTO{
    pinToggle?: boolean,
    carrinhoToggle?: boolean
 };

type PinContextType = {
    pin: PinDTO,
    ativarCarrinhoPin: () => void,
    desativarCarrinhoPin: () => void,
    mostrarCarrinho: () => void,
    desativaCarrinho: () => void

}

export const pinInitial = {
    pinToggle: false,
    carrinhoToggle: false
}

const pinContext = createContext<PinContextType>({ pin: pinInitial, ativarCarrinhoPin: () => {}, desativarCarrinhoPin: () => {}, mostrarCarrinho: () => {}, desativaCarrinho:() => {}});

export default pinContext;