import * as React from 'react';
import { MinhaConta } from './main';
import { useContext, useEffect, useState } from 'react';
import { PedidoItemDTO, PedidosDTO } from '../../DTOs/PedidoDTO';
import api from '../../service/api';
import { formatarValores } from '../../helpers/valores';
import userContext from '../../Context/UserContext';
import { Link } from 'react-router-dom';

function PedidoDetalhes(props: any) {

    const [produtos, setProdutos] = useState<PedidoItemDTO[]>([]);
    const currentlyUser = useContext(userContext);

    useEffect(() => {
        api.get(`api/pedidos/itens/${props.Codacerto}`).then((response) => {
            const produtos = response.data;
            setProdutos(produtos);
        });
    }, [props.Codacerto]);

    return (
            <thead>
            <tr className='trItens'>
                <th className='coluna'>PRODUTO</th>
                <th></th>
                <th></th>
                <th className='coluna'>QTDE.</th>
                <th className='coluna'>PREÇO</th>
                <th className='coluna'>DESCONTO</th>
                <th className='coluna'>TOTAL</th>
            </tr>
            {produtos?.map((produto: PedidoItemDTO, index: any) => {
                return (
                    <tr key={index}>
                        <td><img src={produto.Pathimagem} alt="" className="livro" /></td>
                        <td className='testee'>
                            <Link to={`/produtos/${produto.Codproduto}/detalhes`}>{produto.Nomproduto}</Link></td>
                        <td></td>
                        <td className='valorItem'>{produto.Qtdlivros}</td>
                        <td className='valorItem'>{formatarValores(produto.Vlrvenda)}</td>
                        <td className='valorItem'>{produto.Desconto}%</td>
                        <td className='valorItem'>{formatarValores(produto.Vlrtotal)}</td>
                    </tr>
                )
            })
            }
            </thead>
    )
};

export default PedidoDetalhes;
