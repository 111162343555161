import React from 'react';

import {
  Container, BodyModal, TituloModal, TextoModal,
  BotoesModalContainer, BotoesModal
} from './styles';

interface ModalConfirmacaoProps {
  tituloModal: string
  textoModal: string
  opcaoSelecionada: (opcao: string) => void
}

const ModalConfirmacao: React.FC<ModalConfirmacaoProps> = ({ tituloModal, textoModal, opcaoSelecionada }) => {

  const handleClickNao = () => {
    opcaoSelecionada("N");
  }
  const handleClickSim = () => {
    opcaoSelecionada("S");
  }

  return (
    <Container>
      <TituloModal>
        {tituloModal}
      </TituloModal>

      <BodyModal>

        <TextoModal>
          {textoModal}
        </TextoModal>

        <BotoesModalContainer>
          <BotoesModal onClick={() => handleClickSim()}>Sim</BotoesModal>
          <BotoesModal onClick={() => handleClickNao()}>Não</BotoesModal>
        </BotoesModalContainer>
      </BodyModal>
    </Container>
  )
};

export default ModalConfirmacao;
