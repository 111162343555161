import * as React from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../service/api';
import { useToast } from '../../../hooks/toast';
import { Container, TituloSecao } from '../styles';
import { PagLayout } from './styles';
import { LayoutDTO } from '../../../DTOs/LayoutDTO';

const LayoutCores: React.FC = () => {
    const [layout, setLayout] = useState<LayoutDTO>();
    const { addToast } = useToast();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<LayoutDTO>();
    let history = useHistory();

    useEffect(() => {
        api.get(`api/configuracao/getconfiguracoes`).then((response) => {
            const layout: LayoutDTO = response.data;
            setValue("Cores", layout["Cores"]);
            setValue("Social", layout["Social"]);
            setValue("Logotipo", layout["Logotipo"]);

        });
    }, []);

    function SalvarCoresLayout(corSite: LayoutDTO) {
        api.post(`api/configuracao/socialmedia`, corSite).then((response) => {
            setValue("Cores.HeaderBackground", response.data["HeaderBackground"])
            setValue("Social.Whatsapp", response.data["Whatsapp"])
            addToast({
                type: 'success',
                title: 'Cores do site criada com sucesso'
            });
            window.location.reload();
        }).catch(error => {
            addToast({
                type: 'error',
                title: 'Erro ao criar cores do site'
            });
        });

        history.push("/configuracao/");
    }

    const OnSubmit = (data: any) => {
        const coresSite: LayoutDTO = data;
        SalvarCoresLayout(coresSite);
    };

    function onChanged(ev: any) {
        const { name, value } = ev.target;
        setLayout({ ...layout, [name]: value })
    }

    return (
        <Container>
            <TituloSecao>
                Edição de Cores
            </TituloSecao>

            <PagLayout>

                <form onSubmit={handleSubmit(OnSubmit)}>
                    <div className='layoutCores' >
                        <div className='campoTodo'>
                            <h3 className='tituloPrincipal'>Cores Header</h3>
                            <div className='coresCampo'>
                                <div className='campo'>
                                    <label>Cor Primária</label>
                                    <div>
                                        <input type="color" className='cor'{...register("Cores.HeaderBackground", { required: false })} />
                                    </div>
                                </div>

                                <div className='campo'>
                                    <label>Cor dos Textos</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.HeaderTextos", { required: false })} />
                                    </div>
                                </div>

                                <div className='campo'>
                                    <label>Cor dos Icones</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.HeaderIcons", { required: false })} />
                                    </div>
                                </div>

                                <div className='campo'>
                                    <label>Cor do Botão Pesquisar</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.HeaderButton", { required: false })} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='campoTodo'>
                            <h3 className='tituloPrincipal'>Cores Menu</h3>
                            <div className='coresCampo'>
                                <div className='campo'>
                                    <label>Cor Primária</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.MenuBackground", { required: false })} />
                                    </div>
                                </div>

                                <div className='campo'>
                                    <label>Cor do Submenu</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.SecondaryMenuBackground", { required: false })} />
                                    </div>
                                </div>

                                <div className='campo'>
                                    <label>Cor dos Textos</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.MenuTextos", { required: false })} />
                                    </div>
                                </div>

                                <div className='campo'>
                                    <label>Cor Hover</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.MenuHover", { required: false })} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='campoTodo'>
                            <h3 className='tituloPrincipal'>Cores Cards</h3>
                            <div className='coresCampo'>
                                <div className='campo'>
                                    <label>Cor Adicionar ao Carrinho</label>
                                    <div>
                                        <input type="color" className='cor'{...register("Cores.CardButton", { required: false })} />
                                    </div>
                                </div>
                                
                                <div className='campo'>
                                    <label>Cor Tag Descontos</label>
                                    <div>
                                        <input type="color" className='cor'{...register("Cores.CardDesconto", { required: false })} />
                                    </div>
                                </div>

                                <div className='campo'>
                                    <label>Cor Tag Lançamentos</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.CardLancamentos", { required: false })} />
                                    </div>
                                </div>

                                <div className='campo'>
                                    <label>Cor Tag Mais Vendidos</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.CardMaisVendidos", { required: false })} />
                                    </div>
                                </div>

                                <div className='campo'>
                                    <label>Cor Tag Pré-Venda </label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.CardPreVenda", { required: false })} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='campoTodo'>
                            <h3 className='tituloPrincipal'>Cores Footer</h3>
                            <div className='coresCampo'>
                                <div className='campo'>
                                    <label>Cor Primária</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.FooterBackground", { required: false })} />
                                    </div>
                                </div>

                                <div className='campo'>
                                    <label>Cor dos Textos</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.FooterTextos", { required: false })} />
                                    </div>
                                </div>

                                <div className='campo'>
                                    <label>Cor dos Links</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.FooterLinks", { required: false })} />
                                    </div>
                                </div>

                                {/* <div className='campo'>
                                    <label>Cor dos Icones</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.FooterIcons", { required: false })} />
                                    </div>
                                </div> */}

                                <div className='campo'>
                                    <label>Cor do Botão</label>
                                    <div>
                                        <input type="color" className='cor' {...register("Cores.FooterButton", { required: false })} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='campoTodo'>
                            <h3 className='tituloPrincipal'>Mídias Sociais</h3>
                            <div className='midiaCampo'>
                                <div className='campo'>
                                    <label>Título da seção</label>
                                    <div>
                                        <input type="text" className='cor' {...register("Social.TituloSecao", { required: false })} />
                                    </div>
                                </div>
                                <div className='campo'>
                                    <label>WhatsApp</label>
                                    <div>
                                        <input type="text" className='cor' {...register("Social.Whatsapp", { required: false, onChange: onChanged })} />
                                    </div>
                                </div>

                                <div className='campo'>
                                    <label>Facebook</label>
                                    <div>
                                        <input type="text" className='cor' {...register("Social.Facebook", { required: false })} />
                                    </div>
                                </div>

                                <div className='campo'>
                                    <label>Instagram</label>
                                    <div>
                                        <input type="text" className='cor' {...register("Social.Instagram", { required: false })} />
                                    </div>
                                </div>
                                
                                <div className='botoesFiltros'>
                                    <button type="submit" className="btn-novo"> Salvar</button>
                                </div>

                            </div>

                        </div>
                    </div>
                </form>

            </PagLayout>
        </Container >
    )
};

export default LayoutCores;

