import * as React from 'react';
import { Container, TituloSecao } from './styles';
import Grid from '../../componentes/Grid';
import { useContext } from 'react';
import userContext from '../../Context/UserContext';

const Promocoes: React.FC = () => {
  const currentlyUser = useContext(userContext);
  const {Codcliente, Codempresacli} = currentlyUser.user;
  return (
    <Container>
      <TituloSecao>
        Promoções
      </TituloSecao>
        <Grid Promocoes={1} Codcliente={Codcliente} Codempresacli={Codempresacli}/>
    </Container>
  )
};

export default Promocoes;
