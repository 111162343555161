import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useToast } from '../../hooks/toast';
import { Botao, Cabecalho, Campos, ContainerForm, Formulario, InputLogin, InputRadioLogin, LinksLogin, ListaRodapeLogin, Radio, RodapeLogin, SubtituloLogin } from "./styles";
import api from '../../service/api';
import { useHistory } from "react-router-dom";

interface UsuarioDTO {
  nome: string;
  ddd: string;
  telefone: string;
  empresa: string;
  indcpf: string;
  cnpj: string;
  email: string;
  senha: string;
  confirmasenha: string;
}

const CriarConta = () => {
  const { addToast } = useToast();
  const { register, handleSubmit, formState: { errors } } = useForm<UsuarioDTO>();
  const [indcpf, setIndcpf] = useState(0);
  let history = useHistory();

  const formatarCampoCNPJCPF = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.replace(/[a-zA-Z]/g, "");

    if (indcpf === 0) {
      event.target.value = event.target.value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
    } else {
      event.target.value = event.target.value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
    }
  };

  const retirarFormatacaoCNPJCPF = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.replace(/(\.|\/|\-)/g, "");
  };

  const alternaCNPJCPF = (indcpf: number) => {
    let campo = document.getElementById("cnpj") as HTMLInputElement;
    campo.value = "";

    setIndcpf(indcpf);

  };

  const salvarCadastro = (acessoLogin: UsuarioDTO) => {

    api.post(`api/business/usuario/solicitaracesso`, acessoLogin).then(() => {
      addToast({
        type: 'info',
        title: 'Conta Registrada',
        description: "Aguarde um administrador aprovar sua solicitação!"
      });
    }).catch(() => {
      addToast({
        type: 'error',
        title: 'Erro ao criar cadastro!'
      });
    });

    history.push("/login");
  };

  const onSubmit = (data: UsuarioDTO) => {
    salvarCadastro(data);
  };

  return (
    <ContainerForm>
      <Formulario onSubmit={handleSubmit(onSubmit)}>
        <Cabecalho>
          <SubtituloLogin><b>Criar a sua conta</b></SubtituloLogin>
        </Cabecalho>
        <Campos>
          <InputLogin>
            <input placeholder="Nome" defaultValue="" type="text" required {...register("nome", { required: true })} />
            {errors.nome && <span>Este campo é obrigatório.</span>}
          </InputLogin>
        </Campos>
        <Campos>
          <InputLogin>
            <input placeholder="DDD" defaultValue="" type="text" required maxLength={2} minLength={2} {...register("ddd", { required: true })} style={{ width: "30%", margin: "0 5px 0 0" }} />
            {errors.ddd && <span>Este campo é obrigatório.</span>}
            <input placeholder="Telefone" defaultValue="" type="text" maxLength={9} minLength={8} required {...register("telefone", { required: true })} />
            {errors.telefone && <span>Este campo é obrigatório.</span>}
          </InputLogin>
        </Campos>
        <Campos>
          <InputLogin>
            <input placeholder="Empresa" defaultValue="" type="text" required={indcpf == 0 ? true : false} {...register("empresa")} />
            {errors.empresa && <span>Este campo é obrigatório.</span>}
          </InputLogin>
        </Campos>

        <Campos>
          <InputLogin>
            <select id="indcpf"  {...register("indcpf", { required: true })} onChange={event => alternaCNPJCPF(parseInt(event.target.value))}>
              <option id="indcpf" value={0}>CNPJ</option>
              <option id="indcpf" value={1}>CPF</option>
            </select>
          </InputLogin>
        </Campos>
        <Campos>
          <InputLogin>
            <input
              id="cnpj"
              placeholder={indcpf === 0 ? "CNPJ" : "CPF"}
              minLength={indcpf === 0 ? 14 : 11}
              maxLength={indcpf === 0 ? 14 : 11}
              {...register("cnpj", { required: true })}
              onFocus={retirarFormatacaoCNPJCPF}
              onBlur={formatarCampoCNPJCPF}
            />
            {errors.cnpj && <span>Este campo é obrigatório.</span>}

          </InputLogin>

        </Campos>
        <Campos>
          <InputLogin>
            <input placeholder="Email" defaultValue="" type="email" required {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
            })} />
            {errors.email?.type === "pattern" && <span>Por favor, insira um endereço de email válido.</span>}
            {errors.email?.type === "required" && <span>Este campo é obrigatório.</span>}

          </InputLogin>
        </Campos>
        <Campos>
          <InputLogin>
            <input placeholder="Senha" defaultValue="" type="password" required {...register("senha", { required: true })} />
            {errors.senha && <span>Este campo é obrigatório.</span>}
          </InputLogin>
        </Campos>
        <Campos>
          <InputLogin>
            <input placeholder="Confirmar senha" defaultValue="" type="password" required {...register("confirmasenha", { required: true })} />
            {errors.confirmasenha && <span>Este campo é obrigatório.</span>}
          </InputLogin>
        </Campos>
        <Campos>
          <Botao type="submit">Registre-se</Botao>
          <p className='infos'>Após o envio desse formulário, a requisição será analisada pelo administrador.</p>
          {/* <p className='infos'>A senha deve ter no mínimo 6 caracteres</p> */}
        </Campos>
        <RodapeLogin>
          <ListaRodapeLogin>
            <LinksLogin to="/login"><span>Já possui uma conta? Entrar</span></LinksLogin>
          </ListaRodapeLogin>
        </RodapeLogin>
      </Formulario>
    </ContainerForm>
  );
}

export default CriarConta;