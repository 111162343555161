import * as React from 'react';
import { useForm } from "react-hook-form";
import { Container, PagLinksNovo, TituloSecao } from '../styles';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../service/api';
import { PaginaEstaticaDTO } from '../../../DTOs/PaginaEstaticaDTO';
import { useEffect } from 'react';
import { useToast } from '../../../hooks/toast';

const CdPaginaEstatica: React.FC = () => {
    const { id }: any = useParams();
    const isAddMode = !id;
    const { addToast } = useToast();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<PaginaEstaticaDTO>();
    let history = useHistory();

    useEffect(() => {
        if (!isAddMode) {
            api.get(`api/configuracao/paginaestatica/${id}`).then((response) => {
                setValue("Codpagina", response.data["Codpagina"])
                setValue("Nompagina", response.data["Nompagina"])
                setValue("Url", response.data["Url"])
                setValue("Conteudo", response.data["Conteudo"])   
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao carregar página estática'
                });
            });
        }
    }, []);

    function SalvaPaginaEstatica(paginaEstatica: PaginaEstaticaDTO) {

        if (id != null) {
            api.put(`api/configuracao/paginaestatica/cdpaginaestatica/${id}`, paginaEstatica).then((response) => {
                addToast({
                    type: 'success',
                    title: 'Página estática atualizada com sucesso'
                });
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao atualizar página estática'
                });
            });

        } else {
            api.post(`api/configuracao/paginaestatica/cdpaginaestatica`, paginaEstatica).then((response) => {
                addToast({
                    type: 'success',
                    title: 'Página estática criada com sucesso'
                });
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao criar página estática'
                });
            });
        }
        history.push("/configuracao/paginasestaticas");
    }

    const OnSubmit = (data: any) => {
        const paginaEstatica: PaginaEstaticaDTO = data;
        SalvaPaginaEstatica(paginaEstatica);
    };

    return (
        <Container>
            <TituloSecao>
                Cadastro Página Estática
            </TituloSecao>
            <PagLinksNovo>
                <form onSubmit={handleSubmit(OnSubmit)}>
                    <div className="codCampo">
                        <p> Código</p>
                        <input type="text" className='inputTextNome' placeholder="Codigo" disabled={true} {...register("Codpagina", { required: false, maxLength: 80 })} />
                    </div>
                    <div className="nomePagina">
                        <div className="nomCampo">
                            <p>Nome</p>
                            <input type="text" className='inputTextNome' placeholder="Nome" {...register("Nompagina", { required: false, maxLength: 100 })} />
                        </div>
                    </div>
                    <div className="codCampo">
                        <p>Url</p>
                        <input type="text" placeholder="Url" className='inputUrl' {...register("Url", { required: false })} />
                    </div>
                    <div className="codCampo">
                        <p>Conteudo</p>
                        <input type="text" className='inputUrl'{...register("Conteudo", { required: false })} />
                    </div>
                    {/* <div className="codCampo">
                        <p>Apresentar na Principal</p>
                        <input type="checkbox" placeholder="Apresentar na Principal" {...register("Indapresentapaginaprincipal", {required: false})} />
                    </div> */}

                    <div className='botoesFiltros'>
                        <button type="submit" className="btn-novo"> Salvar</button>
                    </div>
                </form>
            </PagLinksNovo>
        </Container >
    )
};

export default CdPaginaEstatica;
