import styled from "styled-components";

export const Container = styled.div`
  color: #000000;
  top: 100px;
  margin-bottom: 4rem;
  margin-top: 3rem;

  @media (max-width: 325px) {
    max-width: 300px;
  }
`;

export const TituloSecao = styled.h3`
  padding: 20px;
  color: #313131;
  font-size: 26px;
  text-align: left;
  margin-bottom: 12px;
  text-align: center;
`;

export const ContainerLogin = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Verdana, Arial, sans-serif;
  font-size: 14px;
  color: #555555;
  height: 100%;
`;

export const ContainerForm = styled(Container)`
  height: 720px;
  width: 100%;
  background-size: cover;
  background-color: #fafbfc;
  margin-top: 50px;

  @media (max-width: 923px) {
    height: 100%;
  }
`;

export const ContainerFlutuante = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px auto 24px;
  width: 800px;
  padding: 32px 40px;
  background: rgb(255, 255, 255);
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
  box-sizing: border-box;
  color: rgb(94, 108, 132);

  @media (max-width: 923px) {
    width: 100%;
    padding: 10px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

export const Tabela = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  margin-bottom: 10px;

  td,
  th {
    border: 1px solid #000000;
    padding: 3px 10px;
  }

  thead {
    background-color: var(--tabelas-bg-color);
  }

  thead th {
    color: #ffff;
    text-align: center;
    padding: 3px 10px;
  }

  tbody tr:nth-child(odd) {
    background: #ffffff;
  }
  tbody tr:nth-child(even) {
    background: #f3f3f3;
  }

  .icones,
  a {
    text-align: center;
    color: black;
  }

  .icones:hover,
  a:hover {
    color: #423e3e;
    cursor: pointer;
  }
`;

export const Formulario = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0px auto 24px;
  width: 400px;
  padding: 32px 40px;
  background: rgb(255, 255, 255);
  border-radius: 3px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
  box-sizing: border-box;
  color: rgb(94, 108, 132);

  @media (max-width: 923px) {
    width: 100%;
    padding: 10px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`;

export const FormPesquisa = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;

  input {
    padding: 10px;
    width: 100%;
  }
`;
