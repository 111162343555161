import * as React from 'react';
import { useForm } from "react-hook-form";
import { CadastroPublicidade, CadastroPublicidadeModelo, Container, TituloSecao } from '../styles';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../service/api';
import { useEffect, useState } from 'react';
import { useToast } from '../../../hooks/toast';
import { Publicidade, PublicidadeModeloDTO } from '../../../DTOs/PublicidadeModeloDTO';

const CdPublicidadeModelo: React.FC = () => {
    const { addToast } = useToast();
    const { id }: any = useParams();
    const [publicidadeModelo, setPublicidadeModelo] = useState<PublicidadeModeloDTO>();
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const isAddMode = !id;
    let history = useHistory();

    function SalvarPublicidadeModelo(publicidadeModelo: PublicidadeModeloDTO) {

        if (id != null) {
            api.put(`api/business/publicidademodelo/${id}`, publicidadeModelo).then((response) => {
                addToast({
                    type: 'success',
                    title: 'Publicidade atualizada com sucesso'
                });
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao atualizar publicidade'
                });
            });

        } else {
            api.post(`api/business/publicidademodelo`, publicidadeModelo).then((response) => {
                addToast({
                    type: 'success',
                    title: 'Publicidade criada com sucesso'
                });
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao criar publicidade'
                });
            });
        }
        history.push("/configuracao/publicidade/lstpublicidademodelo");
    }

    const OnSubmit = (data: any) => {
        const publicidadeModelo: PublicidadeModeloDTO = data;
        SalvarPublicidadeModelo(publicidadeModelo);
    };


    useEffect(() => {
        if (!isAddMode) {

            api.get(`api/business/publicidademodelo/${id}`).then((response) => {
                const fields = new Array("Codpublicidademodelo", "Dscpublicidademodelo", "Largura", "Altura");
                setValue("Codpublicidademodelo", response.data["Codpublicidademodelo"])
                setValue("Dscpublicidademodelo", response.data["Dscpublicidademodelo"])
                setValue("Largura", response.data["Largura"])
                setValue("Altura", response.data["Altura"])
                setPublicidadeModelo(response.data);
            });
        }
    }, []);

    function onChanged(ev: any) {
        const { name, value } = ev.target;
        setPublicidadeModelo({ ...publicidadeModelo, [name]: value })
    }

    return (
        <Container>
            <TituloSecao>
                Cadastro de Modelos de Publicidade
            </TituloSecao>

            <CadastroPublicidadeModelo>
                <form onSubmit={handleSubmit(OnSubmit)}>
                    <div className="codCampo">
                        <p hidden={publicidadeModelo ? false : true}>Código</p>
                        <input type="text" className='inputTextCodigo' placeholder="Codigo" hidden={publicidadeModelo ? false : true}  {...register("Codpublicidademodelo", { required: false, maxLength: 80, value: publicidadeModelo?.Codpublicidademodelo, disabled: true })} />
                    </div>

                    <div className="campoDescricao">
                        <p>Descrição</p>
                        <input type="text" className='inputDescricao' placeholder="Descrição" {...register("Dscpublicidademodelo", { required: false, maxLength: 100, value: publicidadeModelo?.Dscpublicidademodelo, onChange: onChanged })} />
                    </div>

                    <div className="larguraAltura">
                        <div className='campoLarguraAltura'>
                            <p>Largura</p>
                            <input type="text" className='inputLarguraAltura' placeholder="Largura" {...register("Largura", { required: false, maxLength: 100, value: publicidadeModelo?.Largura, onChange: onChanged })} />
                        </div>

                        <div className='campoLarguraAltura'>
                            <p>Altura</p>
                            <input type="text" className='inputLarguraAltura' placeholder="Altura" {...register("Altura", { required: false, maxLength: 100, value: publicidadeModelo?.Altura, onChange: onChanged })} />
                        </div>
                    </div>

                    {/* <div className="codCampo">
                        <p>Conteudo</p>
                        <input type="text" className='inputUrl'{...register("Conteudo", { required: false, value: publicidade?.Conteudo, onChange: onChanged })} />
                    </div>
                    <div className="codCampo">
                        <p>Apresentar na Principal</p>
                        <input type="checkbox" placeholder="Apresentar na Principal" {...register("Indapresentapaginaprincipal", {required: false})} />
                    </div> */}

                    <div className='botoesFiltros'>
                        <button type="submit" className="btn-novo"> Salvar</button>
                    </div>
                </form>

            </CadastroPublicidadeModelo>
        </Container >
    )
};

export default CdPublicidadeModelo;
