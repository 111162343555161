import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  CabecalhoBuscaLinha, Content, CabecalhoLogoImagem, CabecalhoUsuario, Dropdow,
  CabecalhoUsuarioDeslogado, MenuDesktop, CabecalhoLogo, CabecalhoBusca, Container
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faKey, faPeopleArrows, faShoppingCart, faSignOut, faUser, faUserClock} from '@fortawesome/free-solid-svg-icons'
import { faSquareCheck, faSquare } from '@fortawesome/free-regular-svg-icons';
import Botao from '../Botao';
import { useContext, useEffect, useState } from 'react';
import userContext from '../../Context/UserContext';
import pinContext from '../../Context/PinContext';
import Menu from './Menu';
import { CabecalhoBuscaResponsivo, CabecalhoBuscaLinhaResponsivo } from '../Banner/styles';
import api from '../../service/api';
import { LayoutDTO } from '../../DTOs/LayoutDTO';
import imageContext from '../../Context/ImagesContext';
import { formatarValores } from '../../helpers/valores';
import useCalculaTotais from '../../hooks/useCalculaTotais';
import { UsuarioLogadoDTO } from '../../DTOs/UsuarioLogadoDTO';


const Header: React.FC = () => {
  const [url, setUrl] = useState("");
  const currentlyUser = useContext(userContext);
  const currentlyPin = useContext(pinContext);
  let history = useHistory();
  const [layout, setLayout] = useState<LayoutDTO>();
  const { images, alterarLogo } = useContext(imageContext);

  const [apresentaprecocusto, setApresentaprecocusto] = useState(currentlyUser.user.Indapresentaprecocusto == 1 ? true : false);
  const { totais } = useCalculaTotais();

  function Pesquisa() {
    history.push(`/pesquisa/${encodeURIComponent(url)}`);
  }

  function SignOut() {
    localStorage.removeItem('@VersaB2B:login');
    localStorage.setItem('@VersaB2B:Autorizacao', "false");

    currentlyUser.onLogout();
    history.push("/");
  }

  function mostrarCarrinho() {
    currentlyPin.mostrarCarrinho();
  }


  const handleCheckboxChange = (value: number) => {

    let usuarioCopia: UsuarioLogadoDTO = currentlyUser.user;
    usuarioCopia.Indapresentaprecocusto = value;

    var filtro = {
      codusuario: usuarioCopia.Codusuario,
      Indapresentaprecocusto: value
    }

    api.post(`api/business/usuario/apresentaprecocusto`, filtro).then(() => {
      localStorage.setItem('@VersaB2B:login', JSON.stringify(usuarioCopia));
      currentlyUser.alteraUsuario(usuarioCopia);
      setApresentaprecocusto(value == 1 ? true : false)
    });
  }

  useEffect(() => {
    api.get(`api/configuracao/getconfiguracoes`).then((response) => {
      const layout: LayoutDTO = response.data;
      setLayout(layout);
    });

    const logoPath = localStorage.getItem('logoPath'); // Retrieve the logo path from localStorage
    if (logoPath) {
      alterarLogo(logoPath); // Update the logo path in the imageContext
    }
  }, []);

  return (
    <>
      <Container>
        <Content>
          <CabecalhoLogo>
            <CabecalhoLogoImagem>
              <a href="/">
                {/* <img src={minhaImagem} alt="Versa B2B" />  */}
                <img src={images.Logo} alt="Logo" />
              </a>
              <label className="btn-shop-responsivo" htmlFor="checked">
                <Link to="/carrinho" className='iconCart'>
                  <FontAwesomeIcon icon={faShoppingCart} size={"2x"} />
                  <div className='infos-cart'>
                    <span>
                      {totais.qtdDisponivel} itens
                    </span>
                    <span>
                      <b> {formatarValores(totais.vlrDisponivel)}</b>
                    </span>
                  </div>
                </Link>
              </label>
            </CabecalhoLogoImagem>
          </CabecalhoLogo>
          <CabecalhoBusca>
            <CabecalhoBuscaLinha>
              <form onSubmit={Pesquisa}>
                <input type="text" id="iptBusca" placeholder="Pesquisar produtos" title="Campo de busca." name="buscar" value={url} onChange={event => setUrl(event.target.value)} />
                <Botao type="submit" containerStyle={{
                  backgroundColor: "var(--headerButton-bg-color)", borderColor: "#fff",
                  color: "#fff", borderWidth: 1, borderStyle: "solid",
                  padding: 0, margin: 0, height: "inherit", fontWeight: "normal",
                  fontSize: 16
                }}>Pesquisar</Botao>
              </form>
            </CabecalhoBuscaLinha>
          </CabecalhoBusca>
          <CabecalhoUsuario>
            <CabecalhoUsuarioDeslogado>
              {currentlyUser.user.Codperfil === 0 ?
                <>
                  <Link to="/">
                    <FontAwesomeIcon icon={faUser} size={"2x"} />
                  </Link>
                  <Link to="/">
                    &nbsp;&nbsp;&nbsp;
                    Entrar ou&nbsp;
                  </Link>
                  <Link to="/criarconta">
                    Cadastrar
                  </Link>
                </>
                :
                <Dropdow>
                  <div className="iconUsuario">
                    <a href="#" className='loginUsuario'>
                      <FontAwesomeIcon className='iconUser' style={{ color: layout?.Cores?.HeaderIcons }} icon={faUser} size={"2x"} />
                      Olá {currentlyUser.user.Nome}</a>
                  </div>
                  <div className="dropdow-content">
                    <div className="descricao">
                      <div className="area-usuario">
                        <div className="divs-area-usuario">
                          <div className="header-usuario">
                            <div className='nomeCod'>
                              <p className="nome-usuario">{currentlyUser.user.Nome}</p>
                              <p className='codUsuarioVersa'>#{currentlyUser.user.Codusuarioversa}</p>
                            </div>
                            <div className="cargo-usuario">
                              <p>{currentlyUser.user.Nomcliente ? currentlyUser.user.Nomcliente : "Cliente não selecionado"}</p>
                            </div>

                          </div>
                          {currentlyUser.user.Codperfil !== 1 ?
                            <Link to="/listaclientes" className="editar-usuario">
                              <div className="icons">
                                <FontAwesomeIcon icon={faPeopleArrows} className='iconUser' size={"1x"} />
                              </div>
                              <div className='links'>
                                <p className="conta-usuario">Lista de Clientes</p>
                              </div>
                            </Link>
                            : null}

                          <Link to="/minhaconta/meuspedidos" className="editar-usuario">
                            <div className="icons">
                              <FontAwesomeIcon icon={faUser} className='iconUser' size={"1x"} />
                            </div>
                            <div className='links'>
                              <p className="conta-usuario">Minha Conta</p>
                            </div>
                          </Link>

                          {currentlyUser.user.Codperfil !== 1 ?
                            <Link to="/solicitacaoacesso" className="editar-usuario">
                              <div className="icons">
                                <FontAwesomeIcon className='iconUser' icon={faUserClock} size={"1x"} />
                              </div>
                              <div className='links'>
                                <p className="conta-usuario">Permissões</p>
                              </div>
                            </Link>
                            : null}


                          {currentlyUser.user.Codperfil !== 1 ?
                            <Link to="/configuracao" className="editar-usuario">
                              <div className="icons">
                                <FontAwesomeIcon className='iconUser' icon={faGear} size={"1x"} />
                              </div>
                              <div className='links'>
                                <p className="conta-usuario">Configurações</p>
                              </div>
                            </Link>
                            : null}

                          <Link to="/" className="editar-usuario" onClick={() => {
                            return handleCheckboxChange(currentlyUser.user.Indapresentaprecocusto == 1 ? 0 : 1);
                          }}>
                            <div className="icons">
                              {apresentaprecocusto ? (
                                <FontAwesomeIcon icon={faSquareCheck} className='iconUser' size={"1x"} />
                              ) : (
                                <FontAwesomeIcon icon={faSquare} className='iconUser' size={"1x"} />
                              )}
                            </div>
                            <div className='links'>
                              <label htmlFor="ocultarpreco" className="conta-usuario">Mostrar preço de custo</label>
                            </div>
                          </Link>

                          <Link to="/minhaconta/alterarsenha/" className="editar-usuario">
                            <div className="icons">
                              <FontAwesomeIcon icon={faKey} className='iconUser' size={"1x"} />
                            </div>
                            <div className='links'>
                              <p className="conta-usuario">Alterar Senha</p>
                            </div>
                          </Link>

                          <div className="editar-usuario">
                            <div className="icons">
                              <FontAwesomeIcon icon={faSignOut} className='iconUser' size={"1x"} />
                            </div>
                            <div className='links'>
                              <p className="conta-usuario" onClick={SignOut}>Logout</p>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </Dropdow>}
              <input className="inputDesktop" type="checkbox" id="checked" />
              <label className="btn-shop" htmlFor="checked" onClick={mostrarCarrinho}>
                <FontAwesomeIcon icon={faShoppingCart} size={"2x"} />

                <div className='infos-cart'>
                  <span>
                    {totais.qtdDisponivel} Itens
                  </span>
                  <span>
                    <b> {formatarValores(totais.vlrDisponivel)}</b>
                  </span>
                </div>

              </label>

              <label className="btn-shop-responsivo" htmlFor="checked">
                <Link to="/carrinho">
                  <FontAwesomeIcon icon={faShoppingCart} size={"2x"} />
                </Link>
                <div className='infos-cart'>
                  <span>
                    {totais.qtdDisponivel} itens
                  </span>
                  <span>
                    <b> {formatarValores(totais.vlrDisponivel)}</b>
                  </span>
                </div>
              </label>
            </CabecalhoUsuarioDeslogado>
          </CabecalhoUsuario>
        </Content>
        <MenuDesktop>
          <Menu />
          {/* <MenuTeste /> */}
        </MenuDesktop>
      </Container>
      <CabecalhoBuscaResponsivo>
        <CabecalhoBuscaLinhaResponsivo>
          <form onSubmit={Pesquisa}>
            <input type="text" id="iptBusca" placeholder="Pesquisar produtos" title="Campo de busca." name="buscar" value={url} onChange={event => setUrl(event.target.value)} />
            <Botao type="submit" containerStyle={{
              backgroundColor: "#313131", borderColor: "#fff",
              color: "#fff", borderWidth: 1, borderStyle: "solid",
              padding: 0, margin: 0, height: "inherit", fontWeight: "normal",
              fontSize: 16
            }}>Pesquisar</Botao>
          </form>
        </CabecalhoBuscaLinhaResponsivo>

      </CabecalhoBuscaResponsivo>
    </>
  )
};

export default Header;


