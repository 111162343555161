import { createContext } from "react";
import { ImagesDTO } from "../DTOs/ImagesDTO";
import minhaImagem from '../../src/assets/Logo/logo.jpg';
 
type ImageContextType = {
    images: ImagesDTO;
    alterarLogo: (path: string) => void,

};


export const imageInitial = {
        Logo: minhaImagem
};

const imageContext = createContext<ImageContextType>({images: imageInitial, alterarLogo: (path: string) => {}});



export default imageContext;
