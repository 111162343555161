import styled from 'styled-components';

export const Container = styled.div`
  justify-content: center;
`;

export const FormInputsQuantidade = styled.form`
  display: flex;
  margin: 0 auto;

  @media (min-width: 376px) and (max-width: 436px) {
    margin: 0 0 5px -1px;
  }

  button {
    width: 50px;
    height: 50px;
    color: #787474;
    border: 2px solid #787474;
    font-size: 20px;
    font-weight: bold;

    @media (max-width: 375px) {
      width: 32px;
    }

    @media (min-width: 376px) and (max-width: 436px) {
      width: 37px;
    }

    @media (min-width: 1521px) {
      width: 50px;
      height: 50px;
    }

    @media (min-width: 1800px) {
      width: 100%;
    }
  }

  input {
    height: 50px;
    margin-bottom: 5px;
    color: #787474;
    border: 2px solid #787474;
    text-align: center;
    font-size: 18px;
    width: 80px;

    @media (max-width: 375px) {
      width: 50px;
    }

    @media (min-width: 376px) and (max-width: 436px) {
      width: 65px;
    }

    @media (min-width: 1521px) {
      height: 50px;
    }

    @media (min-width: 1800px) {
      width: 100px;
    }
  }

`;
