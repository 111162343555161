import * as React from "react";
import { useForm } from "react-hook-form";
import { CadastroPublicidade, Container, TituloSecao } from "../styles";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../service/api";
import { useEffect, useState } from "react";
import { useToast } from "../../../hooks/toast";
import {
  Publicidade,
  PublicidadeModeloDTO,
} from "../../../DTOs/PublicidadeModeloDTO";
import { CarrinhoProdutoDTO } from "../../../DTOs/produtoDTO";
import { formatarData } from "../../../helpers/valores";

const CdCapas: React.FC = () => {
  const { addToast } = useToast();
  const [produto, setPublicidade] = useState<CarrinhoProdutoDTO>();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [pathResponse, setPathResponse] = useState<string>();
  const { register, handleSubmit, setValue } = useForm();
  const { id }: any = useParams();
  const isAddMode = !id;
  let history = useHistory();

  function onChangeFile(event: any) {
    const target = event.target;
    if (target.files != null) {
      const file = target.files[0];
      setSelectedFile(file);
    }
  }

  function uploadImagem() {
    if (selectedFile == null) {
      addToast({
        type: "error",
        title: "Erro ao subir logo!",
      });
    } else {
      const formData = new FormData();
      // formData.append('codproduto', String(produto?.CodProduto));
      formData.append("dataFile", selectedFile, selectedFile.name);

      api
        .post(`api/produto/capaUpload`, formData)
        .then((response) => {
          const data = response.data;
          setPathResponse(data);
          addToast({
            type: "success",
            title: "Capa publicada com sucesso!",
          });
          return data;
        })
        .catch((error) => {
          addToast({
            type: "error",
            title: "Erro ao publicar capa!",
          });
        });
    }
    history.push("/configuracao/");
  }

  function SalvarImagemCapa(produto: CarrinhoProdutoDTO) {
    if (pathResponse != null) {
      produto.Pathimagem = pathResponse;
    }
  }

  const OnSubmit = (data: any) => {
    const produto: CarrinhoProdutoDTO = data;
    if (selectedFile != null) {
      uploadImagem();
    }
    SalvarImagemCapa(produto);
  };

  return (
    <Container>
      <TituloSecao>Cadastro de Capa</TituloSecao>

      <CadastroPublicidade>
        <form onSubmit={handleSubmit(OnSubmit)}>
          <div className="campoImg">
            <p>Imagem(1900px X 450px)</p>
            <input
              type="file"
              className="inputImagem"
              name="file"
              onChange={onChangeFile}
            />
          </div>

          <div className="botoesFiltros">
            <button type="submit" className="btn-novo">
              {" "}
              Salvar
            </button>
          </div>
        </form>
      </CadastroPublicidade>
    </Container>
  );
};

export default CdCapas;
