import { faThumbTack, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useContext, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Link, Redirect, useHistory } from "react-router-dom";
import { CarrinhoDTO } from "../../DTOs/produtoDTO";
import { formatarValores } from "../../helpers/valores";
import { useToast } from "../../hooks/toast";
import cartContext from "../../Context/CartContext";
import pinContext from "../../Context/PinContext";
import ModalConfirmacao from "../ModalConfirmacao";
import ProdutoQuantidadeCarrinho from "./ProdutoQuantidade";
import './styles.css'
import useCalculaTotais from "../../hooks/useCalculaTotais";

export const Carrinho: React.FC = () => {
  const { addToast } = useToast();
  const currentlyCart = useContext(cartContext);
  const currentlyPin = useContext(pinContext);
  let history = useHistory();

  const { totais } = useCalculaTotais();

  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const [descontoCarrinho, setDescontoCarrinho] = useState("");
  const [valorMercadorias, setValorMercadorias] = useState("");

  const handleRemoverItem = useCallback((id: number) => {
    try {
      currentlyCart.removeItem(id);
      addToast({
        type: 'success',
        title: 'Produto removido do carrinho'
      });

    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao remover item do carrinho',
        description:
          'Ocorreu um erro ao remover o produto do carrinho' + error,
      });
    }
  }, [currentlyCart.cart.Produtos, addToast]);

  // Esse hook monitora a alteração do state carrinho, para fazer as tratativas
  // de gravar no localStorage
  useEffect(() => {

    // Grava no localStorage do usuário o carrinho atualizado, se tiver algo
    if (currentlyCart.cart.Produtos.length > 0)
      localStorage.setItem('@VersaB2B:carrinho', JSON.stringify(currentlyCart.cart));

  }, [currentlyCart.cart]);

  // Calcula o total do carrinho
  const totalCarrinho = useCallback(() => {
    let totalVal = 0;
    let descontoValor = 0;
    let vlrMercadoria = 0;

    for (let i = 0; i < currentlyCart.cart.Produtos.length; i++) {

      // Lê o produto atual
      let produto = currentlyCart.cart.Produtos[i];
      if (produto.isDeleted == false) {

        if (produto.Qtd) {
          // Calcula o total
          vlrMercadoria += produto.Vlr * produto.Qtd;
        }
        totalVal += produto.VlrTotal;
      }

    }

    descontoValor += vlrMercadoria - totalVal;

    // Seta no state o valor total
    setValorTotalCarrinho(formatarValores(totalVal));
    setDescontoCarrinho(formatarValores(descontoValor));
    setValorMercadorias(formatarValores(vlrMercadoria))

  }, [currentlyCart.cart.Produtos]);

  useEffect(() => {

    // Grava no localStorage do usuário as quantidades
    if (currentlyCart.cart.Produtos.length > 0)
      localStorage.setItem('@VersaB2B:carrinho', JSON.stringify(currentlyCart.cart));

    totalCarrinho();

  }, [currentlyCart.cart.Produtos, totalCarrinho]);

  useEffect(() => {
    if (valorTotalCarrinho != "") {
      localStorage.setItem('@VersaB2B:valorTotalCarrinho', JSON.stringify(valorTotalCarrinho));
    }

  }, [valorTotalCarrinho]);

  // Valida a opção selecionada pelo usuário no modal de confirmação
  const handleOpcaoSelecionadaModalConfirmacao = useCallback((opcao: string): void => {
    // Se o usuário clicar em SIM no modal, vamos limpar o carrinho
    if (opcao === "S") {

      currentlyCart.esvaziaCarrinho();

      totalCarrinho();

      addToast({
        type: 'success',
        title: 'Seu carrinho foi limpo.'
      });
    }

    // Tratativas em JS para fechar o modal
    let modal = document.getElementById('react-confirm-alert');
    let body = document.getElementsByTagName('body').item(0);
    let svg = document.getElementById('react-confirm-alert-firm-svg');

    if (modal && body && svg) {
      modal.style.visibility = 'hidden';
      svg.style.visibility = 'hidden';
      modal?.classList.remove('react-confirm-alert-svg');
      body?.classList.remove('react-confirm-alert-body-element');
    }

  }, [currentlyCart.cart, addToast]);

  // Exibe o modal de confirmação para o usuário
  const mostrarModalConfirmacaoLimparCarrinho = useCallback(({ onClose }) => {
    return (
      <ModalConfirmacao opcaoSelecionada={handleOpcaoSelecionadaModalConfirmacao} tituloModal="Esvaziar Carrinho" textoModal="Tem certeza que deseja esvaziar seu carrinho?" />
    )
  }, [handleOpcaoSelecionadaModalConfirmacao]);

  // Captura a ação de clicar no botão de esvaziar carrinho
  const handleLimparCarrinho = useCallback(() => {

    // Tratativas em JS para exibir o modal, após ele ter sido fechado ou dispensado
    let modal = document.getElementById('react-confirm-alert');
    let body = document.getElementsByTagName('body').item(0);

    if (modal && body) {
      modal.style.visibility = 'visible';
      body?.classList.add('react-confirm-alert-body-element');
    }

    // Chama o modal
    confirmAlert({ customUI: mostrarModalConfirmacaoLimparCarrinho })
  }, [mostrarModalConfirmacaoLimparCarrinho]);

  function alteraPin() {
    currentlyPin.ativarCarrinhoPin();
  }



  function FinalizarPedido() {
    currentlyCart.buscaCarrinho(currentlyCart.cart.CodCliente ? currentlyCart.cart.CodCliente : 0);

    history.push("/carrinho");
  }

  return (
    <div className="container-cart">
      <nav className={currentlyPin.pin.carrinhoToggle ? 'cart actived' : 'cart'}>
        <div className="cart-header">
          <div className="bloco-pin">
            <input type="checkbox" id="checkPin" className="inputPin" />
            <label onClick={alteraPin} className="labelPin" htmlFor="checkPin">
              <FontAwesomeIcon icon={faThumbTack} size={"1x"} className="pinEsquerdo" />
            </label>
          </div>
        </div>
        <div className="cart-body">
          <div className="cart-info">
            <div className="cart-title">
              ITENS NO MEU CARRINHO
            </div>
            <div className="cards-cart">
              {currentlyCart.cart.Produtos.map((produto) => (
                produto.CodProduto >= 0 && produto.isDeleted == false ?
                  <div className="card-cart" key={produto.CodProduto}>
                    <div className="card-cart-image">
                      <div className="container-image">
                        <a href={`/produtos/${produto.CodProduto}/detalhes`}>
                          <img className="cart-img" src={produto.Src} />
                        </a>
                      </div>
                    </div>
                    <div className="card-cart-infos">
                      <div className="card-cart-title">
                        <a href={`/produtos/${produto.CodProduto}/detalhes`} className="produto-link">{produto.Titulo}</a>
                      </div>
                      <div className="card-cart-buttons">

                        <div className="product-quantity">
                          <ProdutoQuantidadeCarrinho
                            aumentarQuantidade={() => currentlyCart.adicionaQuantidade(produto.CodProduto)}
                            diminuirQuantidade={() => produto.Qtd != null && produto.Qtd > 1 ? currentlyCart.removeQuantidade(produto.CodProduto) : null}
                            quantidade={produto.Qtd}
                            id={produto.CodProduto}>
                          </ProdutoQuantidadeCarrinho>
                        </div>
                        <div className="product-value">
                          <span>{formatarValores(produto.Vlr)}</span>
                        </div>
                        <div className="product-discount">
                          <span><b>{produto.Desconto ? `${produto.Desconto}%` : null}</b></span>
                        </div>
                        <div className="product-value-total">

                          <span>
                            {formatarValores(produto.VlrTotal)}
                          </span>
                        </div>
                        <div className="btnRemoverProduto">
                          <span>
                            <FontAwesomeIcon className="remover-produto" onClick={() => handleRemoverItem(produto.CodProduto)} icon={faTrash} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  null))}
            </div>
            <div className="cart-footer">
              <div className="cart-title">
                Resumo do Pedido
              </div>
              <div className="valorTotal">
                <div className="subtotal">
                  <table>
                    <thead>
                      <th></th>
                      <th>Qtd</th>
                      <th>Valor</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Disponível: </td>
                        <th>{totais.qtdDisponivel}</th>
                        <td><b>{formatarValores(totais.vlrDisponivel)}</b></td>
                      </tr>
                      <tr>
                        <td>Encomenda: </td>
                        <th>{totais.qtdEncomenda}</th>
                        <td><b>{formatarValores(totais.vlrEncomenda)}</b></td>
                      </tr>
                      <tr>
                        <td>Total: </td>
                        <th>{totais.qtdTotal}</th>
                        <td><b>{formatarValores(totais.vlrTotal)}</b></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* <div className="descontoeTotalFinal">
                  <div className="Unidade">
                    <p className="desconto-vlrTotal">Mercadorias: </p>
                    <p className="desconto-vlrTotal">Descontos: </p>
                    <p className="desconto-vlrTotal">Valor Total: </p>
                  </div>

                  <div className="totais">
                    <p className="valoresPedidoFinal"><span>{`${valorMercadorias}`}</span></p>
                    <p className="valoresPedidoFinal"><span>{`${descontoCarrinho}`}</span></p>
                    <p className="valoresPedidoFinal"><span>{valorTotalCarrinho}</span></p>
                  </div>
                </div> */}
              </div>
              <div className="botoes">                <div className="finalizar">
                <button className="btn btn-limparCarrinh
                  o" onClick={() => handleLimparCarrinho()}>Esvaziar Carrinho</button>
                <button onClick={() => FinalizarPedido()} className="btn btn-finalizar">Finalizar Carrinho</button>
              </div>
                <p className="salvar-carrinho" onClick={() => currentlyCart.buscaCarrinho(currentlyCart.cart.CodCliente ? currentlyCart.cart.CodCliente : 0)}><span className="limparCarrinho">Recarregar Carrinho</span></p>
              </div>

            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}


