import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../../../componentes/Table/Table";
import { PaginaEstaticaDTO } from "../../../DTOs/PaginaEstaticaDTO";
import { SortColumnDTO } from "../../../DTOs/SortColumnDTO";


type MyProps = {
  // using `interface` is also ok
  paginasEstaticas?: Array<PaginaEstaticaDTO>;
  sortColumn?: SortColumnDTO;
  onSort: any;
  onDelete: Function;

};

class PaginaEstaticaTable extends Component<MyProps> {
  chave = "Codpagina";
  columns = [
    {
      path: "Codpagina",
      label: "Código",

    },
    {
      path: "Nompagina",
      label: "Nome",
      content: (paginaEstatica: PaginaEstaticaDTO) => <Link to={`/configuracao/cdpaginaestatica/${paginaEstatica.Codpagina}`}>{paginaEstatica.Nompagina}</Link>
    },

    // { path: "dailyRentalRate", label: "Rate" },
    // {
    //   key: "like",
    //   //   content: (movie: { liked: any; }) => (
    //   //     <Like liked={movie.liked} onClick={() => this.props.onLike(movie)} />
    //   //   )
    // },
    {
      key: "delete",
      content: (paginaEstatica: PaginaEstaticaDTO) => (
        <button
          onClick={() => this.props.onDelete(paginaEstatica.Codpagina)}
          className="btn btn-danger btn-sm"
        >
          {/* <FontAwesomeIcon icon={faTrash} size={"1x"} className="pinEsquerdo" />  */}
          Excluir
        </button>
      )
    }
  ];

  handleSort = (sortColumn: SortColumnDTO) => {
    this.setState({ sortColumn });
  };



  render() {
    const { sortColumn, onSort, paginasEstaticas } = this.props;
    // const paginasEstaticas = this.getPagedData();

    return (
      <Table
        columns={this.columns}
        data={paginasEstaticas}
        sortColumn={sortColumn}
        onSort={onSort}
        chave={this.chave}
      />
    );
  }
}

export default PaginaEstaticaTable;
