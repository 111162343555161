import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from '../paginas/Home';
import Carrinho from '../paginas/Carrinho';
import Login from '../paginas/Login';
import Lancamentos from '../paginas/Lancamentos';
import Editora from '../paginas/Editora';
import EditoraList from '../paginas/Editora/editoralist';
import Autor from '../paginas/Autor';
import Pesquisa from '../paginas/Pesquisa';
import CriarConta from '../paginas/Login/CriarConta';
import RedefinirSenha from '../paginas/Login/RedefinirSenha';
import Vitrine from '../paginas/Vitrine';
import { PrivateRoute } from './PrivateRoute';
import Categoria from '../paginas/Categoria';
import PreVenda from '../paginas/PreVenda';
import Grupo from '../paginas/Grupo';
import Promocoes from '../paginas/Promocoes';
import MaisVendidos from '../paginas/MaisVendidos';
import SolicitacaoAcesso from '../paginas/Acesso';
import AceitarAcesso from '../paginas/Acesso/AceitarAcesso';
import MeusPedidos from '../paginas/Conta/MeusPedidos';
import NotasFiscais from '../paginas/Conta/NotasFiscais';
import DuplicatasEmAberto from '../paginas/Conta/DuplicatasEmAberto';
import Clientes from '../paginas/Clientes';
import ProdutoDetalhes from '../paginas/Produto/ProdutoDetalhes';
import PedidoDetalhes from '../paginas/Conta/PedidoDetalhes';
import CategoriaInfantil from '../paginas/Categoria/CategoriaInfantil';
import Configuracao from '../paginas/Configuracao';
import MenuLinks from '../paginas/Configuracao/Links/lstlink';
import Cdlink from '../paginas/Configuracao/Links/cdlink';
import Publicidade from '../paginas/Configuracao/Publicidade/publicidade';
import Layout from '../paginas/Configuracao/Layout/layout';
import LstPublicidade from '../paginas/Configuracao/Publicidade/lstPublicidade';
import LstPublicidadeModelo from '../paginas/Configuracao/Publicidade/lstPublicidadeModelo';
import CdPublicidadeModelo from '../paginas/Configuracao/Publicidade/cdpublicidadeModelo';
import PaginasEstaticas from '../paginas/Configuracao/PaginasEstaticas/Index';
import CdPublicidade from '../paginas/Configuracao/Publicidade/cdpublicidade';
import CdPaginaEstatica from '../paginas/Configuracao/PaginasEstaticas/CdPaginaEstatica';
import LayoutCores from '../paginas/Configuracao/Layout';
import AlterarSenha from '../paginas/Login/AlteraSenha';
import CdCapas from '../paginas/Configuracao/Capas';
import CdLogo from '../paginas/Configuracao/Logo';
import Colecao from '../paginas/Colecao';

const Rotas: React.FC = () => {
  return (
    <Switch>
   
      <Route path="/login" exact component={Login} />
      <Route path="/criarconta" exact component={CriarConta} />
      <Route path="/redefinirsenha" exact component={RedefinirSenha} />
      <PrivateRoute path="/" exact component={Home} />
      <PrivateRoute path="/carrinho" exact component={Carrinho} />
      <PrivateRoute path="/lancamentos" exact component={Lancamentos} />
      <PrivateRoute path="/maisvendidos" exact component={MaisVendidos} />
      <PrivateRoute path="/promocoes" exact component={Promocoes} />
      <PrivateRoute path="/editora/list" exact component={EditoraList} />
      <PrivateRoute path="/editora/:id/:name" exact component={Editora} />
      <PrivateRoute path="/colecao/:id/:name" exact component={Colecao} />
      <PrivateRoute path="/vitrine/:id/:name" exact component={Vitrine} />
      <PrivateRoute path="/autor/:id/:name" exact component={Autor} />
      <PrivateRoute path="/categoria/:id/:name" exact component={Categoria} />
      {/* <PrivateRoute path="/categoria/:id/:name/:lancamentos" exact component={Categoria} />
      <PrivateRoute path="/categoria/:id/:name/:maisvendidos" exact component={Categoria} /> */}
      <PrivateRoute path="/categoria/:id/:name/:filtro" exact component={Categoria} />
      <PrivateRoute path="/prevenda" exact component={PreVenda} />
      <PrivateRoute path="/grupo/:id/:name" exact component={Grupo} />
      {/* <PrivateRoute path="/grupo/:id/:name/lancamentos" exact component={Grupo} />
      <PrivateRoute path="/grupo/:id/:name/maisvendidos" exact component={Grupo} /> */}
      <PrivateRoute path="/grupo/:id/:name/:filtro" exact component={Grupo} />
      <PrivateRoute path="/livrosinfantis" exact component={CategoriaInfantil} />
      <PrivateRoute path="/pesquisa/:name" exact component={Pesquisa} />
      <PrivateRoute path="/minhaconta/meuspedidos" exact component={MeusPedidos} />
      <PrivateRoute path="/minhaconta/pedidosdetalhes/:id" exact component={PedidoDetalhes} />
      <PrivateRoute path="/minhaconta/duplicatasemaberto" exact component={DuplicatasEmAberto} />
      <PrivateRoute path="/minhaconta/notasfiscais" exact component={NotasFiscais} />
      <PrivateRoute path="/solicitacaoacesso" exact component={SolicitacaoAcesso} />
      <PrivateRoute path="/aceitaracesso/:id" exact component={AceitarAcesso} />
      <PrivateRoute path="/listaclientes" exact component={Clientes} />
      <PrivateRoute path="/produtos/:id/detalhes" exact component={ProdutoDetalhes} />
      <PrivateRoute path="/configuracao" exact component={Configuracao} />      
      <PrivateRoute path="/configuracao/paginasestaticas" exact component={PaginasEstaticas} />
      <PrivateRoute path="/configuracao/cdpaginaestatica/:id" exact component={CdPaginaEstatica} />   
      <PrivateRoute path="/configuracao/cdpaginaestatica" exact component={CdPaginaEstatica} />         
      <PrivateRoute path="/configuracao/publicidade" exact component={Publicidade} />
      <PrivateRoute path="/configuracao/layout" exact component={Layout} />
      <PrivateRoute path="/configuracao/publicidade/lstpublicidade" exact component={LstPublicidade} />
      <PrivateRoute path="/configuracao/publicidade/lstpublicidademodelo" exact component={LstPublicidadeModelo} />
      <PrivateRoute path="/configuracao/publicidade/cdpublicidade" exact component={CdPublicidade} />
      <PrivateRoute path="/configuracao/publicidade/cdcapas" exact component={CdCapas} />
      <PrivateRoute path="/configuracao/cdlogo" exact component={CdLogo} />
      <PrivateRoute path="/configuracao/publicidade/cdpublicidade/:id" exact component={CdPublicidade} />
      <PrivateRoute path="/configuracao/publicidade/cdpublicidadeModelo" exact component={CdPublicidadeModelo} />
      <PrivateRoute path="/configuracao/publicidade/cdpublicidadeModelo/:id" exact component={CdPublicidadeModelo} />      
      <PrivateRoute path="/configuracao/lstlinks" exact component={MenuLinks} />
      <PrivateRoute path="/configuracao/cdlink" exact component={Cdlink} />
      <PrivateRoute path="/configuracao/cdlink/:id" exact component={Cdlink} />      
      <PrivateRoute path="/configuracao/layoutcores" exact component={LayoutCores} />
      <PrivateRoute path="/minhaconta/alterarsenha" exact component={AlterarSenha} />
      <Redirect to={{ pathname: "/"}} />
              {/* <Route path="*" component={Home} /> */}
    </Switch>
  );
};

export default Rotas;
