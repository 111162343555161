import * as React from 'react';
import {
  Container, FooterNewsletter, TituloFooter
} from './styles';
import formasPg1 from '../../assets/img/formasPagamento/visa.png';
import formasPg2 from '../../assets/img/formasPagamento/master.png';
import formasPg3 from '../../assets/img/formasPagamento/hiper.png';
import formasPg4 from '../../assets/img/formasPagamento/elo.png';
import formasPg5 from '../../assets/img/formasPagamento/pag se.png';
import formasPg6 from '../../assets/img/formasPagamento/american.png';
import formasPg7 from '../../assets/img/formasPagamento/dinner.png';
import formasPg8 from '../../assets/img/formasPagamento/boleto.png';
import whatsapp from '../../assets/img/whatsapp.png'
import facebook from '../../assets/img/facebook.png'
import instagram from '../../assets/img/instagram.png'
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../service/api';
import { LayoutDTO } from '../../DTOs/LayoutDTO';


const Footer: React.FC = () => {
  const [layout, setLayout] = useState<LayoutDTO>();


  useEffect(() => {
    api.get(`api/configuracao/getconfiguracoes`).then((response) => {
      const layout: LayoutDTO = response.data;
      setLayout(layout);
      // setValue("Social", layout["Social"]);
    });
  }, []);

  return (
    <Container>
      <div className='footer'>
        <div className='camadaUmFooter'>
          <nav>
            <p className='infos' >Links Rápidos</p>
            <ul >
              <li><Link to="/editora/list">Editoras</Link></li>
              <li><Link to="/lancamentos">Lançamentos</Link></li>
              <li><Link to="/promocoes">Promoções</Link></li>
              <li><Link to="/maisvendidos">Mais vendidos</Link></li>
            </ul>
          </nav>

          <nav>
            <p className='infos'>Conta</p>
            <ul>
              <a href="/minhaconta/meuspedidos"><li>Minha Conta</li></a>
            </ul>
          </nav>

          <nav>
            <p className='infos'>Informações</p>
            {/* <ul>
              <li><Link to="#">Informação 1</Link></li>
              <li><Link to="#">Informação 2</Link></li>
              <li><Link to="#">Informação 3</Link></li>
              <li><Link to="#">Informação 4</Link></li>
              <li><Link to="#">Informação 5</Link></li>
              <li><Link to="#">Informação 6</Link></li>
            </ul> */}
          </nav>

          <div className='icons'>
            <p className='redesSociais'>{layout?.Social?.TituloSecao}</p>
            <div className='iconsRedesSociais'>
              <a href={layout?.Social?.Whatsapp} target="_blank"> <img src={whatsapp} alt="whatsapp" className='whatsapp' /></a>
              <a href={layout?.Social?.Facebook} target="_blank"> <img src={facebook} alt="whatsapp" className='whatsapp' /></a>
              <a href={layout?.Social?.Instagram} target="_blank"> <img src={instagram} alt="whatsapp" className='whatsapp' /></a>
            </div>
          </div>

          <FooterNewsletter>
            <TituloFooter >Newsletter</TituloFooter>
            <form>
              <input type="text" id="newsEmail" name="newsEmail" placeholder="E-mail" />
              <button type='submit' >Assinar</button>

            </form>

            <div className='formasPagamentoCompraSegura'>
              <p className='tituloPagto'>Formas de Pagamento</p>
              <div className='formasPagto'>
                <img src={formasPg1} alt="FormaPgto1" className='cartao' />
                <img src={formasPg2} alt="FormaPgto2" className='cartao' />
                <img src={formasPg3} alt="FormaPgto3" className='cartao' />
                <img src={formasPg4} alt="FormaPgto4" className='cartao' />
                <img src={formasPg5} alt="FormaPgto5" className='cartao' />
                <img src={formasPg6} alt="FormaPgto6" className='cartao' />
                <img src={formasPg7} alt="FormaPgto7" className='cartao' />
                <img src={formasPg8} alt="FormaPgto8" className='cartao' />
              </div>
            </div>
          </FooterNewsletter>

        </div>
      </div>

      {/* <div className='formasPagamentoCompraSegura'>
        <p>Formas de Pagamento</p>
        <div className='formasPagto'>
          <img src={formasPg1} alt="FormaPgto1" className='cartao'/>
          <img src={formasPg2} alt="FormaPgto2" className='cartao'/>
          <img src={formasPg3} alt="FormaPgto3" className='cartao' />
          <img src={formasPg4} alt="FormaPgto4" className='cartao' />
          <img src={formasPg5} alt="FormaPgto5" className='cartao' />
          <img src={formasPg6} alt="FormaPgto6" className='cartao' />
          <img src={formasPg7} alt="FormaPgto7" className='cartao' />
          <img src={formasPg8} alt="FormaPgto8" className='cartao' />
        </div>
      </div> */}

    </Container>
  )
};

export default Footer;
