import styled from 'styled-components';

export const Container = styled.div`
  color: #000000;
  top: 100px;
  margin-bottom: 4rem;

  @media (max-width: 923px) {
    margin-bottom: 0;
  }

  input[type=checkbox]{
display: none;
}
`;

export const TituloSecao = styled.h3`
  padding: 20px;
  color: #313131;
  font-size: 26px;
  text-align: left;
  margin-bottom: 12px;
`;

export const Return = styled.div`
.retornar a{
    color: rgb(163, 162, 162);
}

.retornar a:hover{
    color: #423e3e;
    transition: 1s;
}
`;

