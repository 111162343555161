import * as React from 'react';
import { Container, TituloSecao } from './styles';
import Grid from '../../componentes/Grid';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import userContext from '../../Context/UserContext';

const Pesquisa: React.FC = () => {
  const {name}: any = useParams(); 
  const currentlyUser = useContext(userContext);
  const {Codcliente, Codempresacli} = currentlyUser.user;
  return (
    <Container>
      <TituloSecao>
        Pesquisa {decodeURIComponent(name)} 
      </TituloSecao>
      <Grid Pesquisa={decodeURIComponent(name)} Codcliente={Codcliente} Codempresacli={Codempresacli}/>
    </Container>
  )
};

export default Pesquisa;
