import * as React from 'react';
import { Container } from './styles';
import { Notas } from './main';
import Sidebar from './Sidebar';
import { useContext, useEffect, useState } from 'react';
import api from '../../service/api';
import { formatarData, formatarValores } from '../../helpers/valores';
import { NotasFiscaisDTO } from '../../DTOs/NotasFiscaisDTO';
import userContext from '../../Context/UserContext';
import Pagination from '../../componentes/Paginacao/Pagination';

const NotasFiscais: React.FC = () => {

    const [notasFiscais, setNotas] = useState<NotasFiscaisDTO[]>([]);
    const currentlyUser = useContext(userContext);
    const { Codcliente, Codempresa } = currentlyUser.user;
    const [itensPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(-1);
    const [currentItens, setCurrentItens] = useState<NotasFiscaisDTO[]>([]);
    const pages = Math.ceil(notasFiscais.length / itensPerPage);
    const startIndex = currentPage * itensPerPage;
    const endIndex = startIndex + itensPerPage;

    useEffect(() => {
        setCurrentItens(notasFiscais.slice(startIndex, endIndex));
    }, [currentPage]);

    function ConsultaNotasFiscais(filtro: any) {
        // Busca os pedidos da API
        api.post(`api/cliente/consultanotasfiscais`, filtro).then((response) => {
            const notasFiscais = response.data;
            setNotas(notasFiscais);
            setCurrentPage(0);
            setCurrentItens(notasFiscais.slice(startIndex, endIndex));
        });
    }

    // FILTRO DAS DATAS
    var dataInitial = new Date();
    var dia = String(dataInitial.getDate()).padStart(2, '0');
    var mes = String(dataInitial.getMonth() + 1).padStart(2, '0');
    var ano = dataInitial.getFullYear();
    var dataAnterior = ano + '-' + mes + '-' + dia;

    var dataFinal = new Date();
    var dia = String(dataFinal.getDate()).padStart(2, '0');
    var mes = String(dataFinal.getMonth() + 1).padStart(2, '0');
    var ano = dataFinal.getFullYear();
    var dataAtual = ano + '-' + mes + '-' + dia;

    function FiltroNotas(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let formData = new FormData(event.currentTarget);
        let dataInicio = formData.get("deData") as string;
        let dataFim = formData.get("ateData") as string;

        var filtro = {
            Codcliente: Codcliente,
            Codempresa: Codempresa,
            datini: dataInicio,
            datfim: dataFim,
        }

        ConsultaNotasFiscais(filtro);
    }

    useEffect(() => {
        var filtro = {
            Codcliente: Codcliente,
            Codempresa: Codempresa,
            Codnota: 0,
            Nronota: 0,
            Datnota: "",
            Valortotal: 0,
            Nomoperacao: "",
            Dsccondicao: "",
            Dscnfe_chave: "",
        }

        ConsultaNotasFiscais(filtro);

    }, [currentPage]);

    return (
        <Container>
            <Notas>
                <Sidebar />
                <div className="conteudo">
                    <div className="tituloNotas">
                        Lista de Notas Fiscais
                    </div>
                    <div className="periodoData">
                        <p className='periodoDatas'>Período</p>
                        <div className='formsFiltro'>
                            <form onSubmit={FiltroNotas} className="datas">
                                <div className='dats'>
                                    <label htmlFor="deData">De:</label>
                                    <input type="date" id="deData" name="deData" defaultValue={dataAnterior} />
                                    <label htmlFor="ateData">Até:</label>
                                    <input type="date" id="ateData" name="ateData" defaultValue={dataAtual} />
                                </div>
                                <div className='botoesFiltros'>
                                    <button type='submit' className="btn-filtrar">Filtrar</button>
                                    {/* <button type='submit' className="btn-filtrar">Exportar PDF</button>
                                    <button type='submit' className="btn-filtrar">Exportar XML</button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr className='camposPrincipais'>
                                <th></th>
                                <th className='valornota'>Nro da Nota</th>
                                <th className='valornota'>Data da Nota</th>
                                <th className='valornota'>Valor da Nota</th>
                                <th>Natureza de Operação</th>
                                <th className='condicao'>Condição de Pagto</th>
                            </tr>
                            {
                                currentItens.length === 0 ?
                                    // Se não vier nada da API, exibe uma mensagem de aviso
                                    <tr><td colSpan={5}><p>Nenhuma nota fiscal encontrada.</p></td></tr>
                                    :
                                    currentItens.map((notaFiscal: NotasFiscaisDTO, index: any) => {
                                        return (
                                            <tr key={index}>
                                                <td><input type="radio" value="0" name="radioButton" id="radioButton" /></td>
                                                <td className='camposNota'>{notaFiscal.Nronota == null ? 0 : notaFiscal.Nronota}</td>
                                                <td className='camposNota'>{formatarData(notaFiscal.Datnota)}</td>
                                                <td className='camposNota'>{formatarValores(notaFiscal.Valortotal == null ? 0 : notaFiscal.Valortotal)}</td>
                                                <td className='camposNota'>{notaFiscal.Nomoperacao}</td>
                                                <td className='condicao'>{notaFiscal.Dsccondicao}</td>
                                            </tr>
                                        )
                                    })
                            }
                        </thead>
                    </table>
                    {currentItens.length === 0 ? null : <Pagination pages={pages} setCurrentPaged={setCurrentPage} currentPage={currentPage} />}
                </div>
            </Notas>
        </Container>
    )
};

export default NotasFiscais;
