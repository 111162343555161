import * as React from 'react';
import { Container, ContainerFlutuante, Tabela, TituloSecao } from './styles';
import { Link } from 'react-router-dom';
import api from '../../service/api';
import { SolicitacaoAcessoDTO } from '../../DTOs/SolicitacaoAcessoDTO';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useToast } from '../../hooks/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faUserXmark } from '@fortawesome/free-solid-svg-icons';
import userContext from '../../Context/UserContext';

const SolicitacaoAcesso: React.FC = () => {
  const [solicitacoesLista, setSolicitacoesLista] = useState<SolicitacaoAcessoDTO[]>([]);
  const currentlyUser = useContext(userContext);
  const { addToast } = useToast();

  useEffect(() => {
    api.get(`api/business/usuario/solicitacaoacesso`).then((response) => {
    
      // Desestrutura o objeto da API com somente os campos necessários na interface
      // e formata o valor para exibição
      const solicitacoes: SolicitacaoAcessoDTO[] = response.data;
      // Seta os produtos no state para usar na página
      setSolicitacoesLista(solicitacoes);
  
    });

  }, []);

  const RecusarAcesso = useCallback((codusuario: number) => {
    
    try {
      let negarAcesso ={
        codusuario: codusuario,
        codusuarioversa: currentlyUser.user.Codusuarioversa,
      };
      api.post(`api/business/usuario/negaracesso`, negarAcesso);

      addToast({
        type: 'success',
        title: 'Solicitação negada com sucesso'
      });

    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao negar solicitação',
      });
    }
  }, [solicitacoesLista, addToast]);
  

  return (
    <Container>
      <TituloSecao>
       Solicitações de Acesso 
      </TituloSecao>
   <ContainerFlutuante>
      <Tabela>
        <thead>
          <tr>
            <th>Código</th>
            <th>Nome</th>
            <th>Email</th>
            <th>Pessoa</th>
            <th>Aceitar</th>
            <th>Negar</th>
          </tr>
        </thead>
        <tbody>
          {solicitacoesLista?.map((item: SolicitacaoAcessoDTO, index: any) => {
          return( 
          <tr key={index}>
              <td>{item.Codusuario}</td>
              <td>{item.Nome}</td>
              <td>{item.Email}</td>
              <td>{item.Indcpf === 0 ? "CNPJ": "CPF"}</td>
              <td className='icones'><Link to={`/aceitaracesso/${item.Codusuario}`}><FontAwesomeIcon icon={faUserCheck} size={"1x"}></FontAwesomeIcon></Link></td>
              <td className='icones'><span onClick={() => RecusarAcesso(item.Codusuario)}><FontAwesomeIcon icon={faUserXmark} size={"1x"}></FontAwesomeIcon></span></td>
            </tr>
          )})}
        </tbody>
      </Tabela> 
      </ContainerFlutuante>
    </Container>  
  )
};

export default SolicitacaoAcesso;

