import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: Verdana, Arial, sans-serif;
        font-size: 14px;
        color: #555555;
        height: 100%;
    `;

export const ContainerForm = styled(Container)`
        height: 720px;
        width: 100%;
        background-size: cover;
        background-color: #fafbfc;
        margin-top: 50px;

        @media(max-width: 923px){
            height: 100%;
        }
    `;

interface ICampos {
    width?: string
}

export const Formulario = styled.form`
        display: flex;
        flex-direction: column;
        margin: 0px auto 24px;
        width: 400px;
        padding: 32px 40px;
        background: rgb(255, 255, 255);
        border-radius: 3px;
        box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
        box-sizing: border-box;
        color: rgb(94, 108, 132);

    @media(max-width: 923px){
        width: 100%;
        padding: 10px;
        margin-top: 2rem;
        margin-bottom: 2rem;
        }
    `;

export const Campos = styled.div<ICampos>`
    list-style: none;
    padding: 5px;
    display: flex;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    color: black;
    width: ${props => props.width};
   
    p{
        color: red;
    }

    label{
        font-size: 13px;
        color: #555555;
    }

    .infos{
        font-size: 13px;
        color: red;
        text-align: center;
        width: 100%;
    }
    `;

export const Cabecalho = styled.div`
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 10px;
        color: rgb(94, 108, 132);   
    `;

export const SubtituloLogin = styled.div`
        font-size: 18px;
        margin: 0;
        padding: 0;
    `;

export const InputLogin = styled.div`
        font-style: normal;
        font-weight: 400;
        line-height: 1.42857142857143;
        text-decoration-skip-ink: auto;
        margin: 0;
        padding: 0;
        -webkit-box-align: center;
        align-items: center;
        margin-bottom: 5px;
        
        background-color: var(--ds-background-input,#FAFBFC);
        border: 0;
        color: var(--ds-text,#091E42);
        cursor: text;
        box-sizing: border-box;
        display: flex;
        flex: 1 1 100%;
        font-size: 14px;
        -webkit-box-pack: justify;
        justify-content: space-around;
        max-width: 100%;
        overflow: hidden;
        transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
        overflow-wrap: break-word;
        vertical-align: top;
        pointer-events: auto;

    input, select{
        text-decoration-skip-ink: auto;
        overflow-wrap: break-word;
        pointer-events: auto;
        background-color: transparent;
        border-color: var(--ds-border,#DFE1E6);
        border-radius: 3px;
        border-width: 2px;
        border-style: solid;
        box-sizing: border-box;
        color: inherit;
        cursor: inherit;
        font-size: 14px;
        min-width: 0px;
        outline: none;
        width: 100%;
        line-height: 1.42857;
        padding: 8px 6px;
        height: 2.57em;
    }
    input:hover{
        background-color: var(--ds-border,#eceaeaf6);
    }
    select:hover{
        background-color: var(--ds-border,#eceaeaf6);
        cursor: pointer;
    }
    input:focus, select:focus{
        background-color: transparent;
        border-color: var(--ds-border,#0052cc);
    }
    `;

export const InputRadioLogin = styled.div`
display: flex;
flex: 1 1 100%;
font-size: 14px;

    `;

export const Radio = styled.div`
    display: flex;
    flex: 1 1 100%;
    font-size: 14px;
    -webkit-box-pack: justify;
        `;

export const Botao = styled.button`
    text-decoration-skip-ink: auto;
    -webkit-box-align: baseline;
    align-items: baseline;
    border-width: 0px;
    border-radius: 3px;
    box-sizing: border-box;
    display: inline-flex;
    font-size: inherit;
    font-style: normal;
    font-family: inherit;
    font-weight: 500;
    max-width: 100%;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    white-space: nowrap;
    background: var(--ds-background-brand-bold,#0052CC);
    cursor: pointer;
    padding: 0px 10px;
    vertical-align: middle;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    color: var(--ds-text-inverse,#FFFFFF)  !important;
    height: 40px !important;
    line-height: 40px !important;
    margin-top: 12px;

    &:hover {
    background: #0c6dff;
    }
    `;

export const RodapeLogin = styled.div`
    font-style: normal;
    font-weight: 400;
    text-decoration-skip-ink: auto;
    margin: 0;
    padding: 0;
    color: rgb(107, 119, 140);
    padding-top: 16px;
    margin-top: 32px;
    border-top: 1px solid rgb(213, 216, 222);
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    `;

export const ListaRodapeLogin = styled.ul`    
    margin: 12px 0 0;
    margin-top: 0;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    list-style: none;
    padding: 0px;
    `;

export const LinksLogin = styled(Link)`
        color: var(--ds-link,#0052cc);
        text-decoration: none;
        margin: 0px 5px;
        text-align:center;  
    &:hover {
        text-decoration: underline;
    }`;