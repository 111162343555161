import styles from './Pagination.module.css';
import * as AiIcons from 'react-icons/ai';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

interface PaginationProps {
    currentPage: number;
    setCurrentPaged: (page: number) => void;
    pages: number;
}

function Pagination(props: PaginationProps) {

    const { currentPage, setCurrentPaged, pages } = props;

    const handleVoltar = () => {
        if (currentPage > 0) {
            setCurrentPaged(currentPage - 1);
        }
    }

    const handleVoltarInicio = () => {
        if (currentPage > 0) {
            setCurrentPaged(0);
        }
    }

    const handleAvancar = () => {
        if (currentPage < pages - 1) {
            setCurrentPaged(currentPage + 1);
        }
    }

    const handleAvancarFinal = () => {
        if (pages > 0) {
            setCurrentPaged(pages - 1);
        }
    }

    const handleMarcar = (index: number) => {
        setCurrentPaged(index);
    }

    const paginationLinks = useMemo(() => {
        const qtdApresenta = 6;
        const inicio = Math.max(currentPage - 2, 0);
      
        const final = Math.min(inicio + qtdApresenta - 1, pages);

        const links = [];


        for (let i = inicio; i < final; i++) {

            const isActive = i === currentPage;
           
            // if (currentPage == 0 ) {
            //     setCurrentPaged(0);
            // } 
           
            links.push(
                <Link key={i} 
                className={isActive ? styles.paginatorPageActive : styles.paginatorPage} 
                onClick={() => handleMarcar(i)} 
                to={`?${new URLSearchParams({
                    page: (i + 1).toString(),
                })}`} >
                    {i + 1}
                </Link>
            );
        }

        return links;
    }, [currentPage, pages]);

    return (
        <div className={styles.links}>
            <button className={styles.paginator} onClick={handleVoltarInicio}>
                <AiIcons.AiOutlineBackward />
            </button>
            <button className={styles.paginator} onClick={handleVoltar}>
                <AiIcons.AiOutlineCaretLeft />
            </button>
            {paginationLinks}
            <button className={styles.paginator} onClick={handleAvancar}>
                <AiIcons.AiOutlineCaretRight />
            </button>
            <button className={styles.paginator} onClick={handleAvancarFinal}>
                <AiIcons.AiOutlineForward />
            </button>
            {/* <span>
        Página{' '}
        <strong>
          {currentPage + 1} de {pages}
        </strong>
      </span> */}
        </div>
    );
}

export default Pagination;
