import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import { Container } from './styles';
import { ToastMessage, useToast } from '../../../hooks/toast';

interface ToastProps {
  message: ToastMessage;
  style: object;
}

const icons = {
  info: <FontAwesomeIcon icon={faInfoCircle} size={"3x"} />,
  error: <FontAwesomeIcon icon={faExclamationCircle} size={"3x"} />,
  success: <FontAwesomeIcon icon={faCheckCircle} size={"3x"} />,
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [message.id, removeToast]);

  return (
    <Container
      type={message.type}
      hasdescription={Number(!!message.description)}
      style={style}
    >
      {icons[message.type || 'info']}
      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
        <button
          onClick={() => {
            return removeToast(message.id);
          }}
          type="button"
        >
          <FontAwesomeIcon icon={faTimesCircle} size={"2x"} />
        </button>
      </div>
    </Container>
  );
};

export default Toast;
