import styled from 'styled-components';

export const Container = styled.div`
  color: #000000;
  top: 100px;
  margin-bottom: 4rem;

  @media (max-width: 923px) {
    margin-bottom: 0;
  }
`;

export const TituloSecao = styled.h3`
  padding: 20px;
  color: #313131;
  font-size: 26px;
  text-align: left;
  margin-bottom: 12px;
`;

export const Return = styled.div`
.retornar a{
    color: rgb(163, 162, 162);
}

.retornar a:hover{
    color: #423e3e;
    transition: 1s;
}
`;

export const Vitrine = styled.div`
  display: flex;

  @media(max-width: 923px){
    justify-content: center;
  }

  p {
    margin: 0;
    font-size: 14px;
  }

  .folderTotal {
    min-width: 320px;
    max-width: 320px;
    min-height: 350px;
  }

  .capaLivroETitulo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
  }

  .imgCapa {
    margin-bottom: 1rem;
    height: 250px;
    width: 100%;
    min-height: 250px;
    min-width: 100%;
  }

  .imagemCapa {
    width: 100%;
    min-height: 350px;
  }

  .tituloLivro {
    margin-bottom: 1rem;
    text-align: center;
    font-size: 14px;
    color: #111;
  }

  .infosLivros {
    display: flex;
    margin: 0;
    padding: 0;
  }

  .valorEDesconto {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .valorProduto {
    font-size: 18px;
    font-weight: 800;
  }

    .adcCarrinho{
        background-color: var(--botaoAdicionarCart-bg-color);
        color: rgb(255, 255, 255);
        cursor: pointer;
        border: none;
        height: 50px;
        padding: 1px;
    }

    .adcCarrinhoDisable{
      background-color: darkgray;
        color: rgb(255, 255, 255);
        cursor: not-allowed;
        border: none;
        height: 50px;
        padding: 1px;      
    }

  .aumentarEcomprar {
    display: flex;
    width: 30%;
    justify-content: space-between;
    margin-top: 10px;
    height: 50px;
    /* box-shadow: 0 12px 15px rgb(0 0 0 / 15%); */
  }

  .inputNumeros {
    width: 20%;
    
  }

  .contadorLivros {
    width: 100%;
    /* height: 100%; */
    font-size: 14px;
    border: none;
    padding: 1px;
    text-align: center;
    background: #eee;
    line-height: 50px;
  }

  .buttonAdc {
    width: 75%;
  }

  .adcCarrinho {
    width: 100%;
    height: 100%;
  }

  .adcCarrinhoDisable{
    width: 100%;
    height: 100%;
    }
`;

export const ProdutoDetails = styled.div`

        a{
            color: #111;
        }

        .detalhesProd{
            font-size: 30px;
            font-weight: bold;
            color: #313131;
        }

        .tudoProduto{
            margin-left: 4rem;
        }

        .tituloProduto{
            width: 100%;
            height: 50px;
            margin-bottom: 2rem;
            margin-top: 4rem;
        }

        .prodTitulo{
            font-weight: bold;
            margin-block-start: 0;
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 10px;
            width: 100%;
        }

        .imagem-produto{
            width: 450px;
        }

        .imagemInformacoes{
            display: flex;
            margin-bottom: 1rem;
        }

        .infosProduto{
            margin-left: 2rem;
            width: 100%;
            /* max-width: 450px; */
        }

        .infosProd{
            width: 30%;
        }

        .infosFicha{
            width: 60%;
        }

        .detalhes{
            display: flex;
            justify-content: space-between;
        }
        
        ul li{
            list-style: none;
        }

        ul{
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            margin-bottom: 10px;
        }

        .campo{
            font-weight: bold;
            font-size: 14px;
        }

        .campoDetalhe{
            color: #6c757d;
        }

        .dePor{
            display: flex;
            flex-direction: row;
        }

        .valor{
            color: #6c757d;
            font-size: 12px;
            margin-top: 1rem;
            margin-bottom: 1rem;
            text-decoration: line-through;
        }

        .por{
            color: #6c757d;
            font-size: 12px;
            margin-top: 1rem;
            margin-bottom: 1rem;
            margin-left: 2px;
        }

        .valorFinal{
            font-size: 30px;
            font-weight: bold;
            color: #313131;
            margin-bottom: 1rem;
        }

        .adicionarProduto{
            /* background-color: green; */
            display: inline-block;
            cursor: pointer;
            color: #ffffff;
            font-family: Arial;
            font-weight: bold;
            padding: 13px 32px;
            text-decoration: none;
            margin-bottom: 1rem;
            width: 90%;
            text-align: center;
        }

        .sinopseFicha{
            width: 100%;
            display: flex;
            margin-right: 4.5rem;
        }

        .sinopse{
            width: 60%;
            margin-top: 2rem;
            margin-left: 4.5rem;
        }

        .Psinopse{
            font-size: 16px;
            font-weight: bold;
            color: #000000;
            margin-bottom: 1rem;
        }

        .Psinopsee{
            font-size: 16px;
            font-weight: bold;
            color: #000000;
            margin-bottom: 1rem;
        }

        .txtSinopse{
            color: #111;
            font-size: 14px;
            width: 90%;
            text-align: justify;
        }

        .ficha{
            display: flex;
            width: 40%;
            float: right;
            margin-top: 2rem;
        }

        .camposFicha{
            width: 80%;
        }

        @media(max-width: 923px){

            .aumentarEcomprar{
                width: 50%;
            }
            
            .tudoProduto{
                margin-left: 0;
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            .imagemInformacoes{
                width: 100%;
                display: flex;
                flex-direction: column;
            }

            .imagem-produto{
                width: 100%;
            }

            .infosProduto{
                margin-left: 0;
            }

            .infosProd{
                width: 100%;
            }

            .titulo-produto, .dePorValores, .sinopse, .sinopseFicha{
                margin-left: 15px;
                margin-right: 15px;
            }

            .txtSinopse{
                width: 100%;
            }

            .sinopse{
                width: 91%;
            }

            .sinopseFicha{
                width: 91%;
                display: flex;
                flex-direction: column;
            }
            
            .ficha{
                margin-left: 0;
                width: 100%;
            }

            .camposFicha{
                width: 100%;
            }

            .infosFicha{
                width: 100%;
            }   

            .detalhesProd{
                display: flex;
                justify-content: center;
            }
        }
`;

