import { useContext, useEffect, useState } from "react";
import cartContext from "../Context/CartContext";
import { TotaisDTO } from "../DTOs/TotaisDTO";


const useCalculaTotais = () => {
    const currentlyCart = useContext(cartContext);
    const [totais, setTotais] = useState<TotaisDTO>({
        qtdDisponivel: 0,
        vlrDisponivel: 0,
        qtdEncomenda: 0,
        vlrEncomenda: 0,
        qtdTotal: 0,
        vlrTotal: 0
    });

    useEffect(() => {
        let qtdDisponivelLocal = 0;
        let qtdEncomendaLocal = 0;
        let qtdTotalLocal = 0;
        let vlrDisponivelLocal = 0;
        let vlrEncomendaLocal = 0;
        let vlrTotalLocal = 0;

        for (let i = 0; i < currentlyCart.cart.Produtos.length; i++) {

            // Lê o produto atual
            let produto = currentlyCart.cart.Produtos[i];

            // Calcula o total
            if (produto.isDeleted === false) {
                if (produto.Qtd) {
                    if (produto.Qtdestoque > 0 ) {
                        if(produto.Qtd <= produto.Qtdestoque){
                            qtdDisponivelLocal += produto.Qtd;
                            vlrDisponivelLocal += produto.VlrTotal;
                        }else{
                            qtdDisponivelLocal += produto.Qtd - (produto.Qtd - produto.Qtdestoque);
                            vlrDisponivelLocal += (produto.VlrTotal / produto.Qtd) * produto.Qtdestoque;
                           
                            qtdEncomendaLocal += (produto.Qtd - produto.Qtdestoque);
                            vlrEncomendaLocal += (produto.VlrTotal / produto.Qtd) * (produto.Qtd - produto.Qtdestoque);
                        }

                    } else {
                        qtdEncomendaLocal += produto.Qtd - produto.Qtdestoque;
                        vlrEncomendaLocal += produto.VlrTotal;
                    }

                    qtdTotalLocal = qtdDisponivelLocal + qtdEncomendaLocal;
                    vlrTotalLocal = vlrDisponivelLocal + vlrEncomendaLocal;
                }

            }
        }

        setTotais({
            qtdDisponivel: qtdDisponivelLocal,
            vlrDisponivel: vlrDisponivelLocal,
            qtdEncomenda: qtdEncomendaLocal,
            vlrEncomenda: vlrEncomendaLocal,
            qtdTotal: qtdTotalLocal,
            vlrTotal: vlrTotalLocal
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentlyCart.cart.VlrTotal, currentlyCart.cart.Produtos]);

    return { totais };
}

export default useCalculaTotais;