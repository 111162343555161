import styled from 'styled-components';

export const Container = styled.header`
  background-color: var(--footerBackground-bg-color);
  color: var(--footerTextos-bg-color);
  left: 0;
  right: 0;
  padding: 15px 10px;
  margin-top:auto;
  min-height: 50px;
  display: flex;

  a{
    color: var(--footerLinks-bg-color);
  }

  a:hover{
    text-decoration: underline;
  }

  nav ul li{
    line-height: 1.4;
    padding: 4px 0;
    font-size: 13px;
  }

  @media(min-width: 769px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  } 

  .camadaUmFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    }

  .footer{
    width: 100%;
    margin-top: 2rem;
  }
  
  .infos{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 3px;
  }

  .redesSociais{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1px;
  }

  .tituloPagto{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    width: 200px;
  }

  .iconsRedesSociais{
    display: flex;
    justify-content: space-around;
  }

  form {
    padding: 4px;
    display: flex;
  }

  form > input {
    border: none
  }

  form > button {
    background: var(--footerButton-bg-color);
    border: none;
    color: #fff;
    height: 35px;
    width: 100px;
  }

  .formasPagamentoCompraSegura{
    width: 60%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    /* margin-left: 180px; */
  }

  .formasPagto{
    display: flex;
    flex-direction: row;
    width: 50px;
    margin-bottom: 2rem;
  }

  .cartao{
    margin-right: 2px;
  }

  .whatsapp, .facebook, .instagram{
    width: 40px;
    color: white;
    cursor: pointer;
    overflow: hidden;
  }

  .whatsapp:hover, .facebook:hover, .instagram:hover{
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  @media(max-width: 923px){
    display: flex;
    flex-direction: column;

    .camadaUmFooter{
      display: flex;
      flex-direction: column;
    }

    .formasPagto{
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
    }

    .formasPagamentoCompraSegura{
      width: 100%;
      margin-left: 0;
    }

    .cartao{
      width: 35px;
    }

    .footer{
      display: flex;
      flex-direction: column;
    }

    nav{
      margin-bottom: 2rem;
    }
  }

`;

export const ContainerFooter = styled.header`
  display: flex;
  flex-direction: row;
  height: 10rem;
  justify-content: center;
  /* align-items: center; */

.imgSubFooter{
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-top: 2rem;
  width: 10%;
  max-width: 200px;
}

.textoFooter{
  width: 30%;
  margin-left: 3em;
  margin-top: 3em;
  color: #111;
  font-size: 13px;
}

@media(max-width: 923px){
  display: flex;
  flex-direction: column;

  .imgSubFooter{
    width: 200px;
  }

  .textoFooter{
    width: 100%;
    margin: 0;
    text-align: center;
  }
}

`;

export const TituloFooter = styled.h2`
  color: #fff;
  font-size: 18px;
  /* padding: 20px 0 10px; */
  margin: 0;

  @media(min-width: 769px) {
    /* font-size: 26px; */
    text-align: left;
  }
`;

export const FooterLogo = styled.div`
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-left: 3em;
  margin-top: 3em;

  @media (max-width: 768px) {
    position: inherit;
    width: 100%;
    cursor: pointer;
    max-width: 235px;
  }

  @media (min-width: 1024px) {
    max-width: 15%;
  }

  @media (min-width: 1200px) {
    max-width: 12%;
  }

  @media(max-width: 923px){
    margin: 0;
    width: 50%;
    padding-left: 5rem;
  }
`;

export const FooterListaContainer = styled.div`
  padding-left: 10px;
`;

export const FooterLista = styled.ul`
    color: #ffffff;
`;

export const FooterListaItem = styled.li`
    margin: 0 0 15px;
    font-size: 14px;

    @media(min-width: 769px) {
      margin: 15px 0;
      font-size: 16px;
    }
`;

export const FooterNewsletter = styled.div`
  padding-left: 10px;
  margin-right: 3rem;

  form {
    min-width: 280px;
    max-width: 350px;

    input {
      margin: 0 0 10px;
      width: -webkit-fill-available;
      padding: 10px 6px;
    }
  }

  @media(max-width: 923px){
    display: none;
  }
`;

export const FooterIcones = styled.div`
  padding-left: 10px;

  @media (min-width: 900px) and (max-width: 1057px) {
    margin: 0 10px 20px;
  }
`;

export const FooterIconesFormasPagamento = styled.div`
  width: 320px;

  @media (max-width: 350px){
    width: 300px;
  }
`;

export const FooterIconesFormasPagamentoGrid = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  width: 200px;

  @media(max-width: 923px){
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
`;

export const FooterIconesFormasPagamentoGridIcone = styled.div`
  max-width: 60px;
  margin: 0 2px;
`;

export const FooterIconesCompraSegura = styled.div`
  display: block;
  width: 100%;

  @media (min-width: 769px){
    width: 320px;
  }
`;

export const FooterIconesCompraSeguraIcone = styled.div`
  min-width: 130px;
  margin-right: 3rem;
  max-width: 350px;

  @media (min-width: 769px){
    min-width: 280px;
  }

  @media(max-width: 923px){
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
