import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  color: #000000;
  top: 100px;
  margin-bottom: 4rem;
  margin-top: 3rem;

  @media(max-width: 923px){
    margin-top: 0;
  }

  @media (max-width: 325px) {
    max-width: 300px;
  }
`;

export const ListaEditora = styled.div`
  display: flex;
  margin: 0 10px;
  justify-content: start;
  width: 100%;
  flex-wrap: wrap;
  /* max-width: 2560px; */
  align-items:center;

  @media(max-width: 923px){
    width: 100px;
    display: flex;
    flex-direction: column;  
    }
`;

export const TituloSecao = styled.h3`
  padding: 20px;
  color: #313131;
  font-size: 26px;
  text-align: left;
  margin-bottom: 12px;
`;

export const LinkEditora = styled(Link)`
    color: black;
    text-decoration: none;
    margin: 0px 5px; 
    font-weight: bold;

    &:hover{
      text-decoration: underline;
    }
`;

export const ContainerCabecalho = styled.div`
  display: flex;

  @media(max-width: 923px){
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const FormPesquisa = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;

  input{
    padding:10px;
  }
`;

