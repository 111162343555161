import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../assets/img/logoVersab2bbranco.png';
import {
    Container, Content, CabecalhoLogo, CabecalhoLogoImagem, CabecalhoBusca,
    CabecalhoBuscaLinha, CabecalhoUsuario, CabecalhoUsuarioDeslogado, MenuDesktop, MenuGeral, Dropdow
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPeopleArrows, faShoppingCart, faSignOut, faUser, faUserClock } from '@fortawesome/free-solid-svg-icons'
import Botao from '../Botao';
import { useContext, useEffect, useState } from 'react';
import api from '../../service/api';
import { MenuDTO, MenuRaizDTO } from '../../DTOs/MenuDTO';
import userContext from '../../Context/UserContext';
import pinContext from '../../Context/PinContext';
import { UsuarioLogadoDTO } from '../../DTOs/UsuarioLogadoDTO';

const Menu: React.FC = () => {
    // const [menu, setMenu] = useState<MenuRaizDTO>();
    const currentlyUser = useContext(userContext);
    let history = useHistory();

    useEffect(() => {
        const datAtual = new Date().getTime();
        let datAlteracao = datAtual;
        if (currentlyUser.user.Menu != null) {
            datAlteracao = new Date(currentlyUser.user.Menu.DataAlteracao).getTime() + 120 * 60000;
        }

        if (datAtual >= datAlteracao) {
            api.get(`api/business/menu`).then((response) => {

                // Desestrutura o objeto da API com somente os campos necessários na interface
                // e formata o valor para exibição
                const menus: MenuRaizDTO = response.data;

                // Seta os produtos no state para usar na página
                if (menus != null) {
                    var userLogado: UsuarioLogadoDTO = {
                        ...currentlyUser.user,
                        Menu: menus
                    }

                    localStorage.setItem('@VersaB2B:login', JSON.stringify(userLogado));
                    currentlyUser.onLogin(userLogado);
                }

            });
        }
    }, []);

    function SignOut() {
        localStorage.removeItem('@VersaB2B:login');
        localStorage.setItem('@VersaB2B:Autorizacao', "false");
        currentlyUser.onLogout();
        history.push("/");
    }

    return (
        <MenuGeral>
            <div className="container">
                <input type="checkbox" id="check" />
                <div className="nav-btn">
                    <div className="nav-links">
                        <ul>
                            {currentlyUser.user.Menu?.Menus.map((item: MenuDTO, index) => (
                                <li className="nav-link" key={index}>
                                    <a href={item.Link}>{item.Menu}</a>
                                    {item.Menus && (
                                        <div className="dropdown">
                                            <ul>
                                                {item.Menus.map((itemFilho, index) => (
                                                    <li className="dropdown-link" key={index}>
                                                        <a href={itemFilho.Link}>{itemFilho.Menu}</a>
                                                        {itemFilho.Menus && (
                                                            <div className="dropdown second">
                                                                <ul>
                                                                    {itemFilho.Menus.map((itemNeto, index) => (
                                                                        <li className="dropdown-link" key={index}>
                                                                            <a href={itemNeto.Link}>{itemNeto.Menu}</a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* LOGIN MENU RESPONSIVO */}
                    <div className="log-sign">
                        {currentlyUser.user.Codperfil === 0 ?
                            <>
                                <Link to="/">
                                    <FontAwesomeIcon icon={faUser} size={"2x"} />
                                </Link>
                                <Link to="/">
                                    &nbsp;&nbsp;&nbsp;
                                    Entrar ou&nbsp;
                                </Link>
                                <Link to="/criarconta">
                                    Cadastrar
                                </Link>
                            </>
                            :
                            <Dropdow>


                                <div className='nav-links'>
                                    <ul>
                                        {currentlyUser.user.Codperfil != 1 ?
                                            <ul className="nav-link">
                                                <li className='usuarioLogado'><FontAwesomeIcon className='iconUser' icon={faUser} size={"2x"} />Olá {currentlyUser.user.Nome}</li>

                                                <div className="dropdown">
                                                    <ul>
                                                        <li className="userSelecionado">{currentlyUser.user.Nomcliente ? currentlyUser.user.Nomcliente : "Cliente não selecionado"}</li>
                                                        <li className="dropdown-link"><a href="/listaclientes">Lista de Clientes</a></li>
                                                        <li className="dropdown-link"><a href="/minhaconta/meuspedidos">Minha Conta</a></li>
                                                        <li className="dropdown-link"><a href="/solicitacaoacesso">Permissões</a></li>
                                                        <li className="dropdown-link"><a href="">Configurações</a></li>
                                                        <li className="dropdown-link"><a href="" onClick={SignOut}>Logout</a></li>
                                                    </ul>
                                                </div>
                                            </ul>
                                            : null}

                                        {currentlyUser.user.Codperfil == 1 ?
                                            <ul className="nav-link">
                                                <li className='usuarioLogado'><FontAwesomeIcon className='iconUser' icon={faUser} size={"2x"} />Olá {currentlyUser.user.Nome}</li>

                                                <div className="dropdown">
                                                    <ul>
                                                        <li className="userSelecionado">{currentlyUser.user.Nomcliente ? currentlyUser.user.Nomcliente : "Cliente não selecionado"}</li>
                                                        <li className="dropdown-link"><a href="/minhaconta/meuspedidos">Minha Conta</a></li>
                                                        <li className="dropdown-link"><a href="" onClick={SignOut}>Logout</a></li>
                                                    </ul>
                                                </div>
                                            </ul>
                                            : null}
                                    </ul>
                                </div>

                                {/* <div className="iconUsuario">
                                    {currentlyUser.user.Codperfil != 1 ?
                                        <a href="/listaclientes" className="editar-usuario">
                                            <div className='links'>
                                                <p className="logoutUsuario">Lista de Clientes</p>
                                            </div>
                                        </a>
                                        : null}
                                </div>
                                <div className="editUsuario">
                                    <div className='links'>
                                        <p className="logoutUsuario" onClick={SignOut}>Logout</p>
                                    </div>
                                </div>  */}
                            </Dropdow>
                        }
                    </div>

                </div>

                <div className="hamburguer-menu-container">
                    {currentlyUser.user.Codusuario > 0 ?
                        <div className="hamburguer-menu">
                            <div></div>
                        </div>
                        : null
                    }
                </div>
            </div>
        </MenuGeral>
    )
}

export default Menu;