import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../../../componentes/Table/Table";
import { Publicidade } from "../../../DTOs/PublicidadeModeloDTO";
import { SortColumnDTO } from "../../../DTOs/SortColumnDTO";


type MyProps = {
  // using `interface` is also ok
  publicidade?: Array<Publicidade>;
  sortColumn?: SortColumnDTO;
  onSort: any;
  onDelete: Function;

};

class PublicidadeTable extends Component<MyProps> {
  chave = "Codpublicidade";
  columns = [
    {
      path: "Codpublicidade",
      label: "Código",
      class: "testeclass"

    },
    {
      path: "Dscpublicidade",
      label: "Nome",
      content: (publicidade: Publicidade) => <Link to={`/configuracao/publicidade/cdpublicidade/${publicidade.Codpublicidade}`}>{publicidade.Dscpublicidade}</Link>
    },
    {
      key: "delete",
      content: (publicidade: Publicidade) => (
        <button
          onClick={() => this.props.onDelete(publicidade.Codpublicidade)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrash} size={"1x"} className="pinEsquerdo" /> 
          {/* Excluir */}
        </button>
      )
    }
  ];

  handleSort = (sortColumn: SortColumnDTO) => {
    this.setState({ sortColumn });
  };



  render() {
    const { sortColumn, onSort, publicidade } = this.props;
    // const paginasEstaticas = this.getPagedData();

    return (
      <Table
        columns={this.columns}
        data={publicidade}
        sortColumn={sortColumn}
        onSort={onSort}
        chave={this.chave}
      />
    );
  }
}

export default PublicidadeTable;
