import styled from 'styled-components';
import 'react-confirm-alert/src/react-confirm-alert.css';

export const Container = styled.div`
  border: 1px solid #009b9a;
  font-size: 16px;
`;

export const BodyModal = styled.div`
  font-family: inherit;
  width: 400px;
  padding: 1em;
  text-align: left;
  background: #fff;
  border-radius: 0;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #009b9a;
`;

export const TituloModal = styled.h3`
  padding: 0.8em;
  color: #ED3237;
`;

export const TextoModal = styled.p`
  padding: 0.8em;
  font-weight: bold;
  color: #313131;
`;

export const BotoesModalContainer = styled.div`
text-align: right;
  padding: 0.7em 0;
`;

export const BotoesModal = styled.button`
  outline: none;
  background: #313131;
  border: none;
  display: inline;
  padding: 6px 18px;
  color: #fff;
  margin-right: 10px;
  border-radius: 0;
  font-size: 14px;
  font-weight: bold;
`;
