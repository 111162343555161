import styled from 'styled-components';

export const Container = styled.div`
  margin: auto;
    text-align: center;
    position: fixed;
    right: 1%;
    bottom: 30px;
    white-space: nowrap;
    top: 1%;
`;
