import React from "react";
import * as FaIcons from 'react-icons/fa';
import { SortColumnDTO } from "../../DTOs/SortColumnDTO";
import "./Tabela.css";


interface Columns {
  path: string;
  key: number;
  label: string;
}

type MyProps = {
  // using `interface` is also ok
  columns: Columns[];
  sortColumn: SortColumnDTO;
  onSort: any;
};


class TableHeader extends React.Component<MyProps> {
  raiseSort = (path: string) => {
    const sortColumn = { ...this.props.sortColumn };
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    this.props.onSort(sortColumn);
  };

  renderSortIcon = (column: Columns) => {
    const { sortColumn } = this.props;

    if (column.path !== sortColumn.path) return null;
    if (sortColumn.order === "asc") return <FaIcons.FaAngleUp />;
    return <FaIcons.FaAngleDown />;
  };

  render() {
    return (
      <thead className="headerTable">
        <tr>
          {this.props.columns.map((column: Columns) => (
            <th
              className="clickable"
              key={column.path || column.key}
              onClick={() => this.raiseSort(column.path)}
            >
              {column.label} {this.renderSortIcon(column)}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}


export default TableHeader;