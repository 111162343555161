import * as React from 'react';
import { Container, TituloSecao } from './styles';
import Grid from '../../componentes/Grid';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import userContext from '../../Context/UserContext';

const Autor: React.FC = () => {
  const {id, name}: any = useParams();
  const currentlyUser = useContext(userContext);
  const {Codcliente, Codempresa} = currentlyUser.user;

 
  return (
    <Container>
      <TituloSecao>
       Autor {id} - {decodeURIComponent(name)} 
      </TituloSecao>
        <Grid Autor={id} Codcliente={Codcliente} Codempresacli={Codempresa}/>
    </Container>
  )
};

export default Autor;
