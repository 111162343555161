import styled from 'styled-components';

export const Container = styled.div`
  color: #000000;
  margin-bottom: 4rem;
  margin-top: 3rem;

  @media (max-width: 923px){
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 325px) {
    max-width: 300px;
  }
`;


