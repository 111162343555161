import * as React from 'react';
import { useContext } from 'react';
import userContext from '../../Context/UserContext';
import { Container } from './styles';
import Carrossel from '../Carrossel';

const Vitrine: React.FC = () => {
  const currentlyUser = useContext(userContext);
  const { Codcliente, Codempresa } = currentlyUser.user;

  return (
    <Container>
      <Carrossel titulo={"Lançamentos"} Lancamento={1} Codcliente={Codcliente} Codempresacli={Codempresa} />
      {/* <TituloSecao>
        Mais Vendidos
      </TituloSecao> */}
      <Carrossel titulo={"Mais Vendidos"} MaisVendidos={1} Codcliente={Codcliente} Codempresacli={Codempresa} />
      {/* <TituloSecao>
        Pré Venda
      </TituloSecao> */}
      <Carrossel titulo={"Pré Venda"} PreVenda={1} Codcliente={Codcliente} Codempresacli={Codempresa} />
    </Container>
  )
};

export default Vitrine;
