import * as React from 'react';
import { Container, ContainerCabecalho, FormPesquisa, LinkEditora, ListaEditora, TituloSecao } from './styles';
import { useEffect, useState } from 'react';
import api from '../../service/api';
import { BusinessProdutoDTO, CarrinhoProdutoDTO } from '../../DTOs/produtoDTO';
import { ContainerPagination, GridItem } from '../../componentes/Grid/styles';
import Pagination from '../../componentes/Paginacao/Pagination';
import Botao from '../../componentes/Botao';
import { EditoraDTO } from '../../DTOs/EditoraDTO';
import { useLocation } from 'react-router-dom';

const EditoraList: React.FC = () => {
  const [Editora, setEditora] = useState<EditoraDTO[]>([]);
  const [itensPerPage] = useState(54);
  const [currentPage, setCurrentPage] = useState(-1);
  const [filtro, setFiltro] = useState("");
  const [currentItens, setCurrentItens] = useState<EditoraDTO[]>([]);
  const location = useLocation();

  const pages = Math.ceil(Editora.length / itensPerPage);
  const startIndex = currentPage * itensPerPage;
  const endIndex = startIndex + itensPerPage;

  useEffect(() => {
    setCurrentItens(Editora.slice(startIndex, endIndex));
  }, [currentPage, filtro]);

  useEffect(() => {

    // Busca as editoras da API
    api.get(`/api/business/editora`).then((response) => {

      // Desestrutura o objeto da API com somente os campos necessários na interface
      // e formata o valor para exibição
      const editoras = response.data;

      // Seta os produtos no state para usar na página
      setEditora(editoras);

      let queryString = location.search;
      let params = new URLSearchParams(queryString);
      let pageValue = params.get('page');
      console.log(pageValue);
      if (pageValue != null) {
        setCurrentPage(Number(pageValue) - 1);
      } else {
        setCurrentPage(0);
      }
    });
  }, []);


  function func_str(arr1: EditoraDTO[], b: string) {
    return arr1.filter(function (ele_val) {
      return ele_val.Nomeditora.toLowerCase().indexOf(b.toLowerCase()) !== -1
    })
  }

  useEffect(() => {
    if (filtro != "") {
      setCurrentItens(func_str(Editora, filtro));
    }
  }, [filtro, location]);

  function Pesquisa(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let formData = new FormData(event.currentTarget);
    let txtfiltro = formData.get("txtfiltro") as string;
    if (txtfiltro != "") {
      setFiltro(txtfiltro);
    }

  }

  function ListaEditoras() {
    return (
      <ListaEditora>
        {
          currentItens.length === 0 ?
            // Se não vier nada da API, exibe uma mensagem de aviso
            <p>Nenhuma editora encontrada.</p>
            :
            currentItens.map((editora: any, index: any) => {
              return (
                <GridItem key={index}>
                  <LinkEditora to={`/editora/${editora.Codeditora}/${encodeURIComponent(editora.Nomeditora)}`}>{editora.Nomeditora}</LinkEditora>
                </GridItem>
              )
            })
        }
      </ListaEditora>
    )
  };

  return (
    <Container>
      <ContainerCabecalho>
        <TituloSecao>
          Editoras
        </TituloSecao>
        <FormPesquisa onSubmit={Pesquisa}>
          <input type="text" id="iptBusca" placeholder="Pesquisar Editoras" title="Campo de busca." name="txtfiltro" value={filtro} onChange={event => setFiltro(event.target.value)} />
          <Botao containerStyle={{
            backgroundColor: "var(--headerButton-bg-color)", borderColor: "#fff",
            color: "#fff", borderWidth: 1, borderStyle: "solid",
            padding: 10, margin: 0, height: "inherit", fontWeight: "normal",
            fontSize: 16
          }}>Pesquisar</Botao>
        </FormPesquisa>
      </ContainerCabecalho>
      <ListaEditoras />

      {currentItens.length === 0 ? null :
        <ContainerPagination>
          <Pagination pages={pages} setCurrentPaged={setCurrentPage} currentPage={currentPage} />
        </ContainerPagination>}
    </Container>
  )
};

export default EditoraList;
