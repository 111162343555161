import React, { ButtonHTMLAttributes } from 'react';
import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  containerStyle?: object;
};

const Botao: React.FC<ButtonProps> = ({ children, loading, containerStyle = {}, ...rest }) => {
  return (
    <Container style={containerStyle} type="button" {...rest}>
      {loading ? 'Carregando' : children}
    </Container>
  );
};

export default Botao;
