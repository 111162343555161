import * as React from 'react';
import { Container } from './styles';
import { MinhaConta } from './main';
import { faAngleDown, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { PedidoItemDTO, PedidosDTO } from '../../DTOs/PedidoDTO';
import api from '../../service/api';
import Sidebar from './Sidebar';
import { formatarDataComHorario, formatarValores } from '../../helpers/valores';
import userContext from '../../Context/UserContext';
import PedidoDetalhes from './PedidoDetalhes';
import Pagination from '../../componentes/Paginacao/Pagination';

const MeusPedidos: React.FC = () => {

    const [pedidos, setPedidos] = useState<PedidosDTO[]>([]);
    const [produtos, setProdutos] = useState<PedidoItemDTO[]>([]);
    const currentlyUser = useContext(userContext);
    const { Codcliente } = currentlyUser.user;
    const [mostrarProdutos, setMostrar] = useState(false);

    const [itensPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(-1);
    const [currentItens, setCurrentItens] = useState<PedidosDTO[]>([]);
    const pages = Math.ceil(pedidos.length / itensPerPage);
    const startIndex = currentPage * itensPerPage;
    const endIndex = startIndex + itensPerPage;

    useEffect(() => {
        setCurrentItens(pedidos.slice(startIndex, endIndex));
    }, [currentPage]);

    // FILTRO DAS DATAS
    var dataInitial = new Date();
    var dia = String(dataInitial.getDate()).padStart(2, '0');
    var mes = String(dataInitial.getMonth() + 1).padStart(2, '0');
    var ano = dataInitial.getFullYear();
    var dataAnterior = ano + '-' + mes + '-' + dia;

    var dataFinal = new Date();
    var dia = String(dataFinal.getDate()).padStart(2, '0');
    var mes = String(dataFinal.getMonth() + 1).padStart(2, '0');
    var ano = dataFinal.getFullYear();
    var dataAtual = ano + '-' + mes + '-' + dia;


    function MostrarMais(codacerto: number) {

        var mostrar = document.getElementById(`mostrar${codacerto}`);

        if (!mostrarProdutos) {
            mostrar?.classList.add('activeProdutos');
            setMostrar(true);

        } else {
            mostrar?.classList.remove('activeProdutos');
            setMostrar(false);
        }
    }

    function ConsultaPedido(filtro: any) {
        api.post(`api/pedidos/`, filtro).then((response) => {
            const pedidos = response.data;
            setPedidos(pedidos);
            setCurrentPage(0);
            setCurrentItens(pedidos.slice(startIndex, endIndex));
        });
    }

    function FiltroPedido(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let formData = new FormData(event.currentTarget);
        let dataInicio = formData.get("deData") as string;
        let dataFim = formData.get("ateData") as string;
        let codSituacao = formData.get("codSituacao") as string;

        var filtro = {
            codcliente: Codcliente,
            datini: dataInicio,
            datfim: dataFim,
            codSituacao: codSituacao,
        }

        ConsultaPedido(filtro);
    }

    useEffect(() => {

        if(Codcliente > 0){
            var filtro = {
                codcliente: Codcliente,
                datini: "",
                datfim: "",
                codSituacao: "",
            }
            ConsultaPedido(filtro);    
        }
            }, [Codcliente]);

    return (
        <Container>
            <MinhaConta>

                <Sidebar />

                <div className="conteudo">
                    <div className="pedidoAtual">
                        Lista de pedidos registrados
                    </div>

                    <div className="periodoData">
                        <p className='periodoDatas'>Período do acerto</p>
                        <div className='formsFiltro'>
                            <form onSubmit={FiltroPedido} className="datas">
                                <div className='dataeStatus'>
                                    <div className='dats'>
                                        <label htmlFor="deData">De:</label>
                                        <input type="date" id="deData" name="deData" defaultValue={dataAnterior} />
                                        <label htmlFor="ateData">Até:</label>
                                        <input type="date" id="ateData" name="ateData" defaultValue={dataAtual} />

                                        <label htmlFor="codSituacao">Status:</label>
                                        <select name="codSituacao" id="codSituacao">
                                            <option id="">Selecione um status</option>
                                            <option id="0" value="0">Em preenchimento</option>
                                            <option id="1" value="1">A validar</option>
                                            <option id="2" value="2">Fechado</option>
                                            <option id="3" value="3">Em Validação</option>
                                            <option id="9" value="9">Excluído</option>
                                        </select>
                                    </div>
                                </div>
                                <button type='submit' className="btn-filtrar">Filtrar</button>
                            </form>
                        </div>
                    </div>

                    {
                        currentItens.length === 0 ?
                            // Se não vier nada da API, exibe uma mensagem de aviso
                            <p>Nenhum pedido encontrado.</p>
                            :
                            currentItens.map((pedido: PedidosDTO, index: any) => {
                                return (
                                    <section key={index}>
                                        <div className='tableMostrar'>
                                            <div className="dadosPedido">
                                                <table className='principais'>
                                                    <thead>
                                                        <tr className='camposPrincipais'>
                                                            <th className='colunaGhost'></th>
                                                            <th className='thClass'>Data</th>
                                                            <th className='thClass'>Pedido</th>
                                                            <th className='thClass'>Total</th>
                                                            <th className='status'>Status</th>
                                                        </tr>
                                                        <tr className='camposSecundarios'>
                                                            <td className='colunaGhost'></td>
                                                            <td className='thClass'>{formatarDataComHorario(pedido.Datinclusao)}</td>
                                                            <td className='thClass'>{pedido.Codacerto}</td>
                                                            <td className='thClass'>{formatarValores(pedido.Valor == null ? 0 : pedido.Valor)}</td>
                                                            <td className='status'> {pedido.Dscsituacao}
                                                                {
                                                                    (() => {
                                                                        switch (pedido.Codsituacao) {

                                                                            case 0:
                                                                                return <FontAwesomeIcon icon={faCircle} size={"1x"} className="iconStatus0" />
                                                                            case 1:
                                                                                return <FontAwesomeIcon icon={faCircle} size={"1x"} className="iconStatus1" />
                                                                            case 2:
                                                                                return <FontAwesomeIcon icon={faCircle} size={"1x"} className="iconStatus2" />
                                                                            case 3:
                                                                                return <FontAwesomeIcon icon={faCircle} size={"1x"} className="iconStatus3" />
                                                                            default:
                                                                                return null
                                                                        }
                                                                    })()
                                                                }
                                                            </td>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                            <div className="detalhes">
                                                <input className='inputArrow' onClick={() => MostrarMais(pedido.Codacerto)} type="checkbox" id={`mostrarMais${pedido.Codacerto}`} />
                                                <label htmlFor={`mostrarMais${pedido.Codacerto}`}> Detalhes
                                                </label>
                                                <label htmlFor={`mostrarMais${pedido.Codacerto}`} className='labelArrow'>
                                                    <FontAwesomeIcon icon={faAngleDown} size={"1x"} className="iconArrow" />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mostrar" id={`mostrar${pedido.Codacerto}`}>
                                            <table>
                                                <PedidoDetalhes Codacerto={pedido.Codacerto}/>
                                            </table>
                                        </div>

                                    </section>
                                )
                            })}
                {currentItens.length === 0 ? null : <Pagination pages={pages} setCurrentPaged={setCurrentPage} currentPage={currentPage} />}

                </div>
            </MinhaConta>
        </Container>
    )
};

export default MeusPedidos;
