import * as React from 'react';
import { Container, Lstpublicidade, TituloSecao } from '../styles';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import api from '../../../service/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useToast } from "../../../hooks/toast";
import { Publicidade } from '../../../DTOs/PublicidadeModeloDTO';
import PublicidadeTable from './publicidadeTable';
import { SortColumnDTO } from '../../../DTOs/SortColumnDTO';
import _ from 'lodash';
import { ContainerPagination } from '../../../componentes/Grid/styles';
import Pagination from '../../../componentes/Paginacao/Pagination';

const LstPublicidade: React.FC = () => {
    const { addToast } = useToast();
    const [publicidade, setPublicidade] = useState<Publicidade[]>([]);
    const [itensPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(-1);
    const [currentItens, setCurrentItens] = useState<Publicidade[]>([]);
    const pages = Math.ceil(publicidade.length / itensPerPage);
    const startIndex = currentPage * itensPerPage;
    const endIndex = startIndex + itensPerPage;
    const [sortColumn, setSortColumn] = useState<SortColumnDTO>({ path: "Dscpublicidade", order: "asc" });
    const [reload, setReload] = useState(false);

    function ListaPublicidade(filtro: any) {
        // Busca os links da API
        api.post(`api/businnes/getfiltropublicidade`, filtro).then((response) => {
            const publicidade = response.data;
            setPublicidade(publicidade);
            setCurrentPage(0);
            setCurrentItens(publicidade.slice(startIndex, endIndex));
            setReload(true);
        });
    }

    function FiltroPublicidade(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        let formData = new FormData(event.currentTarget);
        let codigo = formData.get("txtCodigo") as string;
        let nome = formData.get("txtNome") as string;

        var filtro = {
            Codpublicidade: codigo,
            Dscpublicidade: nome,
        }

        ListaPublicidade(filtro);
    }

    useEffect(() => {
        setCurrentItens(publicidade.slice(startIndex, endIndex));
    }, [currentPage]);

    const handleSort = useCallback((sortColumn: SortColumnDTO) => {
        setSortColumn(sortColumn);
    }, [sortColumn]);

    useEffect(() => {
        if (sortColumn != null && currentItens != undefined) {
            const sorted = _.orderBy(currentItens, [sortColumn.path], [sortColumn.order]);
            setCurrentItens(sorted);
        }
    }, [sortColumn]);

    useEffect(() => {
        var filtro = {
            Codpublicidade: 0,
            Dscpublicidade: "",
        }

        ListaPublicidade(filtro);

    }, [reload]);

    const handleExcluiPublicidade = useCallback((id?: number) => {
        try {

            let paginasCopia = [...currentItens];
            let paginasAtuais = paginasCopia.filter((item) => item.Codpublicidade !== id);

            setPublicidade(paginasAtuais);

            api.delete(`api/business/publicidade/${id}`);

            addToast({
                type: 'success',
                title: 'Publicidade excluída com sucesso!'
            });

            setReload(!reload);

        } catch (error) {
            addToast({
                type: 'error',
                title: 'Erro ao excluir a publicidade!'
            });
        }
    }, [reload, addToast]);

    return (
        <Container>
            <TituloSecao>
                Lista de Publicidades
            </TituloSecao>

            <Lstpublicidade>

                <form onSubmit={FiltroPublicidade}>
                    <div className="codCampo">
                        <p>Código</p>
                        <input type="text" className='txtCodigo' name='txtCodigo' />
                    </div>

                    <div className="nomCampo">
                        <p>Nome</p>
                        <input type="text" className='txtNome' name='txtNome' />
                    </div>

                    <div className='botoesFiltros'>
                        <Link to="/configuracao/publicidade/cdpublicidade"><button type='submit' className="btn-novo">Novo</button></Link>
                        <button type='submit' className="btn-novo">Pesquisar</button>
                    </div>
                </form>

                <PublicidadeTable
                    publicidade={currentItens}
                    sortColumn={sortColumn}
                    // onLike={this.handleLike}
                    onDelete={handleExcluiPublicidade}
                    onSort={handleSort}
                />
                <ContainerPagination>
                    {/* <Pagination pages={pages} setCurrentPaged={setCurrentPage} currentPage={currentPage} /> */}
                </ContainerPagination>
            </Lstpublicidade>
        </Container>
    )
};

export default LstPublicidade;
