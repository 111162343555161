import * as React from 'react';
import { useForm } from "react-hook-form";
import { CadastroPublicidade, Container, TituloSecao } from '../styles';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../service/api';
import { useContext, useEffect, useState } from 'react';
import { useToast } from '../../../hooks/toast';
import { CarrinhoProdutoDTO } from '../../../DTOs/produtoDTO';
import { formatarData } from '../../../helpers/valores';
import userContext from '../../../Context/UserContext';
import imageContext from '../../../Context/ImagesContext';

const CdLogo: React.FC = () => {
    const { addToast } = useToast();
    const { alterarLogo } = useContext(imageContext);
    const [produto, setPublicidade] = useState<CarrinhoProdutoDTO>();
    const [selectedFile, setSelectedFile] = useState<any>();
    const [pathResponse, setPathResponse] = useState<string>();
    const { register, handleSubmit, setValue } = useForm();
    const { id }: any = useParams();
    const isAddMode = !id;
    let history = useHistory();
    const currentlyImages = useContext(imageContext);
  
    useEffect(() => {
      const logoPath = localStorage.getItem('logoPath'); // Retrieve the logo path from localStorage
      if (logoPath) {
        setPathResponse(logoPath); // Update the local component state with the logo path
      }
    }, []);
  
    function onChangeFile(event: any) {
      const target = event.target;
      if (target.files != null) {
        const file = target.files[0];
        setSelectedFile(file);
      }
    }
  
    function uploadImagem() {
      if (selectedFile == null) {
        addToast({
          type: 'error',
          title: 'Erro ao subir logo!'
        });
      } else {
        const formData = new FormData();
        formData.append('dataFile', selectedFile, selectedFile.name);
        return api
          .post(`api/configuracao/updatelogo`, formData)
          .then((response) => {
            const data = response.data;
            const pathFinal = '/images/' + data;
            localStorage.setItem('logoPath', pathFinal); // Store the logo path in localStorage
            setPathResponse(pathFinal); // Update the local component state
            addToast({
              type: 'success',
              title: 'Logo alterado com sucesso!'
            });
            window.location.reload();
            return data;
          })
          .catch((error) => {
            addToast({
              type: 'error',
              title: 'Erro ao trocar logo!'
            });
          });
      }
      history.push('/configuracao/layout');
    }
  
    const OnSubmit = async (data: any) => {
      const path = await uploadImagem();
      setPathResponse(path);
      history.push('/configuracao/layout');
    };
  
    return (
      <Container>
        <TituloSecao>Cadastro de Logo</TituloSecao>
  
        <CadastroPublicidade>
          <form onSubmit={handleSubmit(OnSubmit)}>
            <div className="campoImg">
              <p>Logo</p>
              <input type="file" className="inputImagem" name="file" onChange={onChangeFile} />
            </div>
  
            <div className="botoesFiltros">
              <button type="submit" className="btn-novo">
                Salvar
              </button>
            </div>
          </form>
        </CadastroPublicidade>
      </Container>
    );
  };
  
  export default CdLogo;

