import * as React from 'react';
import { Container, TituloSecao } from './styles';
import Grid from '../../componentes/Grid';
import { useParams } from 'react-router-dom';
import userContext from '../../Context/UserContext';
import { useContext } from 'react';

const PreVenda: React.FC = () => {
  const {id, name}: any = useParams();
  const currentlyUser = useContext(userContext);
  const {Codcliente, Codempresa} = currentlyUser.user;

  return (
    <Container>
      <TituloSecao>
       Pré Venda 
      </TituloSecao>
        <Grid PreVenda={1} Codcliente={Codcliente} Codempresacli={Codempresa}/>
    </Container>
  )
};

export default PreVenda;
