import * as React from 'react';
import Banner from '../../componentes/Carrossel/CarouselBanner/index';
import Dashboard from '../../componentes/Dashboard';
import Vitrine from '../../componentes/Vitrine';

const Home: React.FC = () => {
  return (
    <>
      <Banner />
      <Vitrine />
      <Dashboard />
    </>
  )
};

export default Home;
