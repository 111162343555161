import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../../../componentes/Table/Table";
import { PublicidadeModeloDTO } from "../../../DTOs/PublicidadeModeloDTO";
import { SortColumnDTO } from "../../../DTOs/SortColumnDTO";


type MyProps = {
  // using `interface` is also ok
  publicidade?: Array<PublicidadeModeloDTO>;
  sortColumn?: SortColumnDTO;
  onSort: any;
  onDelete: Function;

};

class PublicidadeModeloTable extends Component<MyProps> {
  chave = "Codpublicidademodelo";
  columns = [
    {
      path: "Codpublicidademodelo",
      label: "Código",
    },
    {
      path: "Dscpublicidademodelo",
      label: "Nome",
      content: (publicidade: PublicidadeModeloDTO) => <Link to={`/configuracao/publicidade/cdpublicidadeModelo/${publicidade.Codpublicidademodelo}`}>{publicidade.Dscpublicidademodelo}</Link>
    },

    // { path: "dailyRentalRate", label: "Rate" },
    // {
    //   key: "like",
    //   //   content: (movie: { liked: any; }) => (
    //   //     <Like liked={movie.liked} onClick={() => this.props.onLike(movie)} />
    //   //   )
    // },
    {
      key: "delete",
      content: (publicidade: PublicidadeModeloDTO) => (
        <button
          onClick={() => this.props.onDelete(publicidade.Codpublicidademodelo)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon={faTrash} size={"1x"} className="pinEsquerdo" /> 
          Excluir
        </button>
      )
    }
  ];

  handleSort = (sortColumn: SortColumnDTO) => {
    this.setState({ sortColumn });
  };



  render() {
    const { sortColumn, onSort, publicidade } = this.props;
    // const paginasEstaticas = this.getPagedData();

    return (
      <Table
        columns={this.columns}
        data={publicidade}
        sortColumn={sortColumn}
        onSort={onSort}
        chave={this.chave}
      />
    );
  }
}

export default PublicidadeModeloTable;
