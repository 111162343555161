import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
  }
  img {
    width: 100%;
  }

  body {
    -webkit-font-smoothing: antialisaed;
    font-family: "Roboto", sans-serif;
    height:100vh;
    margin:0;
  }

  button {
    cursor: pointer;
  }
`;
