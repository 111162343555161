import styled from "styled-components";

export const Container = styled.div`
  margin: 160px auto 0;
  justify-content: center;
  width: 100%;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-content: stretch;
  }

  @media (min-width: 769px) {
    margin: 40px auto 0;
  }

  @media (min-width: 1000px) {
    margin: 36px auto 0;
  }
`;

export const ImagemBanner = styled.div`
  img {
    margin: 0 auto;

    @media (min-width: 1200px) {
      max-width: 100%;
      width: 100%;
      height: auto;
    }

    @media (max-width: 923px) {
      height: 8rem;
    }
  }
`;

export const CabecalhoBuscaResponsivo = styled.div`
  display: none;

  @media (max-width: 923px) {
    display: block;
  }
  @media (min-width: 769px) {
    width: 100%;
  }
`;

export const CabecalhoBuscaLinhaResponsivo = styled.div`
  width: inherit;

  form {
    display: flex;
    width: 100%;

    #iptBusca {
      height: 35px;
      width: 100%;
      padding: 0 12px;
      border: 1px solid #fff;
      color: #727272;
      border-radius: 0;
      background-color: var(--barraPesquisa-bg-color);

      &:focus {
        outline: none;
      }
    }

    button {
      width: 30%;

      span {
        font-size: 16px;
        color: #fff;
      }
    }
  }
`;
