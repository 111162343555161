import * as React from 'react';
import { Container, TituloSecao } from './styles';
import { useContext } from 'react';
import userContext from '../../Context/UserContext';
import ListaClientesAdm from './ListaClienteAdm';
import ListaClientesVen from './ListaClienteVen';

const Clientes: React.FC = () => {
  const currentlyUser = useContext(userContext);
  return (
    <Container>
      <TituloSecao>
        Lista Clientes
      </TituloSecao>
      {
        currentlyUser.user.Codperfil === 1 ?
          <ListaClientesVen />
          :
          <ListaClientesAdm />
      }
    </Container>
  )
};

export default Clientes;

